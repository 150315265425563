export const UnderDevelopmentMessage = () => (
  <div className='flex flex-col items-center justify-center py-20 px-4'>
    <div className='flex items-center gap-4'>
      <div className='text-7xl animate-pulse'>🏗️</div>
      <div className='mt-6 text-center'>
        <h2 className='text-2xl font-bold text-secondary-text mb-2'>Documentation Under Development</h2>
        <p className='text-gray-600'>We're working hard to build comprehensive documentation for you.</p>
      </div>
    </div>
  </div>
);
