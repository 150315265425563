import { InputType, Question } from "@Savus-Inc/questionnaire-types";
import { Val } from "@Savus-Inc/dsl/dist/types";

export function getInputTypeAndQuestionKind(item: any): Pick<Question, "kind" | "inputType"> {
  switch (item.fieldInputType) {
    case "header":
      return {
        inputType: InputType.Header,
        kind: Val.String,
      };
    case "selectAutocomplete":
    case "selectMultiple":
    case "select":
    case InputType.Select:
      return {
        inputType: InputType.Select,
        kind: Val.String,
      };
    case "buttonGroupRadio":
    case InputType.RadioGroup:
    case InputType.RadioGroup.toLowerCase():
      return {
        inputType: InputType.RadioGroup,
        kind: Val.String,
      };
    case InputType.YesNo.toLowerCase():
    case InputType.YesNo:
      return {
        inputType: InputType.YesNo,
        kind: Val.String,
      };
    case "buttonGroupCheckbox":
    case InputType.Checkbox:
    case InputType.Checkbox.toLowerCase():
      return {
        inputType: InputType.Checkbox,
        kind: Val.String,
      };
    case "textarea":
    case InputType.RichText:
      return {
        inputType: InputType.RichText,
        kind: Val.String,
      };
    case "number":
      return {
        inputType: undefined,
        kind: Val.Number,
      };
    case "date":
      return {
        inputType: undefined,
        kind: Val.Date,
      };

    default: {
      return {
        inputType: null as never,
        kind: item.questionKind || Val.String,
      };
    }
  }
}
