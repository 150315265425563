import React, { useEffect, useRef, useState } from "react";

export type BusinessType = {
  title: string;
  naicsCode: string;
  naicsTitle: string;
  id?: string;
};

export interface Suggestion {
  label: string;
  value: string | number | boolean;
}

interface AutocompleteProps<T extends Suggestion = Suggestion> {
  suggestions: T[];
  onSelect: (value: any) => void;
  placeholder?: string; // Optional placeholder
  allowCreate?: boolean;
  list?: boolean;
  focus?: boolean;
  toggle?: () => void;
  onInputChange?: (value: string) => void;
}

const Autocomplete: React.FC<AutocompleteProps> = ({
                                                     suggestions,
                                                     focus,
                                                     onSelect,
                                                     placeholder,
                                                     allowCreate,
                                                     list,
                                                     toggle,
                                                     onInputChange = (_) => {
                                                     },
                                                   }) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState<Suggestion[]>(suggestions);
  const [isOpen, setIsOpen] = useState(focus);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (value: string) => {
    setInputValue(value);
    onInputChange(value);

    if (allowCreate) {
      setFilteredSuggestions([
        { label: value, value: value },
        ...suggestions.filter(suggestion => suggestion.label.toLowerCase().includes(value.toLowerCase())).slice(0, 20),
        ...(list ? [{ label: `${value}[]`, value: `${value}[]` }] : []),
      ]);
    } else {
      setFilteredSuggestions(
        suggestions.filter(suggestion => suggestion.label.toLowerCase().includes(value.toLowerCase())).slice(0, 20),
      );
    }

    setIsOpen(true); // Open suggestions on input
  };

  const selectSuggestion = (suggestion: Suggestion) => {
    onSelect(suggestion.value);
    setInputValue("");
    setFilteredSuggestions([]);
    setIsOpen(false);
    toggle && toggle();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (selectedSuggestionIndex >= 0) {
        selectSuggestion(filteredSuggestions[selectedSuggestionIndex]);
      }
    } else if (event.key === "ArrowDown") {
      setSelectedSuggestionIndex(Math.min(selectedSuggestionIndex + 1, filteredSuggestions.length - 1));
    } else if (event.key === "ArrowUp") {
      setSelectedSuggestionIndex(Math.max(selectedSuggestionIndex - 1, -1));
    }
  };

  useEffect(() => {
    setFilteredSuggestions(suggestions);
  }, [suggestions]);

  return (
    <div className="relative w-full">
      <input
        type="text"
        value={inputValue}
        onChange={e => handleInputChange(e.target.value)}
        onFocus={() => setIsOpen(true)}
        onBlur={() =>
          setTimeout(() => {
            setIsOpen(false);
            toggle && toggle();
          }, 200)
        }
        onKeyDown={handleKeyDown}
        ref={inputRef}
        className="w-full px-1 py-[2px] border border-gray-300 rounded-md focus:outline-none focus:border-sky-500"
        placeholder={placeholder}
        autoFocus={focus}
      />
      {isOpen && (
        <ul
          className="absolute top-full left-0 z-50  bg-white border border-gray-300 shadow-lg rounded-md text-left min-w-full w-max max-h-96 overflow-y-scroll"
          key={JSON.stringify(filteredSuggestions)}
        >
          {filteredSuggestions.slice(0, 20).map((suggestion, index) => (
            <li
              key={suggestion.value.toString()}
              onClick={() => selectSuggestion(suggestion)}
              className={`p-2 hover:bg-gray-100 cursor-pointer  whitespace-nowrap text-ellipsis ${
                index === selectedSuggestionIndex ? "bg-gray-200" : ""
              }`}
            >
              {suggestion.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;
