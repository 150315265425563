import React, { useCallback } from "react";
import { NoData } from "../util/Empty";
import { useBuilderNGN } from "./BuilderNGN";
import { unpack } from "../../utils/unpack";
import { QuestionnaireQuestion } from "./Question";
import { EntityRuleGroups } from "./EntityRuleGroups";
import { AccordionItem, TitleWithAction } from "../util/Accourdion";
import { DisplaySelection } from "./DisplaySelection";
import { cb } from "../../utils/cd";
import MultiSelect from "../util/MultiSelect";
import { QuestionnaireGroupSection, QuestionnaireQuestionGroupEntity } from "@Savus-Inc/questionnaire-types";
import { CopyAction, ImportAction } from "../util/ItemActions";
import { v4 } from "uuid";

const SectionInput = ({ section }: { section: QuestionnaireGroupSection }) => {
  const { updateSection, removeSection, currentGroup, resort } = useBuilderNGN();

  const handleChange = useCallback(
    (questions: string[]) => {
      section.questions.forEach(q => {
        const question = currentGroup?.questions.find(v => v.id === q);
        if (question) {
          question.sectionId = undefined;
        }
      });

      questions.forEach(q => {
        const question = currentGroup?.questions.find(v => v.id === q);
        if (question) {
          question.sectionId = section.id;
        }
      });

      updateSection(section.id)("questions")(questions);
    },
    [section, updateSection, currentGroup],
  );

  return (
    <AccordionItem
      title={
        <TitleWithAction
          title={section.title || `Section - ${section.partOrder}`}
          remove={cb(removeSection, section.id)}
          move={d =>
            resort("section", { groupId: currentGroup?.id || "", sectionId: section.id })(
              currentGroup?.sections || [],
              section.partOrder,
              d,
            )
          }
        />
      }
    >
      <div className={"flex flex-col pt-4 gap-2"}>
        <div>
          <label htmlFor='name' className='block text-gray-700 font-medium'>
            Title:
          </label>
          <input
            type='text'
            id='title'
            name='title'
            onBlur={unpack(updateSection(section.id)("title"))}
            defaultValue={section?.title || `section ${Date.now()}`}
            className='w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-sky-500'
          />
        </div>
        <DisplaySelection select={updateSection(section.id)("display")} selected={section.display} />
        <MultiSelect
          value={section.questions}
          options={
            currentGroup?.questions
              .filter(q => (!q.parentId && !q.sectionId) || q.sectionId === section.id)
              .map(q => ({ value: q.id, label: q.question?.label || "" })) || []
          }
          onChange={handleChange}
        />
      </div>
    </AccordionItem>
  );
};

export const GroupDetails = () => {
  const { currentGroup, editQuestionGroupDetails, addSection, bulkUpdateGroup } = useBuilderNGN();

  const handlePageImport = (data: string) => {
    const parsedData = JSON.parse(data);
    const newMappedSectionIds: { [key: string]: string } = {};

    parsedData.sections.forEach((section: { [key: string]: string }) => {
      const newSectionId = v4();
      newMappedSectionIds[section.id] = newSectionId;
    });

    const updatedData = {
      ...currentGroup,
      title: parsedData.title,
      questions: parsedData.questions.map((q: any) => {
        const newQuestionId = v4();
        const newRuleGroupId = v4();

        const newSectionId = newMappedSectionIds[q.sectionId];

        return {
          ...q,
          id: newQuestionId,
          sectionId: newSectionId,
          questionId: newQuestionId,
          question: {
            ...q.question,
            id: newQuestionId,
          },
          ruleGroups: {
            ...q.ruleGroups,
            id: newRuleGroupId,
          },
        };
      }),

      sections: parsedData.sections.map((s: { [key: string]: string }) => {
        const newSectionId = newMappedSectionIds[s.id];

        return {
          ...s,
          id: newSectionId,
        };
      }),
    };

    const finishedData = {
      ...updatedData,
      displaySections: parsedData.displaySections,
      sections: updatedData.sections.map((s: { [key: string]: string }) => {
        return {
          ...s,
          questions: updatedData.questions
            .filter((q: { [key: string]: string }) => q.sectionId === s.id)
            .map((q: { [key: string]: string }) => q.id),
        };
      }),
    };

    bulkUpdateGroup({ ...finishedData } as QuestionnaireQuestionGroupEntity);
  };

  return (
    <div className={"flex flex-col pt-4 gap-2"}>
      <div className='flex gap-2'>
        <ImportAction onImport={data => handlePageImport(data)} />
        <CopyAction value={JSON.stringify(currentGroup)} />
      </div>
      <div>
        <label htmlFor='name' className='block text-gray-700 font-medium'>
          Title:
        </label>
        <input
          type='text'
          id='title'
          name='title'
          onBlur={unpack(editQuestionGroupDetails("title"))}
          defaultValue={currentGroup?.title || `New Group ${Date.now()}`}
          className='w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-sky-500'
        />
      </div>
      <div>
        <label htmlFor='name' className='block text-gray-700 font-medium'>
          Sub Text:
        </label>
        <textarea
          id='sub'
          name='sub'
          onBlur={unpack(editQuestionGroupDetails("subText"))}
          className='w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-sky-500'
          defaultValue={currentGroup?.subText || ""}
        />
      </div>
      {!!currentGroup?.sections?.length && (
        <DisplaySelection
          select={editQuestionGroupDetails("displaySections")}
          selected={currentGroup.displaySections}
        />
      )}

      <AccordionItem title={<TitleWithAction title={"Sections"} add={addSection} />} initial>
        {currentGroup?.sections
          ?.sort((a, b) => a.partOrder - b.partOrder)
          .map(s => <SectionInput section={s} key={[s.id, s.partOrder].join()} />)}
      </AccordionItem>
    </div>
  );
};

export const QuestionGroups = () => {
  const { currentGroup, groupPartActive } = useBuilderNGN();

  return (
    <div key={currentGroup?.id || ""}>
      {currentGroup ? (
        <div className={"h-full flex gap-5"}>
          <div className={"w-full bg-white px-2 h-max"}>
            {groupPartActive === "g-details" ? (
              <GroupDetails />
            ) : groupPartActive === "g-questions" ? (
              <QuestionnaireQuestion />
            ) : groupPartActive === "g-rules" ? (
              <EntityRuleGroups ruleGroups={currentGroup.ruleGroups} kind={"questionGroup"} />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      ) : (
        <NoData />
      )}
    </div>
  );
};
