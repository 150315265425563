import { ApiResponse } from "../types/ApiResponse";
import axios from "axios";
import { toast } from "react-toastify";

export const makeRequest = async <T, DT>(
  kind: "get" | "post" | "patch" | "delete" | "put",
  path: string,
  data?: DT,
): Promise<ApiResponse<T>> => {
  const DEFAULT_HEADERS = {
    withCredentials: true,
    headers: {
      tenant: "savus",
      Authorization: `Bearer ${localStorage.getItem("__token")}`,
    },
  };
  try {
    const d = await axios[kind](
      path.startsWith("http") ? path : `${process.env.REACT_APP_SERVER_URL}/${path}`,
      (data || DEFAULT_HEADERS) as never,
      data ? DEFAULT_HEADERS : undefined,
    );

    return {
      ok: true,
      data: d.data,
    };
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/";
      if (localStorage.getItem("__token")) {
        toast.error(error?.response?.data?.message || error?.message || "Session expired.");
      }
      localStorage.removeItem("__token");
    } else {
      toast.error(error?.response?.data?.message || error?.message || "Request failed.");
    }

    return {
      ok: false,
      error: error.message,
    };
  }
};
