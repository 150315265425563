import React, { useEffect, useRef, useState } from "react";
import { TableMultiSelect, TableSelect } from "../util/TableUtils";
import { Column, Getter, Row, Table } from "@tanstack/react-table";
import { QuestionData } from "../util/useTableData";

const QuestionTableSelectCell = ({
  getValue,
  setTableInput,
  suggestions,
  column,
  table,
  row,
}: {
  getValue: Getter<string>;
  setTableInput: (value: string) => void;
  suggestions: { label: string; value: string | null }[];
  column: Column<QuestionData>;
  table: Table<QuestionData>;
  row: Row<QuestionData>;
}) => {
  const initialValue = getValue();
  const [value, setValue] = useState<string>(initialValue);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const arrayDisplayValue = Array.isArray(value) && value.length > 0 && value.join(", ");
  const dynamicColumns = ["answerDisplay", "addMoreIcon"];

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const displayValue = () => {
    if (column.id === "autocompleteIntegration") {
      if (value === null || value === "") {
        return "OpenEnded";
      } else {
        return value;
      }
    } else if (column.id === "multipleAnswers") {
      return String(value);
    } else if (column.id === "addMoreIcon") {
      if (value === null || value === "") {
        return "user";
      } else {
        return value;
      }
    } else if (Array.isArray(value)) {
      return arrayDisplayValue;
    } else {
      return value;
    }
  };

  useEffect(() => {
    if (dynamicColumns.includes(column.id) && !row.original.multipleAnswers) {
      setShowMenu(false);
    }
  }, [column.id, row.original.multipleAnswers, dynamicColumns]);

  return (
    <div
      ref={containerRef}
      onClick={() => {
        setTableInput(value);
        toggleMenu();
      }}
      className={`pl-2 block items-center cursor-pointer w-full h-[41px] truncate pt-2`}
    >
      {displayValue()}
      {column.id === "showForCarriers"
        ? showMenu && (
            <TableMultiSelect row={row} table={table} column={column} suggestions={suggestions} value={value} />
          )
        : showMenu && <TableSelect row={row} table={table} column={column} suggestions={suggestions} />}
    </div>
  );
};

export default QuestionTableSelectCell;
