import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import {
  CarrierDefault,
  CarrierQuestion,
  Product,
  Question,
  Questionnaire,
  QuestionnaireGroupSection,
  QuestionnaireQuestionEntity,
  QuestionnaireQuestionGroupEntity,
  QuestionValueOption,
  RuleGroupActionMetadata,
  RuleGroupEntity,
  RuleGroupKind,
} from "@Savus-Inc/questionnaire-types";
import useAsyncFn from "../utils/useAsyncFn";
import {
  addCarrierDataMap,
  addCarrierDefault,
  addKnockoutRule,
  addPage,
  addQuestion as addQuestions,
  addQuestionRuleGroup,
  addReferentQuestion,
  addReferentQuestionValue,
  addReferentQuestionValueVisibilityGroup,
  addSection,
  bulkAddCarrierDataMap,
  bulkAddCarrierDefaults,
  createQuestionnaire,
  deletePage,
  deleteSection,
  editCarrierDataMap,
  editCarrierDefault,
  editKnockoutRule,
  editPage,
  editQuestion,
  editQuestionnaire,
  editQuestionRuleGroup,
  editReferentQuestion,
  editReferentQuestionValue,
  editReferentQuestionValueVisibilityGroup,
  editSection,
  getQuestionnaire,
  listQuestionnaires,
  publishQuestionnaire,
  recoverQuestionnaire,
  removeCarrierDataMap,
  removeCarrierDefault,
  removeKnockoutRule,
  removeQuestion,
  removeQuestionnaire,
  removeQuestionRuleGroup,
  removeReferentQuestion,
  removeReferentQuestionValue,
  removeReferentQuestionValueVisibilityGroup,
  updateKnockoutRuleRules,
  updateRuleGroupRules,
  questionnaireBulkInsert, getBusinessTypes,
} from "../http/questionnaire";
import { listApplications, listCarriers, listProducts, listStates } from "../http/referent";
import { DocumentationLoading } from "../components/Documentation/DocumentationLoading";
import { unpackQuestionnaire } from "@Savus-Inc/questionnaire-ngn/dist/utils";

export type AppState = {
  currentQuestionnaire: Questionnaire | null;
  questionnaires: Questionnaire[];
  states: {
    id: string;
    name: string;
    shortName: string;
    fullName: string;
  }[];
  carriers: { id: string; name: string }[];
  products: (Product & { id: string })[];
  applications: { id: string; name: string }[];
};

const StateCtx = createContext({});
export const useAppState = (): {
  state: AppState;
  modify: (partial: Partial<AppState>) => void;
  list: () => void;
  get: (questionnaireId: string) => void;
  create: (data: Pick<Questionnaire, "name" | "title" | "subText" | "id">) => void;
  remove: (questionnaireId: string) => void;
  editQuestionnaireDetails: (
    id: string,
    questionnaire: Partial<Pick<Questionnaire, "title" | "name" | "subText">>,
  ) => void;
  addPage: (questionnaireId: string, data: Partial<QuestionnaireQuestionGroupEntity>) => void;
  editPage: (questionnaireId: string, pageId: string, data: Partial<QuestionnaireQuestionGroupEntity>) => void;
  deletePage: (questionnaireId: string, pageId: string) => void;
  addSection: (questionnaireId: string, groupId: string, data: Partial<QuestionnaireGroupSection>) => void;
  editSection: (
    questionnaireId: string,
    groupId: string,
    sectionId: string,
    data: Partial<QuestionnaireQuestionGroupEntity>,
  ) => void;
  deleteSection: (questionnaireId: string, groupId: string, sectionId: string) => void;
  addQuestion: (
    questionnaireId: string,
    groupId: string,
    partOrder: number,
    parentId: string | undefined,
    data: Partial<Question>,
  ) => void;
  editReferentQuestion: (questionId: string, data: Partial<Question>) => void;
  removeReferentQuestion: (questionId: string) => void;
  editQuestion: (
    questionnaireId: string,
    groupId: string,
    questionId: string,
    data: Partial<QuestionnaireQuestionEntity>,
  ) => void;
  removeQuestion: (questionnaireId: string, groupId: string, questionId: string) => void;
  addReferentQuestionValue: (questionId: string, data: Partial<QuestionValueOption>) => void;
  editReferentQuestionValue: (questionId: string, valueId: string, data: Partial<QuestionValueOption>) => void;
  removeReferentQuestionValue: (questionId: string, valueId: string) => void;

  addReferentQuestionValueVisibilityGroup: (
    questionId: string,
    valueId: string,
    data: Partial<RuleGroupEntity>,
  ) => void;
  editReferentQuestionValueVisibilityGroup: (
    questionId: string,
    valueId: string,
    groupId: string,
    data: Partial<RuleGroupEntity>,
  ) => void;
  removeReferentQuestionValueVisibilityGroup: (questionId: string, valueId: string, groupId: string) => void;

  addQuestionRuleGroup: (
    questionnaireId: string,
    groupId: string,
    questionId: string,
    data: Partial<RuleGroupEntity & { metadata: RuleGroupActionMetadata }>,
  ) => void;
  editQuestionRuleGroup: (
    questionnaireId: string,
    groupId: string,
    questionId: string,
    ruleGroupId: string,
    data: Partial<RuleGroupEntity & { metadata: RuleGroupActionMetadata }>,
  ) => void;
  removeQuestionRuleGroup: (
    questionnaireId: string,
    groupId: string,
    questionId: string,
    ruleGroupId: string,
    kind: RuleGroupKind,
  ) => void;
  updateQuestionRuleGroupRules: (
    questionnaireId: string,
    groupId: string,
    questionId: string,
    ruleGroupId: string,
    kind: RuleGroupKind,
    rules: RuleGroupEntity["rules"],
  ) => void;
  addKnockoutRule: (
    questionnaireId: string,
    carrierId: string,
    data: Partial<RuleGroupEntity & { metadata: RuleGroupActionMetadata }>,
  ) => void;
  editKnockoutRule: (
    questionnaireId: string,
    carrierId: string,
    ruleGroupId: string,
    data: Partial<RuleGroupEntity & { metadata: RuleGroupActionMetadata }>,
  ) => void;
  removeKnockoutRule: (questionnaireId: string, carrierId: string, ruleGroupId: string) => void;
  updateKnockoutRuleRules: (
    questionnaireId: string,
    carrierId: string,
    ruleGroupId: string,
    rules: RuleGroupEntity["rules"],
  ) => void;
  addCarrierDefault: (questionnaireId: string, carrierId: string, data: Partial<CarrierDefault>) => void;
  editCarrierDefault: (
    questionnaireId: string,
    carrierId: string,
    defaultsId: string,
    data: Partial<CarrierDefault>,
  ) => void;
  removeCarrierDefault: (questionnaireId: string, carrierId: string, dataMapId: string) => void;
  addCarrierDataMap: (questionnaireId: string, carrierId: string, data: Partial<CarrierQuestion>) => void;
  editCarrierDataMap: (
    questionnaireId: string,
    carrierId: string,
    dataMapId: string,
    data: Partial<CarrierQuestion>,
  ) => void;
  removeCarrierDataMap: (questionnaireId: string, carrierId: string, dataMapId: string) => void;
  bulkAddCarrierDataMap: (
    questionnaireId: string,
    carrierId: string,
    data: { items: Partial<CarrierQuestion>[] },
  ) => void;
  bulkAddCarrierDefaults: (
    questionnaireId: string,
    carrierId: string,
    data: { items: Partial<CarrierDefault>[] },
  ) => void;
  questionnaireBulkInsert: (questionnaireId: string, questionnaire: Questionnaire) => void;
  recoverQuestionnaire: (questionnaireId: string) => void;
  publishQuestionnaire: (questionnaireId: string) => void;
  getCarriers: () => void;
  getProducts: () => void;
  getStates: () => void;
} => useContext(StateCtx as never);

const cache: Record<string, any> = {};

const set = (key: string, val: any) => {
  cache[key] = val;
  setTimeout(() => {
    delete cache[key];
  }, 1000);
};

export const StateProvider = ({ children }: { children: ReactNode }) => {
  const { exec: list, data: listResponse, loading: listing } = useAsyncFn(listQuestionnaires);
  const { exec: getR, data: getResponse, loading: getting } = useAsyncFn(getQuestionnaire);

  const { exec: create, data: createResponse, loading: creating } = useAsyncFn(createQuestionnaire);
  const { exec: remove, data: deleteResponse, loading: deleting } = useAsyncFn(removeQuestionnaire);
  const { exec: editQuestionnaireDetails, loading: syncing } = useAsyncFn(editQuestionnaire);
  const { exec: recoverQuestionnaireR, data: recoverResponse, loading: recovering } = useAsyncFn(recoverQuestionnaire);
  const { exec: publishQuestionnaireR, data: publishResponse, loading: publishing } = useAsyncFn(publishQuestionnaire);

  const { exec: addPageR, loading: addingPage } = useAsyncFn(addPage);
  const { exec: editPageR, loading: editingPage } = useAsyncFn(editPage);
  const { exec: removePageR, loading: removingPage } = useAsyncFn(deletePage);

  const { exec: addSectionR, loading: addingSection } = useAsyncFn(addSection);
  const { exec: editSectionR, loading: editingSection } = useAsyncFn(editSection);
  const { exec: removeSectionR, loading: removingSection } = useAsyncFn(deleteSection);

  const { exec: editReferentQuestionR, loading: editingReferentQuestion } = useAsyncFn(editReferentQuestion);
  const { exec: removeReferentQuestionR, loading: removingReferentQuestion } = useAsyncFn(removeReferentQuestion);
  const { exec: addReferentQuestionValueR, loading: addingReferentQuestionValue } =
    useAsyncFn(addReferentQuestionValue);
  const { exec: editReferentQuestionValueR, loading: editingReferentQuestionValue } =
    useAsyncFn(editReferentQuestionValue);
  const { exec: removeReferentQuestionValueR, loading: removingReferentQuestionValue } =
    useAsyncFn(removeReferentQuestionValue);

  const { exec: addReferentQuestionValueVisibilityGroupR, loading: addingReferentQuestionValueVisibility } = useAsyncFn(
    addReferentQuestionValueVisibilityGroup,
  );
  const { exec: editReferentQuestionValueVisibilityGroupR, loading: editingReferentQuestionValueVisibility } =
    useAsyncFn(editReferentQuestionValueVisibilityGroup);
  const { exec: removeReferentQuestionValueVisibilityGroupR, loading: removingReferentQuestionValueVisibility } =
    useAsyncFn(removeReferentQuestionValueVisibilityGroup);

  const { exec: addQuestionR, loading: addingQuestion } = useAsyncFn(addQuestions);
  const { exec: editQuestionR, loading: editingQuestion } = useAsyncFn(editQuestion);
  const { exec: removeQuestionR, loading: removingQuestion } = useAsyncFn(removeQuestion);

  const { exec: addQuestionRuleGroupR, loading: addingQuestionRuleGroup } = useAsyncFn(addQuestionRuleGroup);
  const { exec: editQuestionRuleGroupR, loading: editingQuestionRuleGroup } = useAsyncFn(editQuestionRuleGroup);
  const { exec: removeQuestionRuleGroupR, loading: removingQuestionRuleGroup } = useAsyncFn(removeQuestionRuleGroup);
  const { exec: updateQuestionRuleGroupRulesR, loading: updatingQuestionRuleGroupRules } =
    useAsyncFn(updateRuleGroupRules);

  const { exec: addKnockoutRuleR, loading: addingKnockoutRule } = useAsyncFn(addKnockoutRule);
  const { exec: editKnockoutRuleR, loading: editingKnockoutRule } = useAsyncFn(editKnockoutRule);
  const { exec: removeKnockoutRuleR, loading: removingKnockoutRule } = useAsyncFn(removeKnockoutRule);
  const { exec: updateKnockoutRuleRulesR, loading: updatingKnockoutRuleRules } = useAsyncFn(updateKnockoutRuleRules);

  const { exec: addCarrierDefaultR, loading: addingCarrierDefault } = useAsyncFn(addCarrierDefault);
  const { exec: editCarrierDefaultR, loading: editingCarrierDefault } = useAsyncFn(editCarrierDefault);
  const { exec: removeCarrierDefaultR, loading: removingCarrierDefault } = useAsyncFn(removeCarrierDefault);

  const { exec: addCarrierDataMapR, loading: addingCarrierDataMap } = useAsyncFn(addCarrierDataMap);
  const { exec: editCarrierDataMapR, loading: editingCarrierDataMap } = useAsyncFn(editCarrierDataMap);
  const { exec: removeCarrierDataMapR, loading: removingCarrierDataMap } = useAsyncFn(removeCarrierDataMap);
  const { exec: bulkAddCarrierDataMapR, loading: bulkAddingCarrierDataMap } = useAsyncFn(bulkAddCarrierDataMap);
  const { exec: bulkAddCarrierDefaultsR, loading: bulkAddingCarrierDefaults } = useAsyncFn(bulkAddCarrierDefaults);

  const { exec: getCarriers, data: carriersResponse } = useAsyncFn(listCarriers);
  const { exec: getProducts, data: productsResponse } = useAsyncFn(listProducts);
  const { exec: getStates, data: statesResponse } = useAsyncFn(listStates);
  const { exec: getApplications, data: applicationsResponse } = useAsyncFn(listApplications);
  const { exec: questionnaireBulkInsertR } = useAsyncFn(questionnaireBulkInsert);



  const [state, setState] = useState<AppState>({
    currentQuestionnaire: null,
    questionnaires: [],
    carriers: [],
    states: [],
    products: [],
    applications: [],
  });

  const get = (id: string) => {
    if (cache[id]) return;
    set(id, true);
    getR(id);
  };
  const addQuestion = useCallback(
    (
      questionnaireId: string,
      groupId: string,
      partOrder: number,
      parentId: string | undefined,
      data: Partial<Question>,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { values, ...question } = data;
      addReferentQuestion(question)
        .then(() => {
          addQuestionR(questionnaireId, groupId, { questionId: data.id, id: data.id, partOrder, parentId });
        })
        .catch();
    },
    [addQuestionR],
  );

  const modify = (partial: Partial<AppState>) => {
    setState(s => ({ ...s, ...partial }) as AppState);
  };

  useEffect(() => {
    if (listResponse && listResponse.ok) {
      modify({ questionnaires: listResponse.data.data, currentQuestionnaire: null });
    } else {
      modify({ questionnaires: [] });
    }
  }, [listResponse]);

  useEffect(() => {
    if (getResponse && getResponse.ok) {
      const questionnaire: Questionnaire = unpackQuestionnaire(getResponse.data as never, true);
      modify({ currentQuestionnaire: questionnaire, questionnaires: [] });
    } else {
      modify({ currentQuestionnaire: null });
    }
  }, [getResponse]);

  useEffect(() => {
    if (createResponse && createResponse.ok) {
      modify({ currentQuestionnaire: createResponse.data });
    } else {
      modify({ currentQuestionnaire: null });
    }
  }, [createResponse]);

  useEffect(() => {
    if (deleteResponse && deleteResponse.ok) {
      list();
    }
  }, [list, deleteResponse]);

  useEffect(() => {
    if (recoverResponse && recoverResponse.ok) {
      list();
    }
  }, [list, recoverResponse]);

  useEffect(() => {
    if (publishResponse && publishResponse.ok) {
      list();
    }
  }, [list, publishResponse]);

  useEffect(() => {
    if (carriersResponse && carriersResponse.ok) {
      modify({ carriers: carriersResponse.data.map(c => ({ ...c, configurations: [] })) });
    } else {
      modify({ carriers: [] });
    }
  }, [carriersResponse]);

  useEffect(() => {
    if (productsResponse && productsResponse.ok) {
      modify({ products: productsResponse.data });
    } else {
      modify({ products: [] });
    }
  }, [productsResponse]);

  useEffect(() => {
    if (statesResponse && statesResponse.ok) {
      modify({ states: statesResponse.data });
    } else {
      modify({ states: [] });
    }
  }, [statesResponse]);

  useEffect(() => {
    if (applicationsResponse && applicationsResponse.ok) {
      modify({ applications: applicationsResponse.data });
    } else {
      modify({ applications: [] });
    }
  }, [applicationsResponse]);

  useEffect(() => {
    if (localStorage.getItem("__token")) {
      getApplications();
      getStates();
      getCarriers();
      getProducts();
    }
  }, [localStorage.getItem("__token")]);

  return (
    <StateCtx.Provider
      value={{
        state,
        modify,
        list,
        get,
        create,
        remove,
        editQuestionnaireDetails,
        addPage: addPageR,
        editPage: editPageR,
        deletePage: removePageR,
        addSection: addSectionR,
        editSection: editSectionR,
        deleteSection: removeSectionR,
        addQuestion,
        editReferentQuestion: editReferentQuestionR,
        removeReferentQuestion: removeReferentQuestionR,
        editQuestion: editQuestionR,
        removeQuestion: removeQuestionR,
        addReferentQuestionValue: addReferentQuestionValueR,
        editReferentQuestionValue: editReferentQuestionValueR,
        removeReferentQuestionValue: removeReferentQuestionValueR,
        addQuestionRuleGroup: addQuestionRuleGroupR,
        editQuestionRuleGroup: editQuestionRuleGroupR,
        removeQuestionRuleGroup: removeQuestionRuleGroupR,
        updateQuestionRuleGroupRules: updateQuestionRuleGroupRulesR,
        addKnockoutRule: addKnockoutRuleR,
        editKnockoutRule: editKnockoutRuleR,
        removeKnockoutRule: removeKnockoutRuleR,
        updateKnockoutRuleRules: updateKnockoutRuleRulesR,
        recoverQuestionnaire: recoverQuestionnaireR,
        publishQuestionnaire: publishQuestionnaireR,
        getCarriers,
        getProducts,
        getStates,
        addCarrierDefault: addCarrierDefaultR,
        editCarrierDefault: editCarrierDefaultR,
        removeCarrierDefault: removeCarrierDefaultR,
        addCarrierDataMap: addCarrierDataMapR,
        editCarrierDataMap: editCarrierDataMapR,
        removeCarrierDataMap: removeCarrierDataMapR,
        bulkAddCarrierDataMap: bulkAddCarrierDataMapR,
        bulkAddCarrierDefaults: bulkAddCarrierDefaultsR,
        addReferentQuestionValueVisibilityGroup: addReferentQuestionValueVisibilityGroupR,
        editReferentQuestionValueVisibilityGroup: editReferentQuestionValueVisibilityGroupR,
        removeReferentQuestionValueVisibilityGroup: removeReferentQuestionValueVisibilityGroupR,
        questionnaireBulkInsert: questionnaireBulkInsertR,
      }}
    >
      {(listing || getting || recovering || publishing || deleting) && (
        <div className={"z-50 fixed h-screen w-screen bottom-0 flex items-center justify-center bg-gray-100"}>
          <div className={"max-w-[720px] m-4 flex flex-col gap-10 items-center"}>
            <DocumentationLoading
              message={
                listing
                  ? "Retrieving the list of questionnaires..."
                  : recovering || publishing || deleting
                  ? "Changing questionnaire status"
                  : "Retrieving the questionnaire."
              }
            />
          </div>
        </div>
      )}

      {[
        creating,
        syncing,
        addingPage,
        editingPage,
        removingPage,
        addingSection,
        editingSection,
        removingSection,
        editingReferentQuestion,
        removingReferentQuestion,
        addingQuestion,
        editingQuestion,
        removingQuestion,
        addingReferentQuestionValue,
        editingReferentQuestionValue,
        removingReferentQuestionValue,
        addingQuestionRuleGroup,
        editingQuestionRuleGroup,
        removingQuestionRuleGroup,
        updatingQuestionRuleGroupRules,
        addingKnockoutRule,
        editingKnockoutRule,
        removingKnockoutRule,
        updatingKnockoutRuleRules,
        addingCarrierDefault,
        editingCarrierDefault,
        removingCarrierDefault,
        addingCarrierDataMap,
        editingCarrierDataMap,
        removingCarrierDataMap,
        bulkAddingCarrierDataMap,
        bulkAddingCarrierDefaults,
        addingReferentQuestionValueVisibility,
        editingReferentQuestionValueVisibility,
        removingReferentQuestionValueVisibility,
      ].includes(true) && (
        <div
          className={
            "w-fit border rounded border-green-400 mx-auto bg-white p-4 flex flex-col gap-10 items-center fixed top-2 right-4 z-50 text-sky-600"
          }
        >
          {creating ? "Creating new questionnaire..." : "Syncing changes with the storage..."}
        </div>
      )}
      {children}
    </StateCtx.Provider>
  );
};
