import { useState } from "react";
import { CopyAction } from "./ItemActions";

interface JsonViewerProps {
  data: any;
  initialExpanded?: boolean;
}

export const JsonViewer = ({ data, initialExpanded = false }: JsonViewerProps) => {
  const [isExpanded, setIsExpanded] = useState(initialExpanded);

  const renderValue = (value: any, depth = 0): JSX.Element => {
    if (value === null) return <span className="text-secondary">null</span>;
    if (value === undefined) return <span className="text-secondary">undefined</span>;

    if (Array.isArray(value)) {
      if (value.length === 0) return <span className="text-secondary">[]</span>;
      return (
        <div className="ml-4">
          [
          {value.map((item, index) => (
            <div key={index} className="ml-4">
              {renderValue(item, depth + 1)}
              {index < value.length - 1 && ","}
            </div>
          ))}
          ]
        </div>
      );
    }

    if (typeof value === "object") {
      const entries = Object.entries(value);
      if (entries.length === 0) return <span className="text-secondary">{"{}"}</span>;
      return (
        <div className="ml-4">
          {"{"}
          {entries.map(([key, val], index) => (
            <div key={key} className="ml-4">
              <span className="text-primary">{JSON.stringify(key)}</span>: {renderValue(val, depth + 1)}
              {index < entries.length - 1 && ","}
            </div>
          ))}
          {"}"}
        </div>
      );
    }

    if (typeof value === "string") return <span className="text-success">{JSON.stringify(value)}</span>;
    if (typeof value === "number") return <span className="text-warning">{value}</span>;
    if (typeof value === "boolean") return <span className="text-danger">{value.toString()}</span>;

    return <span>{String(value)}</span>;
  };

  return (
    <div className="font-mono text-sm bg-white rounded-lg shadow-sm p-4">
      <div className="flex items-center justify-between mb-2">
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-primary hover:text-primary-dark"
        >
          {isExpanded ? "[-] Collapse" : "[+] Expand"}
        </button>
        <CopyAction value={JSON.stringify(data, null, 2)} />
      </div>
      {isExpanded && (
        <div className="overflow-auto max-h-[500px]">
          {renderValue(data)}
        </div>
      )}
    </div>
  );
}; 