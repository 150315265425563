import { useDocumentationContext } from "./DocumentationContext";
import {
  QuestionnaireQuestionEntity,
  QuestionValidityAction,
  RuleGroupEntity,
  RuleGroupKind,
  ValidationAlertLevel,
} from "@Savus-Inc/questionnaire-types";
import { ruleGroupToPlainEnglish } from "@Savus-Inc/dsl/dist/utils/rule-group-to-plain-english";
import { useMemo } from "react";
import { DocumentationApiExample } from "./DocumentationApiExample";

const DisplayRule = ({ rule, referenceMap }: { rule: RuleGroupEntity; referenceMap: Record<string, string> }) => {
  return (
    <div className='p-4 bg-blue-50 border border-blue-100 rounded-lg'>
      <div className='flex items-start gap-3'>
        <div className='p-2 bg-blue-100 rounded-full'>
          <svg className='w-4 h-4 text-blue-600' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M15 12a3 3 0 11-6 0 3 3 0 016 0z' />
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z'
            />
          </svg>
        </div>
        <div>
          <div className='text-sm text-blue-700 font-medium mb-1'>Action: {rule.actionKind as string}</div>
          <div className='text-sm text-blue-600'>Condition: {ruleGroupToPlainEnglish(rule, referenceMap)}</div>
        </div>
      </div>
    </div>
  );
};

const DisplayRules = ({
  displayRules,
  referenceMap,
}: {
  displayRules: RuleGroupEntity[];
  referenceMap: Record<string, string>;
}) => {
  return (
    <div className='space-y-3'>
      {displayRules.map(rule => (
        <DisplayRule rule={rule} referenceMap={referenceMap} key={rule.id} />
      ))}
    </div>
  );
};

export const DocumentationQuestionDetails = ({ question }: { question: QuestionnaireQuestionEntity }) => {
  const { referenceMap, selectedGroup } = useDocumentationContext();

  const validationRules = question.ruleGroups.filter(
    group =>
      group.kind === RuleGroupKind.Validation &&
      (group.actionKind as QuestionValidityAction).alertLevel === ValidationAlertLevel.Error,
  );

  const displayRules = question.ruleGroups.filter(group => group.kind === RuleGroupKind.Visibility);

  // Find sub-questions if any
  const subQuestions = useMemo(() => {
    if (!selectedGroup) return [];
    return selectedGroup.questions.filter(q => q.parentId === question.id);
  }, [selectedGroup, question.id]);

  return (
    <div className='flex flex-col gap-6 max-h-[calc(100vh-150px)] overflow-y-auto'>
      <div className='bg-white rounded-lg p-6'>
        <div className='flex justify-between items-start mb-6'>
          <div>
            <div className='text-sm text-secondary'>ID: {question.question?.externalId}</div>
            <h2 className='text-dark-blue mb-2'>{question.question?.label}</h2>
          </div>
          <div className='flex gap-2'>
            {question.showForCarriers.map(carrier => (
              <span
                key={carrier}
                className='px-3 py-1 bg-secondary-lighter text-secondary-text rounded-full text-sm whitespace-nowrap'
              >
                {carrier}
              </span>
            ))}
          </div>
        </div>

        <div className='grid grid-cols-2 gap-6'>
          <div className='flex flex-col gap-4'>
            <DetailItem label='Input Type' value={question.question?.inputType || "Not specified"} />
            <DetailItem label='Placeholder' value={question.question?.placeholder || "None"} />
            {question.question?.subText && <DetailItem label='Sub Text' value={question.question.subText} />}
            {question.question?.tooltip && <DetailItem label='Tooltip' value={question.question.tooltip} />}
          </div>

          <div className='flex flex-col gap-4'>
            {question.question?.multipleAnswers && (
              <>
                <DetailItem label='Multiple Answers' value='Yes' />
                {question.question.maxAnswers && (
                  <DetailItem label='Max Answers' value={question.question.maxAnswers.toString()} />
                )}
                {question.question.minAnswers && (
                  <DetailItem label='Min Answers' value={question.question.minAnswers.toString()} />
                )}
              </>
            )}
            {question.question?.format && <DetailItem label='Format' value={question.question.format} />}
          </div>
        </div>

        {!!validationRules.length && (
          <div className='mt-6'>
            <h3 className='text-lg font-semibold text-dark-blue mb-4'>Validation Rules</h3>
            <div className='space-y-3'>
              {validationRules.map(rule => (
                <div key={rule.id} className='p-4 bg-red-50 border border-red-100 rounded-lg'>
                  <div className='flex items-start gap-3'>
                    <div className='p-2 bg-red-100 rounded-full'>
                      <svg className='w-4 h-4 text-red-600' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth={2}
                          d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                        />
                      </svg>
                    </div>
                    <div>
                      <div className='text-sm text-red-700 font-medium mb-1'>
                        Error Message: "{(rule.actionKind as QuestionValidityAction).message}"
                      </div>
                      <div className='text-sm text-red-600'>
                        Condition: {ruleGroupToPlainEnglish(rule, referenceMap)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {!!displayRules.length && (
          <div className='mt-6'>
            <h3 className='text-lg font-semibold text-dark-blue mb-4'>Visibility Rules</h3>
            <DisplayRules displayRules={displayRules} referenceMap={referenceMap} />
          </div>
        )}

        {question.question?.values && question.question.values.length > 0 && (
          <div className='mt-6'>
            <h3 className='text-lg font-semibold text-dark-blue mb-4'>Available Values</h3>
            <div className='grid grid-cols-2 gap-4 items-center'>
              {question.question.values.map(value => (
                <div key={value.id} className='p-3 bg-light rounded-lg border border-secondary-lighter'>
                  <div className='text-sm font-medium text-dark-blue'>{value.label}</div>
                  {!!value.visibility?.length &&
                    value.visibility.map(rule => <DisplayRule rule={rule} referenceMap={referenceMap} key={rule.id} />)}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <DocumentationApiExample
        question={question}
        subQuestions={subQuestions.length > 0 ? subQuestions : undefined}
        jsonNodePath={["answers"]}
      />
    </div>
  );
};

const DetailItem = ({ label, value }: { label: string; value: string }) => (
  <div className='flex flex-col'>
    <span className='text-sm text-secondary-text'>{label}</span>
    <span className='text-dark-blue'>{value}</span>
  </div>
);
