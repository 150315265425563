interface CodeWindowProps {
  title: string;
  content: string;
  onCopy: () => void;
  copied: boolean;
}

export const CodeWindow = ({ title, content, onCopy, copied }: CodeWindowProps) => (
  <div className="rounded-lg overflow-hidden shadow-lg">
    <div className="bg-primary text-white rounded-t-lg py-2 px-4 flex items-center justify-between">
      <div className="flex items-center gap-3">
        <div className="flex gap-1.5">
          <div className="w-3 h-3 rounded-full bg-white/30"></div>
          <div className="w-3 h-3 rounded-full bg-white/30"></div>
          <div className="w-3 h-3 rounded-full bg-white/30"></div>
        </div>
        <span className="text-sm font-medium">{title}</span>
      </div>
      <div className="flex items-center gap-4">
        {/* <div className="text-xs text-white/60">POST /api/questionnaire/answers</div> */}
        <button
          onClick={onCopy}
          className="relative flex items-center gap-2 px-3 py-1 text-xs bg-white/10 text-white rounded-full 
                   hover:bg-white/20 transition-all duration-200 active:scale-95"
        >
          <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" 
            />
          </svg>
          {copied ? "Copied!" : "Copy"}
          {copied && (
            <div className="absolute -top-8 left-1/2 -translate-x-1/2 px-2 py-1 bg-green-500 
                          text-white text-xs rounded-md animate-fade-in-down">
              Copied!
            </div>
          )}
        </button>
      </div>
    </div>
    <pre className="bg-gradient-to-br from-gray-900 to-gray-800 p-6 rounded-b-lg overflow-x-auto max-h-[600px]">
      <code className="text-sm font-mono">
        {content.split('\n').map((line, i) => (
          <div key={i} className="line animate-fade-in-left" style={{ animationDelay: `${i * 100}ms` }}>
            <span className="text-gray-500 select-none mr-4">{i + 1}</span>
            <span className="text-white/90">{line}</span>
          </div>
        ))}
      </code>
    </pre>
  </div>
); 