import React, { useState, useRef, useEffect } from "react";
import { cb } from "../../utils/cd";

interface TooltipProps {
  children: React.ReactNode;
  content?: string | null;
  position?: "top" | "right" | "bottom" | "left"; // Default is 'top'
}

const Tooltip: React.FC<TooltipProps> = ({ children, content, position = "top" }) => {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const trigger = triggerRef.current;
    const tooltip = tooltipRef.current;

    if (!trigger || !tooltip || !content) return;
  }, [content]);

  return (
    <div
      ref={triggerRef}
      className='relative inline-block'
      onMouseEnter={cb(setIsVisible, true)}
      onMouseLeave={cb(setIsVisible, false)}
    >
      {children}

      {content && isVisible && (
        <div
          ref={tooltipRef}
          className={"bg-secondary py-1 px-2 rounded-md text-white bg-opacity-95 w-96 absolute z-50 transition-all"}
          style={{
            boxShadow: "10px 10px 15px -3px rgba(0, 0, 0, 0.1), -10px 10px 15px -3px rgba(0, 0, 0, 0.1)",
          }}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
