import { useMemo, useState } from "react";
import { QuoteTestConfig, QuoteTestSetup } from "./QuoteTestSetup";
import { QuoteRunsList } from "./QuoteRunsList";
import { Button } from "../util/Button";
import { QuoteRunDetails } from "./QuoteRunDetails";
import { QuoteTestRunner } from "./QuoteTestRunner";
import { useLocation, useNavigate } from "react-router-dom";
import { toKebabCase } from "@Savus-Inc/questionnaire-ngn/dist/utils";

export interface QuoteRun {
  id: string;
  name: string;
  timestamp: string;
  status: "running" | "completed" | "failed";
  totalQuotes: number;
  completedQuotes: number;
  successfulQuotes: number;
  failedQuotes: number;
  quotes: QuoteTest[];
}

export interface QuoteTest {
  id: string;
  runId: string;
  status: "running" | "completed" | "failed";
  businessType: {
    title: string;
    code: string;
  };
  address: {
    street: string;
    city: string;
    state: string;
    zip: string;
  };
  logs: QuoteLog[];
  carrierResults: {
    carrierId: string;
    carrierName: string;
    status: "accepted" | "declined" | "error";
    message?: string;
    premium?: number;
  }[];
}

export interface QuoteLog {
  timestamp: string;
  level: "info" | "warning" | "error";
  message: string;
  details?: any;
}

export const QuoteManagement = () => {
  const [isSetupOpen, setIsSetupOpen] = useState(false);
  const [testRunConfig, setTestRunConfig] = useState<QuoteTestConfig | undefined>();
  const location = useLocation();
  const navigate = useNavigate();

  const [markers, setMarkers] = useState<string[]>([]);

  const selectedRun = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("marker");
  }, [location]);

  return (
    <div className="flex flex-col h-screen bg-light">
      {/* Header */}
      <div className="bg-white border-b border-divider px-6 py-4 sticky top-0 z-10">
        <div className="flex justify-between items-center">
          <h1 className="text-regular font-semibold text-dark-blue">Quote Testing Dashboard</h1>
          <Button variant="primary" size="md" onClick={() => setIsSetupOpen(true)} className="flex items-center gap-2">
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
            Run New Test
          </Button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex flex-1 overflow-hidden">
        {/* Runs List */}
        <div className="w-80 border-r border-divider bg-white overflow-y-auto">
          <QuoteRunsList setMarkers={setMarkers} />
        </div>

        {/* Details Panel */}
        <div className="flex-1 overflow-y-auto">
          {selectedRun ? (
            <QuoteRunDetails />
          ) : testRunConfig ? (
            <QuoteTestRunner config={testRunConfig} key={testRunConfig.name} />
          ) : (
            <div className="flex items-center justify-center h-full text-secondary-text">
              Select a run or quote to view details
            </div>
          )}
        </div>
      </div>

      {/* Test Setup Modal */}
      <QuoteTestSetup
        isOpen={isSetupOpen}
        onClose={() => setIsSetupOpen(false)}
        onSubmit={async config => {
          console.log(config);
          config.name = toKebabCase(config.name);
          const searchParams = new URLSearchParams(location.search);
          if (markers.includes(config.name) && searchParams.get("marker") !== config.name) {
            alert(`Marker name is already taken please pick different one`);
            return;
          }
          setIsSetupOpen(false);

          searchParams.delete("customerRecordId");
          searchParams.delete("quoteStateId");
          searchParams.delete("applicationId");
          searchParams.delete("marker");
          navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
          }, { replace: true });
          setTestRunConfig(config);
        }}
      />
    </div>
  );
};
