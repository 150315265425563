import { unpack } from "../../utils/unpack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useBuilderNGN } from "./BuilderNGN";
import { NoData } from "../util/Empty";
import { cb } from "../../utils/cd";
import { QuestionnaireQuestionEntity } from "@Savus-Inc/questionnaire-types";
import { useAppState } from "../../state/State";
import { SelectAutocomplete } from "../util/SelectAutocomplete";

export const Spotlight = ({
  groupId,
  questionId,
  close,
}: {
  groupId?: string;
  questionId?: string;
  close: () => void;
}) => {
  const [query, setQuery] = useState("");
  const [selectedCarrier, setSelectedCarrier] = useState("");
  const { item, activateGroupPart } = useBuilderNGN();
  const {
    state: { carriers },
  } = useAppState();

  useEffect(() => {
    const spotlightInput = document.getElementById("spotlight-search");
    if (spotlightInput) spotlightInput.focus();
  }, []);

  const availableQuestions = useMemo(() => {
    const trimmedQuery = query.trim().toLowerCase();
    const mappedQuestions: QuestionnaireQuestionEntity[] = item.groups
      .map(g => g.questions.map(q => ({ ...q, groupId: g.id })))
      .flat();

    if (selectedCarrier) {
      return mappedQuestions.filter(q => {
        const editedCarriers: string[] = q?.showForCarriers?.map((item: string) => item.toLowerCase());

        return editedCarriers?.includes(selectedCarrier.toLowerCase());
      });
    }

    if (questionId) {
      return mappedQuestions.filter(q => {
        return (
          (q.parentId === questionId && q.question?.externalId?.toLowerCase().includes(trimmedQuery)) ||
          q.question?.label.toLowerCase().includes(trimmedQuery)
        );
      });
    }

    if (groupId) {
      return item.groups
        .filter(g => g.id === groupId)
        .map(g => g.questions.map(q => ({ ...q, groupId: g.id })))
        .flat()
        .filter(q => {
          return (
            q.question?.externalId?.toLowerCase().includes(trimmedQuery) ||
            q.question?.label.toLowerCase().includes(trimmedQuery)
          );
        });
    }

    return mappedQuestions.filter(q => {
      return (
        q.question?.externalId?.toLowerCase().includes(trimmedQuery) ||
        q.question?.label.toLowerCase().includes(trimmedQuery)
      );
    });
  }, [item, groupId, questionId, query, selectedCarrier]);

  const select = useCallback(
    (question: QuestionnaireQuestionEntity) => {
      activateGroupPart({
        questionId: question?.questionId,
        groupPart: "g-questions",
        group: question?.groupId,
      });
      close();
    },
    [activateGroupPart, close],
  );

  const groupMap: Record<string, string> = useMemo(
    () => item.groups.reduce((p, c) => ({ ...p, [c.id]: c.title }), {}),
    [item.groups],
  );

  const getSuggestions = () => {
    return carriers.map(item => ({ label: item.name, value: item.name }));
  };

  return (
    <div className='flex flex-col gap-4 z-[10]'>
      <div className={"flex flex-col w-11/12 gap-4 m"}>
        <div className='flex w-full items-center gap-4'>
          Spotlight search
          <div>
            <SelectAutocomplete
              suggestions={getSuggestions()}
              onSelect={setSelectedCarrier}
              value={selectedCarrier}
              placeholder='Select a Carrier'
              styles='justify-between border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500 border'
            />
          </div>
        </div>
        <input
          id='spotlight-search'
          value={query}
          className='w-full border border-gray-300 rounded py-[2px] focus:outline-none focus:border-sky-500 px-1'
          onChange={unpack(setQuery)}
        />
      </div>

      {availableQuestions.length ? (
        <div className={"max-h-[50vh] overflow-y-auto"}>
          <div className={"flex flex-col gap-3"}>
            {availableQuestions.map(q => {
              return (
                <div
                  key={q.id}
                  className={"flex gap-4 items-center border rounded px-2 py-1 cursor-pointer truncate"}
                  onClick={cb(select, q)}
                >
                  <div className='text-gray-500'>
                    P{q.partOrder}: {groupMap[q.groupId] || "Unknown Group"}
                  </div>
                  <div className={"font-semibold"}>{q.question?.externalId || ""}</div>
                  <div className='truncate'>{q.question?.label || ""}</div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <NoData message={"No questions found that match provided filter"} />
      )}
    </div>
  );
};
