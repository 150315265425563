import { Fragment } from "react";

interface JsonNodePathProps {
  path: string[];
  onCopy: () => void;
  copied: boolean;
}

export const JsonNodePath = ({ path, onCopy, copied }: JsonNodePathProps) => (
  <div className="mb-4 bg-primary/5 rounded-lg p-4 border border-primary/10 max-w-full">
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <svg className="w-4 h-4 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
        </svg>
        <span className="text-sm font-medium text-primary">JSON Node Path</span>
      </div>
      <button
        onClick={onCopy}
        className="relative flex items-center gap-2 px-3 py-1 text-xs bg-primary/10 text-primary rounded-full hover:bg-primary hover:text-white transition-all duration-200 active:scale-95"
      >
        <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
        </svg>
        {copied ? "Copied!" : "Copy Path"}
        {copied && (
          <div className="absolute -top-8 left-1/2 -translate-x-1/2 px-2 py-1 bg-green-500 text-white text-xs rounded-md animate-fade-in-down">
            Copied!
          </div>
        )}
      </button>
    </div>
    <div className="mt-2 flex items-center gap-2 overflow-x-auto pb-2">
      {path.map((node, index) => (
        <Fragment key={index}>
          {index > 0 && (
            <svg className="w-4 h-4 text-primary/40" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          )}
          <code className="px-2 py-1 bg-primary/10 text-primary rounded font-mono text-sm whitespace-nowrap">
            {node}
          </code>
        </Fragment>
      ))}
    </div>
  </div>
); 