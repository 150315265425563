import { QuestionUI } from "./QuestionUI";
import { EntityRuleGroups } from "./EntityRuleGroups";
import { useBuilderNGN } from "./BuilderNGN";
import { NoData } from "../util/Empty";
import { useReducer } from "react";
import classNames from "classnames";
import { cb } from "../../utils/cd";
import { InputType, Question, QuestionnaireQuestionEntity } from "@Savus-Inc/questionnaire-types";
import { QuestionMapping } from "./QuestionMapping";
import { CopyAction, ImportAction } from "../util/ItemActions";
import { v4 } from "uuid";
import { MiniNgn } from "@Savus-Inc/runtime-ngn/dist/src/ngn/mini-ngn";
import { QuestionPreview } from "../QuesitonnairePreview/QuestionPreview";

export const QuestionnaireQuestion = () => {
  const { selectedQuestion, activateGroupPart, groupId, currentGroup, bulkUpdateQuestion } = useBuilderNGN();
  const [selectedTab, setTab] = useReducer((_: 0 | 1 | 2 | 3, v: 0 | 1 | 2 | 3) => v, 0);

  const handleQuestionImport = (data: string) => {
    const parsedData = JSON.parse(data);

    const updatedData = {
      ...selectedQuestion,
      question: {
        ...parsedData?.question,
        externalId: `Q-withoutExternalId`,
        id: selectedQuestion?.id,
      },
      ruleGroups: parsedData.ruleGroups.map((g: { [key: string]: string }) => {
        return {
          ...g,
          id: v4(),
          name: g.name,
        };
      }),
      sectionId: parsedData.sectionId,
    };

    bulkUpdateQuestion(updatedData as QuestionnaireQuestionEntity);
  };

  if (!selectedQuestion) return <NoData />;

  return (
    <div className={"flex flex-col w-full"}>
      {selectedQuestion?.parentId && (
        <div
          className={"flex text-sky-800 gap-2 my-1 cursor-pointer"}
          onClick={cb(activateGroupPart, {
            group: groupId || "",
            groupPart: "g-questions",
            questionId: selectedQuestion.parentId,
          })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
          </svg>
          Go To Parent Question
        </div>
      )}
      <div className="flex flex-col">
        <div className={"flex w-full border-b border-gray-200 font-medium focus:outline-none  text-center mb-4"}>
          <div
            onClick={cb(setTab, 0)}
            className={classNames("w-32 text-md py-1 transition-all cursor-pointer", {
              "border-sky-500 border-b-2 text-black ": selectedTab === 0,
              "text-slate-500 ": selectedTab !== 0,
            })}
          >
            Details
          </div>
          <div
            onClick={cb(setTab, 1)}
            className={classNames("w-32 text-md py-1 transition-all cursor-pointer", {
              "border-sky-500 border-b-2 text-black ": selectedTab === 1,
              "text-slate-500 ": selectedTab !== 1,
            })}
          >
            Rules
          </div>
          <div
            onClick={cb(setTab, 2)}
            className={classNames("w-32 text-md py-1 transition-all cursor-pointer", {
              "border-sky-500 border-b-2 text-black ": selectedTab === 2,
              "text-slate-500 ": selectedTab !== 2,
            })}
          >
            Mapping
          </div>
          <div
            onClick={cb(setTab, 3)}
            className={classNames("w-32 text-md py-1 transition-all cursor-pointer", {
              "border-sky-500 border-b-2 text-black ": selectedTab === 3,
              "text-slate-500 ": selectedTab !== 3,
            })}
          >
            Preview
          </div>
        </div>
        <div className="text-wrap flex justify-center gap-4 border-b border-gray-200 pb-3">
          <h4 className=" font-bold min-w-fit">P-{currentGroup?.partOrder}:</h4>
          <h4>
            <span className="font-bold">Label: </span>
            {selectedQuestion.question?.label ? (
              selectedQuestion.question.label
            ) : (
              <span className="text-red-600">Missing Label</span>
            )}
          </h4>

          <h4 className="min-w-fit">
            <span className="font-bold">ID:</span>{" "}
            {selectedQuestion.question?.externalId ? (
              selectedQuestion.question.externalId
            ) : (
              <span className="text-red-600">Missing ID</span>
            )}
          </h4>
          <CopyAction value={JSON.stringify(selectedQuestion)} />
          <ImportAction
            onImport={data => {
              handleQuestionImport(data);
            }}
          />
        </div>
      </div>

      {selectedTab === 0 ? (
        <QuestionUI key={selectedQuestion.id} />
      ) : selectedTab === 1 ? (
        <EntityRuleGroups
          ruleGroups={selectedQuestion.ruleGroups}
          kind={"question"}
          container={
            selectedQuestion.question?.inputType !== InputType.Header &&
            currentGroup?.questions.some(q => q.parentId === selectedQuestion.id)
          }
          ownerId={selectedQuestion.id}
          key={selectedQuestion.id}
        />
      ) : selectedTab === 2 ? (
        <QuestionMapping key={selectedQuestion.id} />
      ) : <MiniNgn key={selectedQuestion.id} question={selectedQuestion?.question as Question}><QuestionPreview
        parentPath={[]}
        questionId={"q-id"} /></MiniNgn>}
    </div>
  );
};
