import React from "react";

const SavusLogo = () => {
  return (
    <svg width='145' height='40' viewBox='0 0 145 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M50.3446 29.9272C48.8901 29.4545 47.7264 28.8 46.8901 28.0363L47.981 25.7091C48.781 26.4363 49.7992 27.0182 51.0355 27.4545C52.2719 27.8909 53.5446 28.1091 54.8537 28.1091C56.5628 28.1091 57.8719 27.8182 58.7083 27.2363C59.5446 26.6545 59.981 25.8545 59.981 24.9091C59.981 24.1818 59.7628 23.6 59.2901 23.1636C58.8174 22.7272 58.2355 22.3636 57.581 22.1454C56.8901 21.8909 55.9446 21.6363 54.6719 21.3454C53.1083 20.9818 51.8355 20.5818 50.8901 20.2182C49.9446 19.8545 49.1083 19.2727 48.4174 18.4727C47.7264 17.6727 47.3992 16.6182 47.3992 15.3091C47.3992 14.1818 47.6901 13.2 48.2719 12.2909C48.8537 11.3818 49.7628 10.6909 50.9264 10.1454C52.1264 9.59997 53.581 9.34543 55.3628 9.34543C56.5992 9.34543 57.7992 9.49088 58.9992 9.81816C60.1992 10.1454 61.2174 10.5818 62.0537 11.2L61.0719 13.5636C60.1992 13.0182 59.2537 12.5818 58.2719 12.2909C57.2901 12 56.3083 11.8545 55.3628 11.8545C53.6901 11.8545 52.4174 12.1454 51.581 12.7636C50.7446 13.3818 50.3083 14.1818 50.3083 15.1272C50.3083 15.8545 50.5628 16.4363 51.0355 16.8727C51.5083 17.3091 52.0901 17.6727 52.8174 17.9272C53.5083 18.1818 54.4901 18.4363 55.6901 18.7272C57.2537 19.0909 58.5264 19.4909 59.4719 19.8545C60.4174 20.2182 61.2537 20.8 61.9446 21.5636C62.6355 22.3272 62.9628 23.3818 62.9628 24.6909C62.9628 25.7818 62.6719 26.7636 62.0537 27.6727C61.4719 28.5818 60.5628 29.2727 59.3628 29.8182C58.1628 30.3272 56.6719 30.6182 54.8901 30.6182C53.3264 30.6545 51.7992 30.4 50.3446 29.9272Z'
        fill='#3E4459'
      />
      <path
        d='M104.526 9.59995L95.3991 30.4363H92.4537L83.29 9.59995H86.49L93.9809 26.7272L101.545 9.59995H104.526Z'
        fill='#3E4459'
      />
      <path d='M76.5263 9.59995H73.5809L64.4536 30.4363H67.4354L76.5263 9.59995Z' fill='#639AA6' />
      <path d='M85.69 30.4363L78.0173 12.9454L76.4173 16.5817L82.49 30.4363H85.69Z' fill='#3E4459' />
      <path
        d='M109.617 28.2909C108.054 26.6909 107.29 24.4363 107.29 21.4545V9.59995H110.272V21.309C110.272 25.7818 112.235 27.9999 116.126 27.9999C118.017 27.9999 119.472 27.4545 120.49 26.3636C121.508 25.2727 122.017 23.5636 122.017 21.309V9.59995H124.89V21.4545C124.89 24.4363 124.126 26.7272 122.563 28.3272C120.999 29.8909 118.854 30.6909 116.09 30.6909C113.326 30.6545 111.181 29.8545 109.617 28.2909Z'
        fill='#3E4459'
      />
      <path
        d='M131.763 29.9272C130.308 29.4545 129.144 28.7999 128.308 28.0363L129.399 25.709C130.199 26.4363 131.217 27.0181 132.454 27.4545C133.69 27.8909 134.963 28.109 136.272 28.109C137.981 28.109 139.29 27.8181 140.126 27.2363C140.963 26.6545 141.399 25.8545 141.399 24.909C141.399 24.1818 141.181 23.5999 140.708 23.1636C140.235 22.7272 139.654 22.3636 138.999 22.1454C138.308 21.8909 137.363 21.6363 136.09 21.3454C134.526 20.9818 133.254 20.5818 132.308 20.2181C131.363 19.8545 130.526 19.2727 129.835 18.4727C129.144 17.6727 128.817 16.6181 128.817 15.309C128.817 14.1818 129.108 13.1999 129.69 12.2909C130.272 11.3818 131.181 10.6909 132.344 10.1454C133.544 9.59994 134.999 9.3454 136.781 9.3454C138.017 9.3454 139.217 9.49085 140.417 9.81812C141.617 10.1454 142.635 10.5818 143.472 11.1999L142.49 13.5636C141.617 13.0181 140.672 12.5818 139.69 12.2909C138.708 11.9999 137.726 11.8545 136.781 11.8545C135.108 11.8545 133.835 12.1454 132.999 12.7636C132.163 13.3818 131.726 14.1818 131.726 15.1272C131.726 15.8545 131.981 16.4363 132.454 16.8727C132.926 17.309 133.508 17.6727 134.235 17.9272C134.926 18.1818 135.908 18.4363 137.108 18.7272C138.672 19.0909 139.944 19.4909 140.89 19.8545C141.835 20.2181 142.672 20.7999 143.363 21.5636C144.054 22.3272 144.381 23.3818 144.381 24.6909C144.381 25.7818 144.09 26.7636 143.472 27.6727C142.89 28.5818 141.981 29.2727 140.781 29.8181C139.581 30.3272 138.09 30.6181 136.308 30.6181C134.744 30.6545 133.217 30.3999 131.763 29.9272Z'
        fill='#3E4459'
      />
      <path
        d='M20.7446 1.74545C30.8174 1.74545 38.9992 9.92727 38.9992 20C38.9992 30.0727 30.8174 38.2545 20.7446 38.2545C10.6719 38.2545 2.49008 30.0727 2.49008 20C2.49008 9.92727 10.6719 1.74545 20.7446 1.74545ZM20.7446 0C9.69008 0 0.744629 8.94545 0.744629 20C0.744629 31.0545 9.69008 40 20.7446 40C31.7992 40 40.7446 31.0545 40.7446 20C40.7446 8.94545 31.7992 0 20.7446 0Z'
        fill='#3E4459'
      />
      <path d='M27.5447 18.9455V22.1818L13.9083 16.1091V12.9818L27.5447 18.9455Z' fill='#639AA6' />
      <path d='M27.5447 6.90912L16.1628 12L19.6174 13.4909L27.5447 10.2182V6.90912Z' fill='#3E4459' />
      <path
        d='M13.9447 18.2182L27.5447 24.1091V27.2364L13.9447 33.0909V29.6727L23.6538 25.5273L13.9447 21.3455V18.2182Z'
        fill='#3E4459'
      />
    </svg>
  );
};

export default SavusLogo;
