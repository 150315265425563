import { ButtonHTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'danger' | 'success' | 'warning';
  size?: 'sm' | 'md' | 'lg';
  children: ReactNode;
  isLoading?: boolean;
}

export const Button = ({
  variant = 'primary',
  size = 'md',
  children,
  className,
  disabled,
  isLoading,
  ...props
}: ButtonProps) => {
  const baseStyles = "inline-flex items-center justify-center font-medium rounded-in transition-colors focus:outline-none";
  
  const variantStyles = {
    primary: "bg-primary hover:bg-secondary text-white disabled:bg-disabled disabled:text-disabled-text",
    secondary: "bg-white border border-secondary-light hover:border-primary text-secondary-text hover:text-primary disabled:bg-disabled disabled:border-disabled-gray disabled:text-disabled-text",
    danger: "bg-danger hover:bg-danger/90 text-white disabled:bg-disabled disabled:text-disabled-text",
    success: "bg-success hover:bg-success/90 text-white disabled:bg-disabled disabled:text-disabled-text",
    warning: "bg-warning hover:bg-warning/90 text-dark disabled:bg-disabled disabled:text-disabled-text"
  };

  const sizeStyles = {
    sm: "px-3 py-1.5 text-in",
    md: "px-4 py-2 text-md",
    lg: "px-6 py-2.5 text-regular"
  };

  return (
    <button
      className={classNames(
        baseStyles,
        variantStyles[variant],
        sizeStyles[size],
        { 'cursor-not-allowed': disabled || isLoading },
        className
      )}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading ? (
        <>
          <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Processing...
        </>
      ) : (
        children
      )}
    </button>
  );
}; 