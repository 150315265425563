import { LocationBusinessType } from "@Savus-Inc/runtime-ngn/dist/src/ngn";
import { useEffect, useState } from "react";
import { useDebounce } from "@Savus-Inc/runtime-ngn/dist/src/utils/use-debounce";
import useAsyncFn from "../../utils/useAsyncFn";
import { getBusinessTypes } from "../../http/questionnaire";
import { SelectAutocomplete } from "../util/SelectAutocomplete";

export const BusinessTypeSelect = ({
                                     selected,
                                     onSelect,
                                   }: {
  selected: string;
  onSelect: (v: LocationBusinessType | undefined) => void;
}) => {
  const [term, setTerm] = useState<string>("");
  const debounced = useDebounce(term, 500);
  const { exec, data } = useAsyncFn(getBusinessTypes);

  useEffect(() => {
    if (debounced.trim().length > 2) {
      exec(debounced);
    }
  }, [debounced]);

  return (
    <SelectAutocomplete
      styles={"border border-gray-200 px-2 py-1.5 rounded"}
      placeholder={"Search for a business type"}
      suggestions={data?.ok ? data.data.map(d => ({ label: d.title, value: d.id })) : []}
      value={selected}
      onSelect={d => onSelect(data?.ok ? data.data.find(v => v.id === d) : undefined)}
      onInputChange={setTerm}
    />
  );
};
