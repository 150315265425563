/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import LogoWhite from "../util/LogoWhite";
import { useEffect, useMemo } from "react";
import { UnderDevelopmentMessage } from "./UnderDevelopmentMessage";
import { useAppState } from "../../state/State";
import { DocumentationDetails } from "./DocumentationDetails";
import { cb } from "../../utils/cd";
import { useDocumentationContext } from "./DocumentationContext";

export const DocumentationQuestionnaireDetails = () => {
  const { productId } = useParams();
  const {
    state: { products, currentQuestionnaire },
    get,
  } = useAppState();

  const navigate = useNavigate();

  const { selectedQuestion, selectedGroup, setSelectedGroup } = useDocumentationContext();
  const currentProduct = useMemo(
    () => products.find(p => p.shortName.toLowerCase() === productId?.toLowerCase()),
    [products, products],
  );
  useEffect(() => {
    if (currentProduct && currentProduct.activeQuestionnaireId !== currentQuestionnaire?.id) {
      get(currentProduct?.activeQuestionnaireId);
    }
  }, [currentProduct?.id, currentQuestionnaire?.id]);

  return (
    <div className='min-h-screen flex flex-col'>
      {/* Header */}
      <div className='sticky top-0 z-10 bg-primary px-6 py-4 rounded-lg'>
        <div className='flex items-center gap-6 max-w-full'>
          <div
            className='flex-shrink-0 w-80 scale-50 origin-left cursor-pointer'
            onClick={cb(navigate, "/documentation" as never)}
          >
            <LogoWhite />
          </div>
          <h1 className='text-3xl font-bold text-white truncate'>
            {productId?.toUpperCase()} Questionnaire Documentation
          </h1>
        </div>
        {selectedQuestion && (
          <div className='flex items-center gap-2 w-full justify-end h-0 -translate-y-2'>
            <button onClick={() => setSelectedGroup(selectedGroup!)} className='text-white hover:text-primary-dark'>
              {selectedGroup?.title}
            </button>
            <span className='text-light'>›</span>
            <span className='text-light font-bold'>{selectedQuestion.question?.externalId || "Question Details"}</span>
          </div>
        )}
      </div>

      {/* Content */}
      <div className='flex-1 px-6 py-4 overflow-auto'>
        {currentQuestionnaire ? <DocumentationDetails /> : <UnderDevelopmentMessage />}
      </div>
    </div>
  );
};
