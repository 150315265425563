import React, { useMemo, useState } from "react";
import { Modal } from "../util/Modal";
import { Button } from "../util/Button";
import { SelectAutocomplete } from "../util/SelectAutocomplete";
import { useAppState } from "../../state/State";
import MultiSelect from "../util/MultiSelect";
import zips from "../../data/zips.json";
import { BusinessTypeSelect } from "./BusinessTypeSelect";
import { LocationBusinessType } from "@Savus-Inc/runtime-ngn/dist/src/ngn";

export interface QuoteTestConfig {
  name: string;
  product: string | null;
  carriers: string[];
  states: string[];
  addressMode: "random" | "manual";
  manualAddress?: {
    street: string;
    city: string;
    state: string;
    zip: string;
  };
  businessTypeMode: "random" | "manual";
  manualBusinessType?: LocationBusinessType;
  numberOfQuotes: number;
}

export const QuoteTestSetup = ({
                                 isOpen,
                                 onClose,
                                 onSubmit,
                               }: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (config: QuoteTestConfig) => Promise<void>;
}) => {
  const [config, setConfig] = useState<QuoteTestConfig>({
    name: "",
    addressMode: "random",
    businessTypeMode: "random",
    numberOfQuotes: 1,
    product: null,
    carriers: [],
    states: [],
  });

  const {
    state: { products, carriers, states },
  } = useAppState();

  const availableZips = useMemo(
    () => (config.manualAddress?.state ? (zips as Record<string, string[]>)[config.manualAddress?.state] || [] : []),
    [config.manualAddress?.state],
  );


  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Setup Quote Test Run">
      <div className="space-y-6">
        {/* Test Name */}
        <div>
          <label className="block text-in font-medium text-secondary-text">Test Run Name</label>
          <input
            type="text"
            value={config.name}
            onChange={e => setConfig({ ...config, name: e.target.value })}
            className="mt-1 block w-full rounded-in border border-secondary-light px-3 py-2
                      focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary
                      text-md text-dark-blue placeholder:text-secondary-light"
            placeholder="e.g., Retail Stores Test Run"
          />
        </div>

        <div>
          <label className="block text-in font-medium text-secondary-text">Select Product</label>
          <div className="mt-2 space-y-4">
            <select
              value={config.product || undefined}
              onChange={e => setConfig({ ...config, product: e.target.value })}
              className="block w-full rounded-in border border-secondary-light px-3 py-2
                        focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary
                        text-md text-dark-blue"
            >
              <option value="">Select a Product</option>
              {products.map(product => (
                <option key={product.id} value={product.id}>
                  {product.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div>
          <label className="block text-in font-medium text-secondary-text">Select Carriers</label>
          <div className="mt-2 space-y-4">
            <MultiSelect
              options={[
                { value: "all", label: "All available carriers" },
                ...carriers.map(c => ({ value: c.name, label: c.name })),
              ]}
              value={config.carriers}
              onChange={value => setConfig({ ...config, carriers: value })}
            />
          </div>
        </div>

        <div>
          <label className="block text-in font-medium text-secondary-text">Select States</label>
          <div className="mt-2 space-y-4">
            <MultiSelect
              options={[
                { value: "all", label: "All available states" },
                ...states.map(c => ({ value: c.shortName, label: c.name })),
              ]}
              value={config.states}
              onChange={value => setConfig({ ...config, states: value })}
            />
          </div>
        </div>

        {/* Address Configuration */}
        <div>
          <label className="block text-in font-medium text-secondary-text">Address Selection</label>
          <div className="mt-2 space-y-4">
            <select
              value={config.addressMode}
              onChange={e => setConfig({ ...config, addressMode: e.target.value as "random" | "manual" })}
              className="block w-full rounded-in border border-secondary-light px-3 py-2
                        focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary
                        text-md text-dark-blue"
            >
              <option value="random">Random Addresses</option>
              <option value="manual">Specify Address</option>
            </select>

            {config.addressMode === "manual" && (
              <div className="space-y-3">
                <div className="space-y-3">
                  <input
                    type="text"
                    placeholder="Street Address"
                    className="block w-full rounded-md border border-gray-300 px-3 py-2"
                    onChange={e =>
                      setConfig({
                        ...config,
                        manualAddress: { ...(config.manualAddress || {}), street: e.target.value } as never,
                      })
                    }
                  />
                  <input
                    type="text"
                    placeholder="City"
                    className="block w-full rounded-md border border-gray-300 px-3 py-2"
                    onChange={e =>
                      setConfig({
                        ...config,
                        manualAddress: { ...(config.manualAddress || {}), city: e.target.value } as never,
                      })
                    }
                  />

                  <div className="flex gap-4 items-center w-full">
                    <label className="block text-in font-medium text-secondary-text w-1/2">

                      State:
                    </label>
                    <SelectAutocomplete
                      onSelect={v =>
                        setConfig({
                          ...config,
                          manualAddress: { ...(config.manualAddress || {}), state: v } as never,
                        })
                      }
                      suggestions={states.map(v => ({ label: `${v.name} (${v.shortName})`, value: v.shortName }))}
                      value={config.manualAddress?.state || "Select..."}
                    />
                  </div>
                  <div className="flex gap-4 items-center">
                    <label className="block text-in font-medium text-secondary-text w-1/2">
                      Zip:
                    </label>
                    <SelectAutocomplete
                      suggestions={availableZips.map(v => ({
                        label: v,
                        value: v,
                      }))}
                      onSelect={v =>
                        setConfig({
                          ...config,
                          manualAddress: { ...(config.manualAddress || {}), zip: v } as never,
                        })
                      }
                      value={config.manualAddress?.zip || "Select..."}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Business Type Configuration */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Business Type Selection</label>
          <div className="mt-2 space-y-4">
            <select
              value={config.businessTypeMode}
              onChange={e => setConfig({ ...config, businessTypeMode: e.target.value as "random" | "manual" })}
              className="block w-full rounded-md border border-gray-300 px-3 py-2"
            >
              <option value="random">Random Business Types</option>
              <option value="manual">Specify Business Type</option>
            </select>

            {config.businessTypeMode === "manual" && (
              <BusinessTypeSelect
                onSelect={(value) =>
                  setConfig({
                    ...config,
                    manualBusinessType: value,
                  })
                }
                selected={config.manualBusinessType?.title || ""}
              />
            )}
          </div>
        </div>

        {/* Number of Quotes */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Number of Quotes</label>
          <input
            type="number"
            min="1"
            max="100"
            value={config.numberOfQuotes}
            onChange={e => setConfig({ ...config, numberOfQuotes: parseInt(e.target.value) })}
            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
          />
        </div>

        {/* Actions */}
        <div className="flex justify-end gap-3">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => onSubmit(config)}
            disabled={!config.name || config.numberOfQuotes < 1}
          >
            Start Test Run
          </Button>
        </div>
      </div>
    </Modal>
  );
};
