import Autocomplete, { Suggestion } from "./Autocomplete";
import React, { useReducer } from "react";
import { cb } from "../../utils/cd";
import classNames from "classnames";

export const SelectAutocomplete = ({
                                     suggestions,
                                     onSelect,
                                     value,
                                     placeholder,
                                     styles,
                                     onInputChange = (_) => {
                                     },
                                   }: {
  suggestions: Suggestion[];
  onSelect: (v: any) => void;
  value: any;
  placeholder?: string;
  styles?: string;
  onInputChange?: (value: string) => void;
}) => {
  const [opMenu, toggleOpMenu] = useReducer((_: boolean, c: boolean) => c, false);

  if (!opMenu) {
    return (
      <div onClick={cb(toggleOpMenu, true)}
           className={classNames(`flex items-center gap-2 w-full cursor-pointer`, styles ? { [styles]: true } : {})}>
        <div className={"flex-1"}>{value || placeholder}</div>
        <span className={"text-xs"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
          </svg>
        </span>
      </div>
    );
  }

  return <Autocomplete onInputChange={onInputChange} focus suggestions={suggestions} toggle={cb(toggleOpMenu, false)}
                       onSelect={onSelect} />;
};
