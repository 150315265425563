import { useMemo } from 'react';
import { CustomerRecord } from '../../types/customer-record';
import { useAppState } from '../../state/State';

interface QuoteRunStatsProps {
  data: CustomerRecord[];
}

export const QuoteRunStats = ({ data }: QuoteRunStatsProps) => {
  const { state: { carriers } } = useAppState();

  const stats = useMemo(() => {
    const totalQuotes = data.length;
    let completed = 0;
    let errored = 0;
    let pending = 0;

    // Carrier stats tracking
    const carrierStats = data.reduce((acc, quote) => {
      const submissions = quote.questionnaireState?.find(qs => qs.submissions.length)?.submissions || [];
      submissions.forEach(sub => {
        if (sub.status === "Completed") completed++;
        else if (sub.status === "Errored") errored++;
        else pending++;

        const carrier = carriers.find(c => c.id === sub.carrierId);
        if (carrier) {
          acc[carrier.id] = acc[carrier.id] || { 
            name: carrier.name,
            total: 0, 
            completed: 0, 
            errored: 0 
          };
          acc[carrier.id].total++;
          if (sub.status === "Completed") acc[carrier.id].completed++;
          if (sub.status === "Errored") acc[carrier.id].errored++;
        }
      });
      return acc;
    }, {} as Record<string, { 
      name: string;
      total: number; 
      completed: number; 
      errored: number;
    }>);

    const total = completed + errored + pending;
    const successRate = total ? ((completed / total) * 100).toFixed(1) : "0";

    return {
      totalQuotes,
      completed,
      errored,
      pending,
      total,
      successRate,
      carrierStats
    };
  }, [data, carriers]);

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-light rounded-xl p-4 hover:shadow-md transition-shadow duration-200">
          <div className="flex items-center justify-between">
            <div className="text-secondary-text text-sm font-medium">Total Quotes</div>
            <div className="bg-primary/10 p-2 rounded-lg">
              <svg className="w-5 h-5 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
            </div>
          </div>
          <div className="text-2xl font-semibold text-dark-blue mt-2">{stats.totalQuotes}</div>
        </div>

        <div className="bg-light rounded-xl p-4 hover:shadow-md transition-shadow duration-200">
          <div className="flex items-center justify-between">
            <div className="text-secondary-text text-sm font-medium">Success Rate</div>
            <div className="bg-success/10 p-2 rounded-lg">
              <svg className="w-5 h-5 text-success" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
              </svg>
            </div>
          </div>
          <div className="text-2xl font-semibold text-dark-blue mt-2">{stats.successRate}%</div>
        </div>

        <div className="bg-light rounded-xl p-4 hover:shadow-md transition-shadow duration-200">
          <div className="flex items-center justify-between">
            <div className="text-secondary-text text-sm font-medium">Total Submissions</div>
            <div className="bg-secondary/10 p-2 rounded-lg">
              <svg className="w-5 h-5 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
              </svg>
            </div>
          </div>
          <div className="text-2xl font-semibold text-dark-blue mt-2">{stats.total}</div>
        </div>

        <div className="bg-light rounded-xl p-4 hover:shadow-md transition-shadow duration-200">
          <div className="flex items-center justify-between mb-2">
            <div className="text-secondary-text text-sm font-medium">Status Breakdown</div>
            <div className="bg-dark-blue/10 p-2 rounded-lg">
              <svg className="w-5 h-5 text-dark-blue" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
              </svg>
            </div>
          </div>
          <div className="flex gap-3 items-center">
            <div className="flex items-center gap-1.5">
              <div className="w-2 h-2 rounded-full bg-success"></div>
              <span className="text-success font-medium">{stats.completed}</span>
            </div>
            <div className="flex items-center gap-1.5">
              <div className="w-2 h-2 rounded-full bg-danger"></div>
              <span className="text-danger font-medium">{stats.errored}</span>
            </div>
            <div className="flex items-center gap-1.5">
              <div className="w-2 h-2 rounded-full bg-primary"></div>
              <span className="text-primary font-medium">{stats.pending}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light rounded-xl p-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-sm font-medium text-secondary-text">Carrier Performance</h3>
          <div className="flex gap-4 text-xs">
            <div className="flex items-center gap-1.5">
              <div className="w-2 h-2 rounded-full bg-success"></div>
              <span className="text-secondary-text">Successful</span>
            </div>
            <div className="flex items-center gap-1.5">
              <div className="w-2 h-2 rounded-full bg-danger"></div>
              <span className="text-secondary-text">Failed</span>
            </div>
          </div>
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {Object.values(stats.carrierStats)
            .sort((a, b) => b.completed / b.total - a.completed / a.total)
            .map((carrier) => {
              const successRate = (carrier.completed / carrier.total) * 100;
              return (
                <div key={carrier.name} 
                  className="bg-white rounded-lg p-4 hover:shadow-md transition-all duration-200 border border-light"
                >
                  <div className="flex items-center justify-between mb-3">
                    <div className="font-medium text-secondary">{carrier.name}</div>
                    <div className={`text-sm font-semibold ${
                      successRate >= 70 ? 'text-success' :
                      successRate >= 40 ? 'text-warning' :
                      'text-danger'
                    }`}>
                      {successRate.toFixed(1)}%
                    </div>
                  </div>
                  
                  <div className="h-2 bg-light rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-success transition-all duration-300"
                      style={{ width: `${successRate}%` }}
                    />
                  </div>
                  
                  <div className="flex justify-between mt-2 text-xs text-secondary-text">
                    <span>{carrier.completed} of {carrier.total} successful</span>
                    {carrier.errored > 0 && (
                      <span className="text-danger">{carrier.errored} failed</span>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}; 