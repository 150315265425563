import React, { PropsWithChildren, useCallback, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import { QuestionnaireEditor } from "./components/Questionaire/QuestionnaireEditor";
import { QuestionnairePreview } from "./components/QuesitonnairePreview/QuestionnairePreview";
import { HomePage } from "./components/Home/HomePage";
import { Questionnaire } from "./components/Questionaire/Questionnaire";
import useAsyncFn from "./utils/useAsyncFn";
import { me } from "./http/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UtilsPage } from "./components/Utils/UtilsPage";
import { Documentation } from "./components/Documentation/Documentation";
import { DocumentationQuestionnaireDetails } from "./components/Documentation/DocumentationQuestionnaireDetails";
import { DocumentationCTX } from "./components/Documentation/DocumentationContext";
import { QuoteManagement } from "./components/QuoteManagement/QuoteManagement";
import SavusLogo from "./components/util/SavusLogo";

const AuthCheck = ({ children, auth }: PropsWithChildren<{ auth: boolean }>) => {
  const token = localStorage.getItem("__token");
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, data, exec } = useAsyncFn(me);

  useEffect(() => {
    if (!token && auth && location.pathname !== "/") {
      navigate("/");
    } else if (token) {
      exec(token);
    }
  }, [token, auth, location.pathname, navigate, exec]);

  useEffect(() => {
    if (data?.ok && !auth) {
      navigate("/questionnaire");
    } else if (data?.ok === false && auth) {
      navigate("/");
    }
  }, [data, auth, navigate]);

  if (loading)
    return (
      <div className={"h-screen w-screen flex justify-center items-center"}>
        <div className={"max-w-[720px] m-4 rounded border bg-white p-6 flex flex-col gap-10 items-center"}>
          Gathering user data...
        </div>
      </div>
    );

  if (!token && auth)
    return (
      <div className={"h-screen w-screen flex justify-center items-center"}>
        <div className={"max-w-[720px] m-4 rounded border bg-white p-6 flex flex-col gap-10 items-center"}>
          Redirecting to login page...
        </div>
      </div>
    );

  if (token && data?.ok && !auth)
    return (
      <div className={"h-screen w-screen flex justify-center items-center"}>
        <div className={"max-w-[720px] m-4 rounded border bg-white p-6 flex flex-col gap-10 items-center"}>
          Redirecting to questionnaire page...
        </div>
      </div>
    );

  return <div className={"relative"}>{children}</div>;
};

function App({ auth }: PropsWithChildren<{ auth: boolean }>) {
  const logout = useCallback(() => {
    localStorage.removeItem("__token");
    window.location.reload();
  }, []);

  return (
    <div className={"w-screen h-screen bg-gray-50 overflow-y-auto px-1 flex flex-col gap-[1px] "} id={"app"}>
      <div className='sticky top-0 w-full h-16 bg-white flex justify-between items-center border-gray-200 border-b-[3px] p-4 z-[9] '>
        <SavusLogo />
        <div>
          {auth && (
            <button onClick={logout} className='underline cursor-pointer'>
              Logout
            </button>
          )}
        </div>
      </div>
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            path='/'
            element={
              <AuthCheck auth={false}>
                <HomePage />
              </AuthCheck>
            }
          />
          <Route
            path='/documentation'
            element={
              <AuthCheck auth={true}>
                <Documentation />
              </AuthCheck>
            }
          />
          <Route
            path='/documentation/:productId'
            element={
              <AuthCheck auth={true}>
                <DocumentationCTX>
                  <DocumentationQuestionnaireDetails />
                </DocumentationCTX>
              </AuthCheck>
            }
          />

          <Route
            path='/questionnaire'
            element={
              <AuthCheck auth>
                <Questionnaire />
              </AuthCheck>
            }
          />

          <Route
            path='/quotes'
            element={
              <AuthCheck auth>
                <QuoteManagement />
              </AuthCheck>
            }
          />
          <Route
            path='/questionnaire/:id'
            element={
              <AuthCheck auth>
                <QuestionnaireEditor />
              </AuthCheck>
            }
          />
          <Route
            path='/questionnaire/:id/preview'
            element={
              <AuthCheck auth>
                <QuestionnairePreview />
              </AuthCheck>
            }
          />
          <Route
            path='/utils'
            element={
              <AuthCheck auth>
                <UtilsPage />
              </AuthCheck>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
