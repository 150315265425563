import { useAsyncValueIntegration, useQuestionAnswer } from "@Savus-Inc/runtime-ngn/dist/src/ngn";
import { unpack } from "../../../utils/unpack";

export const StateQuestion = ({ me, parentPath }: { me: string; parentPath: (string | number)[] }) => {
  const { answer, value, question } = useQuestionAnswer(me, parentPath);
  const {
    choices,
  } = useAsyncValueIntegration<Record<"name" | "shortName" | "fullName", string>>("state", -1);

  return (
    <select value={value?.value?.toString() || ""} onChange={unpack(answer(0))}
            className={"w-full border border-secondary-light rounded-in px-3 py-2 text-base focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary bg-white transition-colors"}>
      <option value={question?.placeholder || ""}></option>
      {choices.map(v => (
        <option value={v.fullName} key={v.shortName} selected={value?.value === v.fullName}>
          {v.fullName}
        </option>
      ))}
    </select>
  );
};
