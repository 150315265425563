import { useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { cb } from "../../utils/cd";
import { CustomerRecord } from "../../types/customer-record";
import { Modal } from "../util/Modal";
import { CustomerRecordForm, getPrevious, setPrevious, updatePrevious } from "./CustomerRecordForm";
import { useAppState } from "../../state/State";
import classNames from "classnames";
import { CarrierStatus } from "./CarrierStatus";
import { NoData } from "../util/Empty";
import { DebugView } from "./DebugView";
import useAsyncFn from "../../utils/useAsyncFn";
import { getQuestionnaireCarrierMapping } from "../../http/questionnaire";
import { Answers } from "@Savus-Inc/questionnaire-types";
import { toast } from "react-toastify";
import { Coverages } from "./Coverages";
import { CarrierProduct } from "@Savus-Inc/questionnaire-ngn/dist/types";
import { NGNContextProvider, SandboxNGNProvider, useNGN } from "@Savus-Inc/runtime-ngn/dist/src/ngn";
import { unpackMapping } from "@Savus-Inc/questionnaire-ngn/dist/utils";
import { DocumentationLoading } from "../Documentation/DocumentationLoading";
import { v4 } from "uuid";
import { GroupPreview } from "./GroupPreview";
import { prepareQuestionnaire } from "../../utils/prepare-questionnaire";

function generateRandomString(length: number): string {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function generateRandomHash() {
  let array = new Uint8Array(10);
  window.crypto.getRandomValues(array);

  return Array.from(array)
    .map(b => b.toString(16).padStart(2, "0"))
    .join("");
}

const Spinner = () => <div className={"w-screen h-screen flex items-center justify-center"}>Loading...</div>;
export const QuestionnaireRun = () => {
  const { group, next, prev, availableGroups, jump, questionnaire, visibleQuestions, answers } = useNGN();

  const isKnockout = useMemo(() => {
    return (
      visibleQuestions.isKnockout &&
      !Object.keys(visibleQuestions.questions).length &&
      !visibleQuestions.sections.length
    );
  }, [visibleQuestions]);
  useEffect(() => {
    const element = document.getElementById("app"); // Get the <html> element
    if (!element) return;

    element.scrollTo(0, 0);
  }, [group]);

  if (!questionnaire) return null;

  if (isKnockout) {
    return (
      <div className="flex flex-col items-center justify-center h-full text-center">
        <h1 className="text-2xl font-bold text-red-500">Quote Declined</h1>
        <p className="text-lg text-gray-700 mt-4">
          We are sorry, but your quote has been declined by all selected carriers.
        </p>
        <p className="text-md text-gray-500 mt-2">If you have any questions, please contact support.</p>
      </div>
    );
  }

  return (
    <div className="flex min-h-[calc(100vh-12rem)]  bg-gray-50">
      <div className="w-96 bg-white shadow-lg flex flex-col justify-start">
        <div className="bg-primary text-xl text-white py-4 px-6">
          <h1 className="font-semibold">{questionnaire.title}</h1>
        </div>
        <div className="py-4 flex-1">
          {availableGroups.map((gr, index) => (
            <div
              key={gr.id}
              className={classNames("flex items-center gap-3 px-6 py-3 cursor-pointer transition-colors", {
                "text-primary border-l-4 border-primary bg-secondary-lighter": gr.partOrder === group,
                "text-green-600 hover:bg-gray-50": gr.partOrder < group,
                "text-gray-500 hover:bg-gray-50": gr.partOrder > group,
              })}
              onClick={cb(jump, gr.partOrder)}
            >
              <span
                className={classNames("w-3 h-3 rounded-full flex items-center justify-center text-sm border-2", {
                  "border-primary bg-primary text-white": gr.partOrder === group,
                  "border-green-500 bg-green-500 text-white": gr.partOrder < group,
                  "border-gray-300": gr.partOrder > group,
                })}
              ></span>
              <span className="font-medium">{gr.title}</span>
            </div>
          ))}
        </div>
        <div className={"flex justify-self-end min-h-96 border-t border-gray-200 "}>
          <CarrierStatus />
        </div>
      </div>

      <div className="flex-1">
        <div className={"flex"}>
          <div className={"flex-1 px-8 py-6 max-h-[calc(100vh - 12rem)] overflow-y-auto"}>
            <GroupPreview group={questionnaire.groups[group]} />
          </div>
        </div>

        <div className="fixed w-[calc(100vw-50rem)] mx-auto left-96  bottom-0  bg-white shadow-lg">
          <div className=" px-8 py-4 flex justify-between items-center">
            <button
              className={classNames("px-6 py-2.5 rounded-lg font-medium transition-colors", {
                "bg-secondary-light text-white hover:bg-secondary": group > 0,
                "bg-gray-100 text-gray-400 cursor-not-allowed": group === 0,
              })}
              disabled={group === 0}
              onClick={prev}
            >
              ← Previous
            </button>
            <button
              onClick={cb(next, true)}
              disabled={!(group + 1 <= questionnaire.groups.length)}
              className={classNames("px-6 py-2.5 rounded-lg font-medium transition-colors", {
                "bg-primary text-white hover:bg-secondary": group + 1 <= questionnaire.groups.length,
                "bg-gray-100 text-gray-400 cursor-not-allowed": !(group + 1 <= questionnaire.groups.length),
              })}
            >
              Continue →
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const QuestionnairePreview = () => {
  const {
    state: { carriers, products },
  } = useAppState();
  const [customerRecord, update] = useReducer(
    (_: CustomerRecord, action: CustomerRecord) => {
      if (!action.id && action.businessType.classCode) {
        action.id = generateRandomHash();
        const previous = getPrevious();
        previous.push();
        setPrevious(previous);
      }

      return action;
    },
    {
      id: "",
      businessName: generateRandomString(12),
      emailAddress: `${generateRandomHash()}@savus.tech`,
      phoneNumber: Math.ceil(Math.random() * 10000000000000).toString(),
      firstName: generateRandomString(6),
      lastName: generateRandomString(6),
      address: {
        address: "",
        city: "",
        stateId: "",
        zip: 0,
      },
      businessType: {
        naicsCode: "",
        naicsTitle: "",
        title: "",
        classCode: "",
      },
      progress: {
        page: 0,
        answers: {},
      },
      fcraStatement: true,
    } as unknown as CustomerRecord,
  );

  const [showCS, toggle] = useReducer(p => !p, true);
  const { id } = useParams();
  const [showCoverages, setChowCoverages] = useState<{ applicationId: string; quoteStateId: string }>();

  const {
    state: { currentQuestionnaire },
    get,
  } = useAppState();

  useEffect(() => {
    id && get(id);
  }, [id]);

  const {
    exec: getMappings,
    data: mappingsResponse,
    loading: gettingMappings,
  } = useAsyncFn(getQuestionnaireCarrierMapping);

  const onSave = useCallback(
    (page: number, answers: Answers) => {
      updatePrevious({ ...customerRecord, progress: { page, answers } });
    },
    [customerRecord],
  );

  useEffect(() => {
    if (currentQuestionnaire?.id) {
      getMappings(currentQuestionnaire.id);
    }
  }, [currentQuestionnaire?.id]);

  const mapping = useMemo(() => {
    if (mappingsResponse?.ok && currentQuestionnaire) {
      return unpackMapping(mappingsResponse.data, currentQuestionnaire);
    }

    return [];
  }, [mappingsResponse, currentQuestionnaire]);

  useEffect(() => {
    if (customerRecord.id) {
      toggle();
    }
  }, [customerRecord.id]);

  if (!currentQuestionnaire) {
    return (
      <div>
        <NoData message={"Questionnaire not found"} />
      </div>
    );
  }

  if (gettingMappings) {
    return <Spinner />;
  }

  if (showCS) {
    return (
      <Modal isOpen={showCS} onClose={cb(toast.error, "Please complete the form to proceed to questionnaire preview")}>
        <CustomerRecordForm initial={customerRecord} onChange={update} />
      </Modal>
    );
  }

  if (showCoverages) {
    return <Coverages applicationId={showCoverages.applicationId} quoteStateId={showCoverages.quoteStateId} />;
  }

  const { progress, ...CS } = customerRecord;
  const initialState = {
    carrierProducts: carriers.flatMap(c =>
      products.map(p => {
        const cp: CarrierProduct = {
          id: v4(),
          lineOfBusinessId: p.id,
          lineOfBusiness: p,
          carrierId: c.id,
          carrier: c,
          knockout: [],
          defaults: [],
          dataMap: [],
        };

        return cp;
      }),
    ),
    mapping: mapping.map(
      v =>
        ({
          id: (Math.random() * Date.now()).toString(),
          lineOfBusinessId: currentQuestionnaire?.lineOfBusinessId,
          lineOfBusiness: currentQuestionnaire?.lineOfBusiness || {
            name: "BOP",
            shortName: "BOP",
            id: "",
          },
          carrierId: v.carrierId,
          carrier: carriers.find(c => c.id === v.carrierId),
          defaults: v.defaults,
          knockout: v.rules,
          dataMap: v.questions,
        }) as CarrierProduct,
    ) as CarrierProduct[],
    ...progress,
  };

  if (customerRecord.quoteStateId) {
    return (
      <div className={"flex flex-col justify-center"}>
        <NGNContextProvider
          onEnd={cb(setChowCoverages, {
            applicationId: customerRecord.applicationId || "",
            quoteStateId: customerRecord.quoteStateId,
          })}
          asyncConfig={{
            customerRecordId: customerRecord.id,
            questionnaireApiUrl: `${process.env.REACT_APP_SERVER_URL}/questionnaire/${customerRecord.applicationId}/${customerRecord.quoteStateId}`,
            getToken: () => localStorage.getItem("__token"),
          }}
          context={{ customerRecord: CS, agent: { email: "admin@savus.tech", lastName: "Savus", firstName: "Admin" } }}
          fieldMap={{
            Address: ["customerRecord", "address", "address"],
            City: ["customerRecord", "address", "city"],
            Suite: ["customerRecord", "address", "suite"],
            BusinessTypeNAICS: ["customerRecord", "businessType", "naicsCode"],
            BusinessTypeNAICSTitle: ["customerRecord", "businessType", "naicsTitle"],
            BusinessTypeTitle: ["customerRecord", "businessType", "title"],
            Product: ["product", "name"],
            ProductShortName: ["product", "shortName"],
            State: ["customerRecord", "address", "state", "shortName"],
            StateName: ["customerRecord", "address", "state", "name"],
            StateNameWithCode: ["customerRecord", "address", "state", "fullName"],
            ZipCode: ["customerRecord", "address", "zip"],
            ClassCodeChubb: ["customerRecord", "businessType", "classCode"],
            BusinessName: ["customerRecord", "businessName"],
            PolicyQuoteIdChubb: ["policyQuoteIdChubb"],
            AgentFirstName: ["agent", "firstName"],
            AgentLastName: ["agent", "lastName"],
            AgentEmail: ["agent", "email"],
            __X: ["__X"],
          }}
          spinner={<DocumentationLoading message={"Loading Questionnaire..."} />}
        >
          <QuestionnaireRun />
          <DebugView />
        </NGNContextProvider>
      </div>
    );
  }

  return (
    <div className={"flex flex-col justify-center"}>
      <SandboxNGNProvider
        onEnd={cb(alert, "Questionnaire Completed")}
        questionnaire={prepareQuestionnaire(currentQuestionnaire)}
        initialState={initialState}
        onSave={onSave}
        context={{ customerRecord: CS, agent: { email: "admin@savus.tech", lastName: "Savus", firstName: "Admin" } }}
        fieldMap={{
          Address: ["customerRecord", "address", "address"],
          City: ["customerRecord", "address", "city"],
          Suite: ["customerRecord", "address", "suite"],
          BusinessTypeNAICS: ["customerRecord", "businessType", "naicsCode"],
          BusinessTypeNAICSTitle: ["customerRecord", "businessType", "naicsTitle"],
          BusinessTypeTitle: ["customerRecord", "businessType", "title"],
          Product: ["product", "name"],
          ProductShortName: ["product", "shortName"],
          State: ["customerRecord", "address", "state", "shortName"],
          StateName: ["customerRecord", "address", "state", "name"],
          StateNameWithCode: ["customerRecord", "address", "state", "fullName"],
          ZipCode: ["customerRecord", "address", "zip"],
          ClassCodeChubb: ["customerRecord", "businessType", "classCode"],
          BusinessName: ["customerRecord", "businessName"],
          PolicyQuoteIdChubb: ["policyQuoteIdChubb"],
          AgentFirstName: ["agent", "firstName"],
          AgentLastName: ["agent", "lastName"],
          AgentEmail: ["agent", "email"],
          __X: ["__X"],
        }}
        spinner={<Spinner />}
      >
        <QuestionnaireRun />
        <DebugView />
      </SandboxNGNProvider>
    </div>
  );
};
