import { CustomerRecord } from "../../types/customer-record";
import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import businessTypes from "../../data/savus-class-codes.json";
import zips from "../../data/zips.json";
import { chubbClassCodes } from "../../data/chubb-class-codes";
import { SelectAutocomplete } from "../util/SelectAutocomplete";
import { unpack } from "../../utils/unpack";
import { cb } from "../../utils/cd";
import { useAppState } from "../../state/State";
import useAsyncFn from "../../utils/useAsyncFn";
import { listPreviousQuotes, startQuote } from "../../http/app";
import { listBusinessTypes } from "../../http/referent";
import classNames from "classnames";
import { toast } from "react-toastify";

const Spinner = () => <div className={"w-screen h-screen flex items-center justify-center"}>Loading...</div>;

const getQuoteLabel = (pr: CustomerRecord): string => {
  return `${pr.businessName} (${pr.address.zip} ${pr.address.state?.shortName || ""}) (TTS: ${
    pr.businessType.classCode
  }) page: ${pr.progress.page}`;
};

export function getPrevious() {
  const prevStr = localStorage.getItem("__previous_quotes");
  if (prevStr) {
    try {
      return JSON.parse(prevStr) as CustomerRecord[];
    } catch (e) {
      return [];
    }
  }

  return [];
}

export function setPrevious(v: CustomerRecord[]) {
  localStorage.setItem("__previous_quotes", JSON.stringify(v));
}

export function updatePrevious(v: CustomerRecord) {
  const previous = getPrevious();
  const idx = previous.findIndex(a => a.id === v.id);
  if (idx !== -1) {
    previous[idx] = v;
  } else {
    previous.push(v);
  }

  setPrevious(previous);
}

export const CustomerRecordForm = ({
                                     onChange,
                                     initial,
                                   }: {
  initial: CustomerRecord;
  onChange: (cs: CustomerRecord) => void;
}) => {
  const {
    state: { applications, states, carriers, currentQuestionnaire: item },
  } = useAppState();

  const { exec: listQuotes, loading: listingQuotes, data: quotesResponse } = useAsyncFn(listPreviousQuotes);
  const {
    exec: listBusinesses,
    loading: listingBusinessTypes,
    data: businessTypesResponse,
  } = useAsyncFn(listBusinessTypes);

  const [quoteAction, setQuoteAction] = useState(0);

  const customers = useMemo(() => {
    if (quotesResponse?.ok) {
      return quotesResponse.data.data.flatMap(c =>
        c.questionnaireState.map((qs: any) => ({ id: qs.id, customerId: c.id, businessName: c.businessName })),
      );
    }

    return [];
  }, [quotesResponse]);

  const [customerRecord, update] = useReducer(
    (
      prevState: CustomerRecord,
      action: {
        path:
          | "zip"
          | "state"
          | "businessType"
          | "businessName"
          | "TTS"
          | "applicationId"
          | "customerId"
          | "quoteStateId"
          | "address"
          | "email"
          | "phoneNumber"
          | "firstName"
          | "lastName"
          | "city"
          | "businessTypeId"
          | "prevQuoteStateId";
        value: any;
      },
    ) => {
      switch (action.path) {
        case "applicationId":
          prevState.applicationId = action.value;
          break;
        case "customerId":
          prevState.id = action.value;
          break;
        case "quoteStateId":
          prevState.quoteStateId = action.value;
          onChange(prevState);
          break;
        case "prevQuoteStateId":
          const { id, customerId, businessName } = JSON.parse(action.value);
          prevState.quoteStateId = id;
          prevState.id = customerId;
          prevState.businessName = businessName;
          onChange(prevState);
          break;
        case "address":
          prevState.address.address = action.value;
          break;
        case "email":
          prevState.emailAddress = action.value;
          break;
        case "phoneNumber":
          break;
        case "firstName":
          prevState.firstName = action.value;
          break;
        case "lastName":
          prevState.lastName = action.value;
          break;
        case "city":
          prevState.address.city = action.value;
          break;
        case "businessName":
          prevState.businessName = action.value;
          break;
        case "zip":
          prevState.address.zip = action.value;
          break;
        case "businessTypeId":
          prevState.businessTypeId = action.value;
          break;
        case "state": {
          const bt = states.find(v => v.id === action.value);
          prevState.address.state = {
            id: bt?.id,
            name: bt?.name,
            shortName: bt?.shortName,
            fullName: `${bt?.name} (${bt?.shortName})`,
          } as never;
          prevState.address.stateId = bt?.id as never;
          break;
        }
        case "businessType": {
          const bt = businessTypes.find(v => v.Savus_Id === action.value);
          prevState.businessType = {
            id: bt["Savus_Id"],
            naicsCode: bt["2022 NAICS Code"],
            title: bt["Savus UI description"],
            naicsTitle: bt["2022 NAICS Title"],
            classCode: (Object.entries(chubbClassCodes).find(([_, bd]) =>
              bd.some(v => v === bt["Savus UI description"]),
            ) || [])[0],
          };
          break;
        }
        case "TTS": {
          const cc = (chubbClassCodes as Record<string, string[]>)[action.value as string];
          if (cc) {
            const bt = businessTypes.find(v => (cc as string[]).includes(v["Savus UI description"]));
            prevState.businessType = {
              id: bt["Savus_Id"],
              naicsCode: bt["2022 NAICS Code"],
              title: bt["Savus UI description"],
              naicsTitle: bt["2022 NAICS Title"],
              classCode: action.value as string,
            };
            if (quoteAction === 1) listBusinesses(bt["Savus UI description"]);
          }

          break;
        }
      }
      return { ...prevState };
    },
    initial,
  );

  const pickQuote = useCallback((val: string) => {
    update({ path: "prevQuoteStateId", value: val });
  }, []);

  const createQuote = useCallback(() => {
    if (!item?.lineOfBusinessId) {
      toast.error("Questionnaire must be associated with the product and published to create a real quote");
      return;
    }

    if (!customerRecord.applicationId) {
      toast.error("Application must be selected to create a real quote");
      return;
    }

    if (!customerRecord.address.address) {
      toast.error("Address is required to create a real quote");
      return;
    }

    if (customerRecord.quoteStateId) {
      onChange(customerRecord);
    } else if (customerRecord.id) {
      toast.info("Creating new quote");
      startQuote(
        customerRecord.applicationId,
        customerRecord.quoteStateId as string,
        customerRecord.id,
        carriers.map(c => ({ carrierId: c.id, lineOfBusinessId: item?.lineOfBusinessId as string })),
      )
        .then(v => {
          if (v.ok) {
            toast.success("New Quote Created");
            update({ path: "quoteStateId", value: v.data.id });
          }
        })
        .catch(e => {
          toast.error("Failed to create a qoute");
          console.error(e);
        });
    } else {
      const { id, businessType, progress, applicationId, ...payload } = customerRecord;
      alert("Not implemented");
    }
  }, [customerRecord]);

  const availableZips = useMemo(
    () =>
      customerRecord.address.state
        ? (zips as Record<string, string[]>)[customerRecord.address.state.shortName] || []
        : [],
    [customerRecord],
  );

  const previousQuotes = useMemo(() => {
    return getPrevious();
  }, []);

  const selectedQuote = useMemo(() => {
    const pr = previousQuotes.find(v => v.id === customerRecord.id);

    if (pr) {
      return getQuoteLabel(pr) || "Search previous quotes";
    }

    return "Search previous quotes";
  }, [previousQuotes, customerRecord]);

  const includedBusinessTypes = useMemo(
    () =>
      customerRecord.businessType.classCode
        ? ((chubbClassCodes as Record<string, string[]>)[customerRecord.businessType.classCode] as string[])
        : [],
    [customerRecord],
  );

  useEffect(() => {
    if (quoteAction === 1 && customerRecord.applicationId && !quotesResponse) {
      listQuotes(customerRecord.applicationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteAction, !quotesResponse, customerRecord.applicationId]);

  useEffect(() => {
    if (applications.length) {
      update({ path: "applicationId", value: applications.find(v => v.name.startsWith("Agent"))?.id });
    }
  }, [applications.length]);

  useEffect(() => {
    if (businessTypesResponse?.ok) {
      update({ path: "businessTypeId", value: businessTypesResponse.data?.data[0]?.id });
    }
  }, [businessTypesResponse]);

  if (listingBusinessTypes || listingQuotes) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col gap-4 bg-white ml-1 rounded p-2 ">
      <div className="flex">
        <div
          onClick={cb(setQuoteAction, 0)}
          className={classNames("rounded-xl py-1 px-5 transition-all cursor-pointer", {
            "bg-sky-500 text-white translate-x-3": quoteAction === 0,
            "bg-gray-200": quoteAction !== 0,
          })}
        >
          Test Quote
        </div>
        <div
          onClick={cb(setQuoteAction, 1)}
          className={classNames("rounded-xl py-1 px-5", {
            "bg-sky-500 text-white -translate-x-3": quoteAction !== 0,
            "bg-gray-200": quoteAction === 0,
          })}
        >
          Real Quote
        </div>
      </div>

      <h1 className={"text-xl font-bold"}>Customer Details</h1>
      {quoteAction === 0 && (
        <>
          <div className="flex gap-4 items-center border-b-2 border-gray-200 pb-4">
            <SelectAutocomplete
              suggestions={previousQuotes.map(v => ({ label: getQuoteLabel(v), value: v.id || "" }))}
              onSelect={val => {
                const sel = previousQuotes.find(v => v.id === val);
                sel && onChange(sel);
              }}
              value={selectedQuote}
            />
            <div
              className={"text-red-500 cursor-pointer underline text-nowrap"}
              onClick={() => {
                setPrevious([]);
                window.location.reload();
              }}
            >
              Clear History
            </div>
          </div>
        </>
      )}
      {quoteAction === 1 && (
        <>
          <div className="flex gap-4 items-center border-t-2 border-gray-200 pt-4">
            <SelectAutocomplete
              suggestions={(applications || []).map(v => ({
                label: v.name,
                value: v.id || "",
              }))}
              onSelect={val => {
                update({ path: "applicationId", value: val });
              }}
              value={applications.find(v => v.id === customerRecord.applicationId)?.name || "Select Application"}
            />
          </div>
          <div className="flex gap-4 items-center border-b-2 border-gray-200 pb-4">
            <SelectAutocomplete
              suggestions={(customers || []).map(v => ({
                label: v.businessName,
                value: JSON.stringify(v),
              }))}
              onSelect={val => {
                pickQuote(val);
              }}
              value={customerRecord?.quoteStateId ? customerRecord.businessName : "Select previous customer quotes"}
            />
          </div>
        </>
      )}

      <div className="flex gap-4 items-center">
        <label htmlFor="name" className="block text-gray-700 font-medium w-full max-w-[220px] bg-gray-100 px-2">
          Business Name:
        </label>
        <input
          value={customerRecord.businessName}
          className="flex-1 px-1 py-[2px] border border-gray-300 rounded-md focus:outline-none focus:border-sky-500"
          onChange={unpack(v => update({ value: v, path: "businessName" }))}
        />
      </div>
      {quoteAction === 1 && (
        <>
          <div className="flex gap-4 items-center">
            <label htmlFor="name" className="block text-gray-700 font-medium w-full max-w-[220px] bg-gray-100 px-2">
              Owner Email:
            </label>
            <input
              value={customerRecord.emailAddress || ""}
              className="flex-1 px-1 py-[2px] border border-gray-300 rounded-md focus:outline-none focus:border-sky-500"
              onChange={unpack(v => update({ value: v, path: "email" }))}
            />
          </div>
          <div className="flex gap-4 items-center">
            <label htmlFor="name" className="block text-gray-700 font-medium w-full max-w-[220px] bg-gray-100 px-2">
              Phone Number:
            </label>
            <input
              value={customerRecord.phoneNumber || ""}
              className="flex-1 px-1 py-[2px] border border-gray-300 rounded-md focus:outline-none focus:border-sky-500"
              onChange={unpack(v => update({ value: v, path: "phoneNumber" }))}
            />
          </div>
          <div className="flex gap-4 items-center">
            <label htmlFor="name" className="block text-gray-700 font-medium w-full max-w-[220px] bg-gray-100 px-2">
              First Name:
            </label>
            <input
              value={customerRecord.firstName || ""}
              className="flex-1 px-1 py-[2px] border border-gray-300 rounded-md focus:outline-none focus:border-sky-500"
              onChange={unpack(v => update({ value: v, path: "firstName" }))}
            />
          </div>
          <div className="flex gap-4 items-center border-b-2 border-gray-200 pb-4">
            <label htmlFor="name" className="block text-gray-700 font-medium w-full max-w-[220px] bg-gray-100 px-2">
              Last Name:
            </label>
            <input
              value={customerRecord.lastName || ""}
              className="flex-1 px-1 py-[2px] border border-gray-300 rounded-md focus:outline-none focus:border-sky-500"
              onChange={unpack(v => update({ value: v, path: "lastName" }))}
            />
          </div>
          <div className="flex gap-4 items-center">
            <label htmlFor="name" className="block text-gray-700 font-medium w-full max-w-[220px] bg-gray-100 px-2">
              Address:
            </label>
            <input
              value={customerRecord.address?.address || ""}
              className="flex-1 px-1 py-[2px] border border-gray-300 rounded-md focus:outline-none focus:border-sky-500"
              onChange={unpack(v => update({ value: v, path: "address" }))}
            />
          </div>
          <div className="flex gap-4 items-center">
            <label htmlFor="name" className="block text-gray-700 font-medium w-full max-w-[220px] bg-gray-100 px-2">
              City:
            </label>
            <input
              value={customerRecord.address?.city || ""}
              className="flex-1 px-1 py-[2px] border border-gray-300 rounded-md focus:outline-none focus:border-sky-500"
              onChange={unpack(v => update({ value: v, path: "city" }))}
            />
          </div>
        </>
      )}
      <div className="flex gap-4 items-center">
        <label htmlFor="name" className="block text-gray-700 font-medium w-full max-w-[220px] bg-gray-100 px-2">
          State:
        </label>
        <SelectAutocomplete
          onSelect={v => update({ path: "state", value: v })}
          suggestions={states.map(v => ({ label: `${v.name} (${v.shortName})`, value: v.id }))}
          value={customerRecord?.address?.state?.name || "Select..."}
        />
      </div>
      <div className="flex gap-4 items-center">
        <label htmlFor="name" className="block text-gray-700 font-medium w-full max-w-[220px] bg-gray-100 px-2">
          Zip Code:
        </label>
        <SelectAutocomplete
          suggestions={availableZips.map(v => ({
            label: v,
            value: v,
          }))}
          onSelect={v => update({ path: "zip", value: v })}
          value={customerRecord.address.zip || "Select..."}
        />
      </div>
      <div className="flex gap-4 items-center">
        <label htmlFor="name" className="block text-gray-700 font-medium w-full max-w-[220px] bg-gray-100 px-2">
          TTS
        </label>
        <SelectAutocomplete
          suggestions={Object.keys(chubbClassCodes)
            .sort((a, b) => Number(a) - Number(b))
            .map(v => ({ label: v, value: v }))}
          onSelect={v => update({ path: "TTS", value: v })}
          value={customerRecord.businessType?.classCode || "Select..."}
        />
      </div>
      <div className="flex gap-4 items-center">
        <label htmlFor="name" className="block text-gray-700 font-medium w-full max-w-[220px] bg-gray-100 px-2">
          Included Business Types:
        </label>

        <div className="flex flex-col">
          {includedBusinessTypes.map(v => (
            <div key={v} className={"border-b"}>
              {v}
            </div>
          ))}
        </div>
      </div>

      <div className={"flex gap-2 justify-center items-center w-full mt-5"}>
        {quoteAction === 0 && (
          <button className={"py-2 px-10 rounded bg-sky-500 text-white"} onClick={cb(onChange, customerRecord)}>
            Test Quote (builder)
          </button>
        )}
        {quoteAction === 1 && (
          <button className={"py-2 px-10 rounded bg-sky-500 text-white"} onClick={createQuote}>
            Real Quote (builder)
          </button>
        )}
      </div>
      <div className={"text-orange-400 w-full text-center"}>At the minimum Business Type is required to run quote</div>
    </div>
  );
};
