import { QuestionValues } from "./QuestionValues";
import { useBuilderNGN } from "./BuilderNGN";
import { unpack } from "../../utils/unpack";
import { NoData } from "../util/Empty";
import { Val } from "@Savus-Inc/dsl/dist/types";
import {
  AddMoreIconName,
  InputType,
  QuestionValueAutocompleteIntegration,
  QuestionValueFormatting,
} from "@Savus-Inc/questionnaire-types";
import { cb } from "../../utils/cd";
import { useMemo } from "react";
import { AccordionItem, TitleWithAction } from "../util/Accourdion";
import { QuestionsGroupQuestions } from "./QuestionsGroupQuestions";
import { DisplaySelection } from "./DisplaySelection";
import { Editor } from "../util/Editor";
import { getAllRefs } from "../Rules/utils/getAvailableRefs";
import { QuestionMapping } from "./QuestionMapping";
import MultiSelect from "../util/MultiSelect";
import { useAppState } from "../../state/State";
import { SelectAutocomplete } from "../util/SelectAutocomplete";

export const QuestionUI = () => {
  const { changeQuestionUIDetails, updateQuestion, selectedQuestion, currentGroup, context, insertQuestion, item } =
    useBuilderNGN();

  const {
    state: { carriers },
  } = useAppState();

  const availableRefs = useMemo(
    () =>
      getAllRefs(context()).map(v => ({
        label: v.label.toString(),
        value: v.referenceId,
      })),
    [context],
  );

  const subQuestions = useMemo(() => {
    return currentGroup?.questions.filter(v => v.parentId === selectedQuestion?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuestion, currentGroup, item]);

  const availableInputTypes = useMemo(() => {
    if (!selectedQuestion) return [];
    return Object.values(InputType).filter(v => {
      if (selectedQuestion.question?.autocompleteIntegration === QuestionValueAutocompleteIntegration.OwnValues) {
        return ![InputType.Phone, InputType.RichText, InputType.Header, InputType.Textarea].includes(v);
      }

      switch (selectedQuestion.question?.kind) {
        case Val.String:
          return [
            InputType.Phone,
            InputType.RichText,
            InputType.Header,
            InputType.Textarea,
            InputType.LocationBusinessType,
            InputType.WCBusinessDescription,
            InputType.Statement,
            InputType.State,
          ].includes(v);
        case Val.Number:
          return false;
        case Val.Bool:
          return v === InputType.YesNo;
        case Val.Date:
          return false;
        default:
          return false;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, selectedQuestion]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const availableParents = useMemo(() => {
    if (!currentGroup) return [];
    return currentGroup?.questions.filter(v => !v.parentId && v.id !== selectedQuestion?.id) || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGroup, selectedQuestion]);

  if (!selectedQuestion || !currentGroup) return <NoData />;

  const getSelectValue = () => {
    const matchingSection = currentGroup.sections?.find(s => s.id === selectedQuestion.sectionId);

    return matchingSection?.title;
  };

  return (
    <div className={"flex flex-col gap-3 pb-10"}>
      <div className=" py-2 flex flex-col gap-3 ">
        <div className={"pt-4 flex gap-4 items-center"}>
          <label className={"w-48"} htmlFor="label">
            ID:{" "}
          </label>
          <Editor
            refs={[]}
            initial={selectedQuestion.question?.externalId || ""}
            onChange={changeQuestionUIDetails("externalId")}
          />
        </div>
        <div className={"pt-4 flex gap-4 items-center"}>
          <label className={"w-48"} htmlFor="label">
            Order:{" "}
          </label>
          <input
            type="number"
            defaultValue={selectedQuestion.partOrder}
            className="w-full border border-gray-300 rounded py-[2px] focus:outline-none focus:border-sky-500 px-1"
            onBlur={unpack(updateQuestion("partOrder"))}
          />
        </div>
        <div className={"flex gap-4 items-center"}>
          <label className={"w-48"} htmlFor="label">
            Label:{" "}
          </label>
          <Editor
            refs={availableRefs}
            initial={selectedQuestion.question?.label || ""}
            onChange={changeQuestionUIDetails("label")}
          />
        </div>
        <div className={"flex gap-4 items-center"}>
          <label className={"w-48"} htmlFor="label">
            Carriers:{" "}
          </label>
          <div className={"flex gap-2 w-full min-w-[250px]"}>
            <MultiSelect
              onChange={updateQuestion("showForCarriers")}
              options={carriers.map(v => ({ label: v.name, value: v.name }))}
              value={selectedQuestion.showForCarriers}
            />
          </div>
        </div>
      </div>

      <div className="w-full h-[1px] bg-gray-200"></div>

      <div className="py-2 flex flex-col gap-3  ">
        {!selectedQuestion.parentId && currentGroup.sections && (
          <div className={"flex gap-4 items-center"}>
            <label className={"w-48"} htmlFor="section">
              Section
            </label>
            <SelectAutocomplete
              suggestions={[
                { label: "None", value: "" },
                ...currentGroup.sections.map(item => ({
                  label: `${item.title} (S-${item.partOrder})`,
                  value: item.id,
                })),
              ]}
              onSelect={updateQuestion("sectionId")}
              value={getSelectValue()}
              placeholder="Select Section"
              styles="justify-between border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-sky-500 border"
            />
          </div>
        )}

        {!subQuestions?.length && (
          <>
            {!!availableParents.length && (
              <div className={"flex gap-4 items-center"}>
                <label className={"w-48"} htmlFor="parent">
                  Container question
                </label>
                <SelectAutocomplete
                  suggestions={[
                    { label: "None", value: "" },
                    ...availableParents.map(item => ({
                      label: `${item.question?.label} ${item.question?.externalId || ""}`,
                      value: item.id,
                    })),
                  ]}
                  value={"Select Container question"}
                  onSelect={updateQuestion("parentId")}
                  placeholder="Select Container question"
                  styles="justify-between border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-sky-500 border"
                />
              </div>
            )}
          </>
        )}
        <div className="flex gap-4 items-center">
          <label className={"w-48"} htmlFor="parent">
            Page Title
          </label>
          <SelectAutocomplete
            suggestions={item.groups.map(item => ({ label: item.title, value: item.id }))}
            value={item.groups.find(g => g.id === selectedQuestion.groupId)?.title}
            onSelect={updateQuestion("groupId")}
            placeholder="Page Title"
            styles="justify-between border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-sky-500 border"
          />
        </div>
      </div>

      <div className="w-full h-[1px] bg-gray-200"></div>

      <div className="w-full py-2 flex flex-col gap-3 ">
        <div className={"flex gap-4 items-center"}>
          <label className={"w-48"} htmlFor="subText">
            Sub Text:{" "}
          </label>
          <div className="w-full">
            <Editor
              refs={availableRefs}
              initial={selectedQuestion.question?.subText || ""}
              onChange={changeQuestionUIDetails("subText")}
            />
          </div>
        </div>

        {!subQuestions?.length && (
          <>
            <div className={"flex gap-4 items-center"}>
              <label className={"w-48"} htmlFor="placeholder">
                Placeholder:{" "}
              </label>
              <div className="w-full">
                <Editor
                  refs={availableRefs}
                  initial={selectedQuestion.question?.placeholder || ""}
                  onChange={changeQuestionUIDetails("placeholder")}
                />
              </div>
            </div>
            <div className={"flex gap-4 items-center"}>
              <label className={"w-48"} htmlFor="tooltip">
                Tooltip:{" "}
              </label>
              <div className="w-full">
                <Editor
                  refs={availableRefs}
                  initial={selectedQuestion.question?.tooltip || ""}
                  onChange={changeQuestionUIDetails("tooltip")}
                />
              </div>
            </div>
          </>
        )}
      </div>

      <div className="w-full h-[1px] bg-gray-200"></div>

      {!subQuestions?.length && (
        <div className={"pt-2 flex gap-4 items-center"}>
          <label className={"w-48"} htmlFor="kind">
            Answer Kind:{" "}
          </label>
          <SelectAutocomplete
            suggestions={Object.values(Val).map(item => ({ label: item, value: item }))}
            onSelect={changeQuestionUIDetails("kind")}
            value={selectedQuestion.question?.kind}
            styles="justify-between border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-sky-500 border"
          />
        </div>
      )}

      <div className={"flex gap-4 items-center"}>
        <label className={"w-48"} htmlFor="format">
          Input Type:{" "}
        </label>
        <SelectAutocomplete
          suggestions={[
            { label: "Default", value: "Default" },
            ...availableInputTypes.map(item => ({ label: item, value: item })),
          ]}
          onSelect={changeQuestionUIDetails("inputType")}
          value={selectedQuestion.question?.inputType || "Default"}
          styles="justify-between border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-sky-500 border"
        />
      </div>

      {!subQuestions?.length && (
        <>
          <div className={"flex gap-4 items-center"}>
            <label className={"w-48"} htmlFor="format">
              Answer Format:{" "}
            </label>
            <SelectAutocomplete
              suggestions={[
                { label: "None", value: "None" },
                ...Object.values(QuestionValueFormatting).map(item => ({ label: item, value: item })),
              ]}
              onSelect={changeQuestionUIDetails("format")}
              value={selectedQuestion.question?.format || "None"}
              styles="justify-between border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-sky-500 border"
            />
          </div>
        </>
      )}

      <div className={"flex gap-4 items-center justify-start"}>
        <label className={"w-[167px]"} htmlFor="multipleAnswers">
          Multiple answers:{" "}
        </label>
        <input
          type="checkbox"
          id={"multipleAnswers"}
          className="border border-gray-300 rounded py-[2px] focus:outline-none focus:border-sky-500"
          checked={selectedQuestion.question?.multipleAnswers || false}
          onChange={cb(changeQuestionUIDetails("multipleAnswers"), !selectedQuestion.question?.multipleAnswers)}
        />
      </div>

      {!subQuestions?.length && (
        <>
          <div className={"flex gap-4 items-center"}>
            <label className={"w-48"} htmlFor="autocompleteIntegration">
              Answer Options:{" "}
            </label>
            <SelectAutocomplete
              suggestions={[
                { label: "OpenEnded", value: "Open Ended" },
                ...Object.values(QuestionValueAutocompleteIntegration).map(item => ({ label: item, value: item })),
              ]}
              onSelect={v =>
                changeQuestionUIDetails("autocompleteIntegration")(v === "Open Ended" ? null : (v as string))
              }
              value={selectedQuestion.question?.autocompleteIntegration || "OpenEnded"}
              styles="justify-between border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-sky-500 border"
            />
          </div>
        </>
      )}

      {!!selectedQuestion.question?.multipleAnswers && (
        <>
          <div className="flex gap-4 items-center">
            <DisplaySelection
              select={changeQuestionUIDetails("answerDisplay")}
              selected={selectedQuestion.question?.answerDisplay}
              label={"Answer Display"}
            />
          </div>
          <div className={"flex gap-4 items-center"}>
            <label className={"w-48"} htmlFor="format">
              Max Answers:{" "}
            </label>
            <input
              type="number"
              defaultValue={selectedQuestion.question?.maxAnswers}
              className="w-full border border-gray-300 rounded py-[2px] focus:outline-none focus:border-sky-500 px-1"
              onBlur={unpack(changeQuestionUIDetails("maxAnswers"))}
            />
          </div>
          <div className={"flex gap-4 items-center"}>
            <label className={"w-48"} htmlFor="format">
              Min Answers:{" "}
            </label>
            <input
              type="number"
              defaultValue={selectedQuestion.question?.minAnswers}
              className="w-full border border-gray-300 rounded py-[2px] focus:outline-none focus:border-sky-500 px-1"
              onBlur={unpack(changeQuestionUIDetails("minAnswers"))}
            />
          </div>
          <div className={"flex gap-4 items-center"}>
            <label className={"w-48"} htmlFor="icon">
              Add More Icon Action:{" "}
            </label>
            <SelectAutocomplete
              suggestions={Object.values(AddMoreIconName).map(item => ({
                label: item,
                value: item,
              }))}
              onSelect={changeQuestionUIDetails("addMoreIcon")}
              value={selectedQuestion.question?.addMoreIcon || "user"}
              styles="justify-between border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-sky-500 border"
            />
          </div>
          <div className={"flex gap-4 items-center"}>
            <label className={"w-48"} htmlFor="format">
              Add More Action Text:{" "}
            </label>
            <input
              defaultValue={selectedQuestion.question?.addMoreText}
              className="w-full border border-gray-300 rounded py-[2px] focus:outline-none focus:border-sky-500 px-1"
              onBlur={unpack(changeQuestionUIDetails("addMoreText"))}
            />
          </div>
        </>
      )}

      {!!subQuestions?.length && (
        <DisplaySelection
          select={changeQuestionUIDetails("subQuestions")}
          selected={selectedQuestion.question?.subQuestions}
          label={"Question Display"}
        />
      )}

      {!subQuestions?.length && (
        <>
          {selectedQuestion.question?.autocompleteIntegration === QuestionValueAutocompleteIntegration.OwnValues && (
            <QuestionValues
              values={selectedQuestion.question?.values}
              kind={selectedQuestion.question?.kind}
              onChange={unpack(changeQuestionUIDetails("values"))}
            />
          )}
        </>
      )}
      <QuestionMapping />
      {!selectedQuestion.parentId && (
        <AccordionItem
          title={
            <TitleWithAction title={"Sub Questions"} add={cb(insertQuestion, currentGroup.id, selectedQuestion?.id)} />
          }
          initial={true}
          key={JSON.stringify(subQuestions)}
        >
          <QuestionsGroupQuestions
            questions={(subQuestions || []).sort((a, b) => a.partOrder - b.partOrder)}
            container
            key={JSON.stringify(subQuestions)}
            groupId={currentGroup.id}
          />
        </AccordionItem>
      )}
    </div>
  );
};
