import React from "react";

const QuestionTablePagination = ({ table }: { table: any }) => {
  return (
    <div className='flex items-center gap-2'>
      <div className='flex items-center'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth='1.5'
          stroke='green'
          className='size-6 cursor-pointer'
          onClick={() => table.firstPage()}
        >
          <path strokeLinecap='round' strokeLinejoin='round' d='M18.75 19.5L11.25 12l7.5-7.5' />
          <path strokeLinecap='round' strokeLinejoin='round' d='M11.25 19.5L3.75 12l7.5-7.5' />
        </svg>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth='1.5'
          stroke='orange'
          className='size-6 cursor-pointer'
          onClick={() => table.previousPage()}
        >
          <path strokeLinecap='round' strokeLinejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' />
        </svg>
      </div>
      <div className='flex items-center'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth='1.5'
          stroke='orange '
          className='size-6 cursor-pointer'
          onClick={() => {
            if (table.getState().pagination.pageIndex + 1 !== table.getPageCount()) {
              table.nextPage();
            }
          }}
        >
          <path strokeLinecap='round' strokeLinejoin='round' d='M8.25 4.5L15.75 12l-7.5 7.5' />
        </svg>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth='1.5'
          stroke='green'
          className='size-6 cursor-pointer'
          onClick={() => {
            table.lastPage();
          }}
        >
          <path strokeLinecap='round' strokeLinejoin='round' d='M5.25 4.5L12.75 12l-7.5 7.5' />
          <path strokeLinecap='round' strokeLinejoin='round' d='M12.75 4.5L20.25 12l-7.5 7.5' />
        </svg>
      </div>

      <span>
        Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
      </span>
    </div>
  );
};

export default QuestionTablePagination;
