import { AccordionItem } from "../util/Accourdion";
import classNames from "classnames";
import React from "react";
import { useNGN } from "@Savus-Inc/runtime-ngn/dist/src/ngn";

export const CarrierStatus = () => {
  const { carrierProductStatus, questionnaire } = useNGN();
  if (!questionnaire) return null;

  return (
    <>
      <div className={"flex gap-2"}>
        {!!carrierProductStatus.length && (
          <div className={"bg-white p-2 gap-2 flex flex-col w-80"}>
            {carrierProductStatus.map(v => (
              <div key={`${v.product.id}-${v.carrier.id}`} className={""}>
                <AccordionItem
                  title={
                    <div
                      className={classNames("flex gap-2 items-center", {
                        "text-red-500 font-semibold": v.declinations.length,
                      })}
                    >
                      <div
                        className={classNames("h-3 w-3 rounded rounded-full", {
                          "bg-red-500": v.declinations.length,
                          "bg-secondary": !v.declinations.length,
                        })}
                      ></div>
                      <span>{v.carrier.name}</span>
                      <span> - </span>
                      <span>{v.product.name}</span>
                    </div>
                  }
                >
                  {v.declinations.length ? (
                    <div className={"flex flex-col gap-2 text-sm"}>
                      {v.declinations.map(v => (
                        <div key={v.message} className={"text-red-700"}>
                          {v.message}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={"text-green-500"}>There are no declinations</div>
                  )}
                </AccordionItem>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
