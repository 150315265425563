import { StateFilter, useDocumentationContext } from "./DocumentationContext";
import { useMemo, useState, useEffect } from "react";

export const DocumentationFilters = () => {
  const {
    carriers,
    states,
    filterCarriers,
    setFilterCarriers,
    filterStates,
    setFilterStates,
    searchTerm,
    setSearchTerm,
  } = useDocumentationContext();
  const [isCarrierDropdownOpen, setIsCarrierDropdownOpen] = useState(false);
  const [isStateDropdownOpen, setIsStateDropdownOpen] = useState(false);
  const [carrierSearchTerm, setCarrierSearchTerm] = useState("");
  const [stateSearchTerm, setStateSearchTerm] = useState("");
  const [editingCarrier, setEditingCarrier] = useState<string | null>(null);
  const [tempClasscode, setTempClasscode] = useState("");

  // Get unique carriers and filter them based on search
  const uniqueCarriers = useMemo(() => Array.from(new Set(carriers)), [carriers]);
  const filteredCarriers = useMemo(
    () => uniqueCarriers.filter(carrier => carrier.toLowerCase().includes(carrierSearchTerm.toLowerCase())),
    [uniqueCarriers, carrierSearchTerm],
  );

  // State filtering logic
  const filteredStates = useMemo(
    () =>
      states.filter(
        state =>
          state.name.toLowerCase().includes(stateSearchTerm.toLowerCase()) ||
          state.shortName.toLowerCase().includes(stateSearchTerm.toLowerCase()),
      ),
    [states, stateSearchTerm],
  );

  const toggleCarrier = (carrier: string) => {
    if (filterCarriers.some(f => f.carrier === carrier)) {
      setFilterCarriers(filterCarriers.filter(f => f.carrier !== carrier));
    } else {
      setFilterCarriers([...filterCarriers, { carrier }]);
    }
  };

  const updateCarrierClasscode = (carrier: string, classcode: string) => {
    setFilterCarriers(
      filterCarriers.map(f => (f.carrier === carrier ? { ...f, classcode: classcode || undefined } : f)),
    );
    setEditingCarrier(null);
    setTempClasscode("");
  };

  const toggleState = (state: StateFilter) => {
    if (filterStates.some(f => f.id === state.id)) {
      setFilterStates(filterStates.filter(f => f.id !== state.id));
    } else {
      setFilterStates([...filterStates, state]);
    }
  };

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".carrier-dropdown")) {
        setIsCarrierDropdownOpen(false);
      }
      if (!target.closest(".state-dropdown")) {
        setIsStateDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className='flex flex-col gap-4 mb-2 bg-white p-4 rounded-lg border-b sticky top-0 '>
      <div className='flex items-center gap-4'>
        <div className='flex-1'>
          <input
            type='text'
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder='Search questions...'
            className='w-full px-3 py-2 border border-secondary-lighter rounded-lg focus:outline-none focus:border-primary'
          />
        </div>
        <div className='relative carrier-dropdown'>
          <button
            onClick={() => setIsCarrierDropdownOpen(!isCarrierDropdownOpen)}
            className='px-4 py-2 border border-secondary-lighter rounded-lg flex items-center gap-2 hover:border-primary transition-all'
          >
            <span className='text-secondary-text'>
              {filterCarriers.length
                ? `${filterCarriers.length} carrier${filterCarriers.length > 1 ? "s" : ""} selected`
                : "Select carriers"}
            </span>
            <span className='text-secondary-text'>▼</span>
          </button>

          {isCarrierDropdownOpen && (
            <div className='absolute top-full right-0 mt-2 w-64 bg-white rounded-lg shadow-lg border border-secondary-lighter z-50'>
              <div className='p-2 border-b border-secondary-lighter'>
                <input
                  type='text'
                  value={carrierSearchTerm}
                  onChange={e => setCarrierSearchTerm(e.target.value)}
                  placeholder='Search carriers...'
                  className='w-full px-2 py-1 border border-secondary-lighter rounded-lg focus:outline-none focus:border-primary text-sm'
                />
              </div>
              <div className='max-h-64 overflow-y-auto'>
                {filteredCarriers.map(carrier => (
                  <div
                    key={carrier}
                    className='flex items-center px-3 py-2 hover:bg-light cursor-pointer'
                    onClick={() => toggleCarrier(carrier)}
                  >
                    <input
                      type='checkbox'
                      checked={filterCarriers.some(f => f.carrier === carrier)}
                      onChange={() => {}}
                      className='mr-2'
                    />
                    <span className='text-sm text-secondary-text'>{carrier}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className='relative state-dropdown'>
          <button
            onClick={() => setIsStateDropdownOpen(!isStateDropdownOpen)}
            className='px-4 py-2 border border-secondary-lighter rounded-lg flex items-center gap-2 hover:border-primary transition-all'
          >
            <span className='text-secondary-text'>
              {filterStates.length
                ? `${filterStates.length} state${filterStates.length > 1 ? "s" : ""} selected`
                : "Select states"}
            </span>
            <span className='text-secondary-text'>▼</span>
          </button>

          {isStateDropdownOpen && (
            <div className='absolute top-full right-0 mt-2 w-64 bg-white rounded-lg shadow-lg border border-secondary-lighter z-50'>
              <div className='p-2 border-b border-secondary-lighter'>
                <input
                  type='text'
                  value={stateSearchTerm}
                  onChange={e => setStateSearchTerm(e.target.value)}
                  placeholder='Search states...'
                  className='w-full px-2 py-1 border border-secondary-lighter rounded-lg focus:outline-none focus:border-primary text-sm'
                />
              </div>
              <div className='max-h-64 overflow-y-auto'>
                {filteredStates.map(state => (
                  <div
                    key={state.id}
                    className='flex items-center px-3 py-2 hover:bg-light cursor-pointer'
                    onClick={() => toggleState(state)}
                  >
                    <input
                      type='checkbox'
                      checked={filterStates.some(f => f.id === state.id)}
                      onChange={() => {}}
                      className='mr-2'
                    />
                    <span className='text-sm text-secondary-text'>
                      {state.shortName} - {state.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {(filterCarriers.length > 0 || filterStates.length > 0) && (
        <div className='flex flex-wrap gap-2'>
          {filterCarriers.map(({ carrier, classcode }) => (
            <div key={carrier} className='px-2 py-1 bg-primary text-white text-sm rounded-full flex items-center gap-1'>
              <span>{carrier}</span>
              {editingCarrier === carrier ? (
                <div className='flex items-center gap-1 ml-1'>
                  <input
                    type='text'
                    value={tempClasscode}
                    onChange={e => setTempClasscode(e.target.value)}
                    className='w-20 px-1 py-0.5 text-xs text-dark-blue rounded'
                    placeholder='Classcode'
                    onClick={e => e.stopPropagation()}
                  />
                  <button
                    onClick={e => {
                      e.stopPropagation();
                      updateCarrierClasscode(carrier, tempClasscode);
                    }}
                    className='text-white hover:text-secondary-lighter'
                  >
                    ✓
                  </button>
                </div>
              ) : (
                <>
                  {classcode && (
                    <span className='mx-1 px-1.5 py-0.5 bg-white bg-opacity-20 rounded text-xs'>{classcode}</span>
                  )}
                  <button
                    onClick={e => {
                      e.stopPropagation();
                      setEditingCarrier(carrier);
                      setTempClasscode(classcode || "");
                    }}
                    className='hover:text-secondary-lighter ml-1'
                  >
                    {classcode ? "✎" : "+"}
                  </button>
                  <button onClick={() => toggleCarrier(carrier)} className='hover:text-secondary-lighter ml-1'>
                    ×
                  </button>
                </>
              )}
            </div>
          ))}

          {filterStates.map(state => (
            <div
              key={state.id}
              className='px-2 py-1 bg-secondary text-white text-sm rounded-full flex items-center gap-1'
            >
              <span>{state.shortName}</span>
              <button onClick={() => toggleState(state)} className='hover:text-secondary-lighter ml-1'>
                ×
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
