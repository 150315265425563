import { useState } from "react";
import { useBuilderNGN } from "./BuilderNGN";

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { RemoveIcon } from "../util/ItemActions";
import QuestionTableHeader from "./QuestionTableHeader";
import { fuzzyFilter } from "../util/TableUtils";
import QuestionTablePagination from "./QuestionTablePagination";
import { QuestionData, useTableData } from "../util/useTableData";

const QuestionnaireQuestionTable = () => {
  const { removeQuestion, activateGroupPart } = useBuilderNGN();

  const { columns, data, tableInput } = useTableData();

  const [questionData, setQuestionData] = useState<QuestionData[]>(data);
  const [columnsData, setColumnsData] = useState(columns);
  const [showMenuPopup, setShowMenuPopup] = useState<string>("");
  const dynamicColumns = ["answerDisplay", "maxAnswers", "minAnswers", "addMoreIcon", "addMoreText"];

  const defaultColumn = {
    size: 140,
    minSize: 110,
    maxSize: 2000,
  };

  const table = useReactTable<QuestionData>({
    data: questionData,
    columns: columnsData,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    globalFilterFn: fuzzyFilter,
    enableRowSelection: true,
    columnResizeMode: "onChange",
    getPaginationRowModel: getPaginationRowModel(),
    meta: {
      updateData: (rowIdx: number, columnId: string, value: string | string[]) => {
        setQuestionData(prev => prev.map((row, index) => (index === rowIdx ? { ...row, [columnId]: value } : row)));
      },
    },

    initialState: {
      pagination: {
        pageSize: 50,
      },
    },
  });

  const togglePopup = (columnId: string) => {
    setShowMenuPopup(prev => (prev === columnId ? "" : columnId));
  };

  const handleDeleteSelected = () => {
    const selectedRows = table.getSelectedRowModel().rows.map((row: any) => {
      return { id: row.original.id, groupId: row.original.groupId };
    });
    selectedRows.forEach((row: { id: string; groupId: string }) => {
      removeQuestion(row.groupId, row.id);
    });

    setQuestionData((prev: any) => {
      const selectedIds = selectedRows.map(row => row.id);
      const filteredData = prev.filter((data: QuestionData) => !selectedIds.includes(data.id));
      return filteredData;
    });

    table.resetRowSelection();
  };

  return (
    <div className='flex flex-col gap-4 bg-white ml-1 rounded w-fit p-4 overflow-x-scroll'>
      <div className='sticky top-0 left-0 flex flex-col gap-4'>
        <div className='flex gap-4 items-center align-center'>
          <input
            className='sticky w-1/2 px-4 py-2 border border-gray-200 rounded-sm'
            onChange={e => table.setGlobalFilter(e.target.value)}
            placeholder='Search...'
          ></input>
          {table.getSelectedRowModel().rows.length > 0 && (
            <RemoveIcon
              onClick={handleDeleteSelected}
              className='h-10 w-10 py-[10px] rounded text-sm bg-red-400 text-white cursor-pointer'
            />
          )}
        </div>
        <div>
          <input value={tableInput} onChange={() => {}} className='w-1/2 px-4 py-2 border border-gray-200 rounded-sm' />
        </div>
      </div>
      <div className='flex gap-4 place-items-start'>
        <table className='table-fixed border-collapse w-full '>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} className=' text-gray-400'>
                {headerGroup.headers.map(header => (
                  <QuestionTableHeader
                    key={header.id}
                    setShowMenuPopup={setShowMenuPopup}
                    showMenuPopup={showMenuPopup}
                    onClick={togglePopup}
                    header={header}
                  />
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.original.id} style={{ background: row.getIsSelected() ? "#F3F4F6" : "" }}>
                {row.getVisibleCells().map(cell => (
                  <td
                    key={cell.id}
                    className={`h-10 border border-gray-200 ${
                      dynamicColumns.includes(cell.column.id) && !row.original.multipleAnswers ? "bg-gray-100" : ""
                    } `}
                    style={{ width: "150px" }}
                    onClick={() => {
                      activateGroupPart({
                        group: row.original.groupId,
                        groupPart: "g-questions",
                        questionId: row.original.id,
                      });
                    }}
                  >
                    <div className='relative'>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='flex items-center gap-1 w-full border text-gray-400 py-2 pl-2 cursor-pointer hover:bg-gray-100'>
        <span>New</span>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          className='w-5 h-5 text-gray-400 cursor-pointer'
        >
          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 5v14m7-7H5' />
        </svg>
      </div>
      <QuestionTablePagination table={table} />
    </div>
  );
};

export default QuestionnaireQuestionTable;
