import React, { useCallback, useMemo, useState } from "react";
import { useBuilderNGN } from "./BuilderNGN";
import { QuestionnaireQuestionEntity } from "@Savus-Inc/questionnaire-types";
import { unpack } from "../../utils/unpack";
import { NoData } from "../util/Empty";
import { cb } from "../../utils/cd";

const SidebarSearch = ({ groupId, questionId }: { groupId?: string; questionId?: string }) => {
  const { item, activateGroupPart } = useBuilderNGN();
  const [query, setQuery] = useState("");

  const availableQuestions = useMemo(() => {
    const trimmedQuery = query.trim().toLowerCase();
    const mappedQuestions: QuestionnaireQuestionEntity[] = item.groups
      .map(g => g.questions.map(q => ({ ...q, groupId: g.id })))
      .flat();

    if (questionId) {
      return mappedQuestions.filter(q => {
        return (
          (q.parentId === questionId && q.question?.externalId?.toLowerCase().includes(trimmedQuery)) ||
          q.question?.label.toLowerCase().includes(trimmedQuery)
        );
      });
    }

    if (groupId) {
      return item.groups
        .filter(g => g.id === groupId)
        .map(g => g.questions.map(q => ({ ...q, groupId: g.id })))
        .flat()
        .filter(q => {
          return (
            q.question?.externalId?.toLowerCase().includes(trimmedQuery) ||
            q.question?.label.toLowerCase().includes(trimmedQuery)
          );
        });
    }

    return mappedQuestions.filter(q => {
      return (
        q.question?.externalId?.toLowerCase().includes(trimmedQuery) ||
        q.question?.label.toLowerCase().includes(trimmedQuery)
      );
    });
  }, [item, groupId, questionId, query]);

  const groupMap: Record<string, string> = useMemo(
    () => item.groups.reduce((p, c) => ({ ...p, [c.id]: c.title }), {}),
    [item.groups],
  );

  const select = useCallback(
    (question: QuestionnaireQuestionEntity) => {
      activateGroupPart({
        questionId: question?.questionId,
        groupPart: "g-questions",
        group: question?.groupId,
      });
      setQuery("");
    },
    [activateGroupPart],
  );

  return (
    <div className='flex flex-col w-full'>
      <input
        id='spotlight-search'
        className='w-full pl-2 py-1 border border-sky-500 rounded-md focus:outline-none'
        value={query}
        type='text'
        placeholder='Search questions...'
        onChange={unpack(setQuery)}
      ></input>
      {query && (
        <div className={"max-h-[50vh] overflow-y-auto"}>
          <div className={"flex flex-col gap-3"}>
            {availableQuestions.map(q => {
              return (
                <div
                  key={q.id}
                  className={"flex gap-4 items-center border rounded px-2 py-1 cursor-pointer truncate"}
                  onClick={cb(select, q)}
                >
                  <div className='text-gray-500'>
                    P{q.partOrder}: {groupMap[q.groupId] || "Unknown Group"}
                  </div>
                  <div className={"font-semibold"}>{q.question?.externalId || ""}</div>
                  <div className='truncate'>{q.question?.label || ""}</div>
                </div>
              );
            })}
          </div>
          {query.length > 0 && availableQuestions.length === 0 && (
            <NoData message={"No questions found that match provided filter"} />
          )}
        </div>
      )}
    </div>
  );
};

export default SidebarSearch;
