import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAsyncFn from "../../utils/useAsyncFn";
import { getCustomerRecordMarkerQuotesData } from "../../http/app";
import { TestQuoteDetails } from "./TestQuoteDetails";
import { CustomerRecord } from "../../types/customer-record";
import { useAppState } from "../../state/State";
import { useDebounce } from "@Savus-Inc/runtime-ngn/dist/src/utils/use-debounce";
import { QuoteRunStats } from './QuoteRunStats';
import { QuoteCard } from './QuoteCard';

export const QuoteRunDetails = () => {
  const { state: { products, carriers } } = useAppState();
  const location = useLocation();
  const navigate = useNavigate();

  const selectedRun = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("marker");
  }, [location]);

  const { exec, loading, data } = useAsyncFn(getCustomerRecordMarkerQuotesData);

  const setSelectedQuote = useCallback((quote: CustomerRecord | null) => {
    const searchParams = new URLSearchParams(location.search);
    if (quote) {
      searchParams.set("quoteId", quote.id);
    } else {
      searchParams.delete("quoteId");
    }
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [location, navigate]);


  const selectedQuote = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("quoteId") ? data?.ok ? data?.data.find(v => v.id === searchParams.get("quoteId")) : null : null;
  }, [location.search, data]);

  useEffect(() => {
    selectedRun && exec(selectedRun);
  }, [selectedRun]);

  const fetch = useMemo(() => {
    if (data?.ok && data.data.some(v => v.questionnaireState?.some(cs => cs.submissions.some(s => s.status === "Started")))) {
      return Date.now();
    }
  }, [data]);

  const shouldReFetch = useDebounce(fetch, 5000);

  useEffect(() => {
    if (shouldReFetch && selectedRun) {
      exec(selectedRun);
    }
  }, [shouldReFetch, selectedRun]);

  const getSubmissionStatusSummary = (quote: CustomerRecord) => {
    const submissions = quote.questionnaireState?.[1]?.submissions || [];
    const total = submissions.length;
    const completed = submissions.filter(s => s.status === "Completed").length;
    const errored = submissions.filter(s => s.status === "Errored").length;
    const pending = total - completed - errored;

    return { total, completed, errored, pending };
  };

  const testRunStats = useMemo(() => {
    if (!data?.ok) return null;

    const totalQuotes = data.data.length;
    const totalSubmissions = data.data.reduce((acc, quote) =>
      acc + (quote.questionnaireState?.[1]?.submissions.length || 0), 0);

    const submissionStats = data.data.reduce((acc, quote) => {
      const submissions = quote.questionnaireState?.[1]?.submissions || [];
      submissions.forEach(sub => {
        acc[sub.status] = (acc[sub.status] || 0) + 1;
      });
      return acc;
    }, {} as Record<string, number>);

    const carrierStats = data.data.reduce((acc, quote) => {
      const submissions = quote.questionnaireState?.find(qs => qs.submissions.length)?.submissions || [];
      submissions.forEach(sub => {
        const carrier = carriers.find(c => c.id === sub.carrierId);
        if (carrier) {
          acc[carrier.name] = acc[carrier.name] || { total: 0, completed: 0, errored: 0 };
          acc[carrier.name].total++;
          if (sub.status === "Completed") acc[carrier.name].completed++;
          if (sub.status === "Errored") acc[carrier.name].errored++;
        }
      });
      return acc;
    }, {} as Record<string, { total: number; completed: number; errored: number }>);

    return {
      totalQuotes,
      totalSubmissions,
      submissionStats,
      carrierStats,
      successRate: totalSubmissions ?
        ((submissionStats["Completed"] || 0) / totalSubmissions * 100).toFixed(1) :
        "0",
    };
  }, [data, carriers]);


  if (!data?.ok || !testRunStats) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-4rem)] bg-gray-50/50">
        <div className="text-center text-secondary-text">
          <svg className="w-16 h-16 mx-auto mb-4 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M12 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <div className="text-lg font-medium mb-2">No Data Available</div>
          <div className="text-sm">There are no test results to display at this time.</div>
        </div>
      </div>
    );
  }

  const handleQuoteClick = (quote: CustomerRecord) => {
    setSelectedQuote(quote);
  };

  return (
    <div className="min-h-[calc(100vh-4rem)] bg-light/50 pb-8">
      <div className="bg-white shadow-sm mb-6 sticky top-0 z-10">
        <div className="max-w-7xl mx-auto">
          <div className="p-4 sm:px-6 lg:px-8">
            {data?.ok && <QuoteRunStats data={data.data} />}
          </div>
        </div>
      </div>

      <div className="p-6 max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {data?.data.map((quote: CustomerRecord) => (
            <QuoteCard
              key={quote.id}
              quote={quote}
              onClick={setSelectedQuote}
            />
          ))}
        </div>
      </div>

      {selectedQuote && (
        <TestQuoteDetails 
          customerRecord={selectedQuote} 
          handleClose={() => setSelectedQuote(null)} 
        />
      )}
    </div>
  );
}; 