import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import useAsyncFn from "../../utils/useAsyncFn";
import { getCustomerRecordMarkers } from "../../http/app";
import { useLocation, useNavigate } from "react-router-dom";

export const QuoteRunsList = ({ setMarkers }: { setMarkers: (markers: string[]) => void }) => {
  const { exec, data, loading } = useAsyncFn(getCustomerRecordMarkers);
  const location = useLocation();
  const navigate = useNavigate();

  const curentMarker = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("marker");
  }, [location.search]);
  useEffect(() => {
    exec();
  }, [location.search]);

  const onSelectQuote = (marker: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("customerRecordId");
    searchParams.delete("quoteStateId");
    searchParams.delete("applicationId");
    searchParams.set("marker", marker);
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    }, { replace: true });
  };

  const [searchTerm, setSearchTerm] = useState("");

  const filteredRuns = useMemo(() => {
    if (!data?.ok) return [];
    return data.data.filter(run => run.marker?.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [data, searchTerm]);

  useEffect(() => {
    if (data?.ok) {
      setMarkers(data.data.map(run => run.marker as string));
    }
  }, [data]);

  if (loading) {
    return (
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-center h-full animate-pulse">
          <div className="flex flex-col items-center">
            <div className="w-8 h-8 rounded-full bg-gray-300 mb-2"></div>
            <div className="w-16 h-4 bg-gray-300 rounded mb-2"></div>
            <div className="w-12 h-4 bg-gray-300 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  if (!data?.ok) {
    return (
      <div className="flex flex-col h-full">
        <div className="flex flex-col items-center justify-center h-full text-center text-gray-500">
          <div className="p-4">
            <svg
              className="w-16 h-16 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M20 12H4m16 0a8 8 0 11-16 0 8 8 0 0116 0zm0 0H4"
              />
            </svg>
          </div>
          <h3 className="mt-2 text-lg font-medium text-gray-900">No Data Available</h3>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full max-h-[calc(100vh-10rem) overflow-y-auto]">
      <div className="p-4">
        <input type="text" placeholder="Search" className="w-full p-2 border border-divider rounded-md"
               value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
      </div>
      <div className="flex-1 overflow-y-auto">
        {filteredRuns.sort((a, b) => new Date(b.updatedAt as string) > new Date(a.updatedAt as string) ? 1 : -1).map(run => (
          <div key={run.marker as string} className="border-b border-divider last:border-b-0">
            {/* Run Header */}
            <button
              onClick={() => onSelectQuote(run.marker as string)}
              className={classNames("w-full px-4 py-3 text-left transition-colors hover:bg-light", {
                "bg-light": curentMarker === run.marker,
              })}
            >
              <div className="flex flex-col">
                <span className="text-secondary font-bold">{run.marker}</span>
                <span
                  className={classNames("text-in flex justify-end", {})}>{new Date(run.updatedAt as string).toLocaleString()}</span>
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
