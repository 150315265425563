import { useMemo } from 'react';
import { CustomerRecord } from '../../types/customer-record';

interface QuoteCardProps {
  quote: CustomerRecord;
  onClick: (quote: CustomerRecord) => void;
}

export const QuoteCard = ({ quote, onClick }: QuoteCardProps) => {
  const statusSummary = useMemo(() => {
    const submissions = (quote.questionnaireState?.find(qs => qs.submissions.length))?.submissions || [];
    const total = submissions.length;
    const completed = submissions.filter(s => s.status === "Completed").length;
    const errored = submissions.filter(s => s.status === "Errored").length;
    const pending = total - completed - errored;

    return { total, completed, errored, pending };
  }, [quote.questionnaireState]);

  return (
    <div
      onClick={() => onClick(quote)}
      className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 cursor-pointer overflow-hidden"
    >
      {/* Status Bar */}
      <div className="h-1.5 bg-light w-full flex">
        {statusSummary.total > 0 && (
          <>
            <div 
              className="h-full bg-success transition-all" 
              style={{ width: `${(statusSummary.completed / statusSummary.total) * 100}%` }}
            />
            <div 
              className="h-full bg-danger transition-all" 
              style={{ width: `${(statusSummary.errored / statusSummary.total) * 100}%` }}
            />
            <div 
              className="h-full bg-primary transition-all" 
              style={{ width: `${(statusSummary.pending / statusSummary.total) * 100}%` }}
            />
          </>
        )}
      </div>

      <div className="p-4">
        {/* Business Info */}
        <div className="mb-4">
          <h3 className="text-lg font-medium text-dark-blue">{quote.businessName}</h3>
          <p className="text-sm text-secondary-text">{quote.businessType.title}</p>
        </div>

        {/* Address */}
        <div className="text-sm text-secondary-text flex items-start">
          <svg className="w-4 h-4 mr-2 mt-0.5 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
          <div>
            {quote.address.address},<br />
            {quote.address.city}, {quote.address.state.shortName} {quote.address.zip}
          </div>
        </div>

        {/* Progress Indicators */}
        <div className="mt-4 pt-4 border-t border-light">
          <div className="flex justify-between text-xs text-secondary-text">
            <div>
              <span className="text-success font-medium">{statusSummary.completed}</span> completed
            </div>
            <div>
              <span className="text-danger font-medium">{statusSummary.errored}</span> failed
            </div>
            <div>
              <span className="text-primary font-medium">{statusSummary.pending}</span> pending
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}; 