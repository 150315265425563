import React, { useMemo, useState } from "react";
import { useBuilderNGN } from "../Questionaire/BuilderNGN";
import { useAppState } from "../../state/State";
import {
  AddMoreIconName,
  DisplayDirection,
  InputType,
  QuestionnaireGroupSection,
  QuestionnaireQuestionEntity,
  QuestionnaireQuestionGroupEntity,
  QuestionValueAutocompleteIntegration,
  QuestionValueFormatting,
  QuestionValueOption,
} from "@Savus-Inc/questionnaire-types";
import { createColumnHelper, Row } from "@tanstack/react-table";
import QuestionTableSelectCell from "../Questionaire/QuestionTableSelectCell";
import QuestionTableCell from "../Questionaire/QuestionTableCell";
import { Val } from "@Savus-Inc/dsl/dist/types";

export interface QuestionData {
  addMoreIcon?: AddMoreIconName;
  addMoreText?: string;
  answerDisplay?: DisplayDirection;
  autocompleteIntegration: QuestionValueAutocompleteIntegration | null;
  externalId?: string;
  format: QuestionValueFormatting | null;
  groupId: string;
  id: string;
  inputType?: InputType | null;
  kind: Val;
  label: string;
  maxAnswers?: number;
  minAnswers?: number;
  multipleAnswers?: boolean;
  partOrder: number;
  placeholder: string | null;
  sectionDisplay: string;
  sectionId: string;
  sectionTitle: string;
  showForCarriers: string[];
  subQuestions?: DisplayDirection;
  subText: string | null;
  tooltip: string | null;
  values: QuestionValueOption[];
  parentId: string;
  containerQuestion: string;
  groupTitle: string;
  groupPartOrder: number | null;
}

export const useTableData = () => {
  const [tableInput, setTableInput] = useState<string>("");
  const { questions, item } = useBuilderNGN();
  const {
    state: { carriers },
  } = useAppState();

  const data: QuestionData[] = useMemo(() => {
    return questions
      .sort((a: any, b: any) => a.question?.externalId?.slice(2) - b.question?.externalId?.slice(2))
      .map((q: QuestionnaireQuestionEntity) => {
        if (!q.question) return {} as QuestionData;
        const { ...rest } = q.question;
        const section = item.groups
          .find((g: QuestionnaireQuestionGroupEntity) =>
            g.sections?.some((s: QuestionnaireGroupSection) => s.id === q.sectionId),
          )
          ?.sections?.find((s: QuestionnaireGroupSection) => s.id === q.sectionId);
        const groupId = q.groupId;
        const partOrder = q.partOrder;
        const containerQuestion = questions.find(item => item.id === q.parentId);
        const containerQuestionExternalId = containerQuestion?.question?.externalId;
        const group = item.groups.find((g: QuestionnaireQuestionGroupEntity) => g.id === q.groupId);

        return {
          groupId,
          partOrder,
          containerQuestion: containerQuestionExternalId || "",
          sectionId: q.sectionId || "",
          parentId: q.parentId || "",
          sectionTitle: section?.title || "",
          sectionDisplay: section?.display || "",
          showForCarriers: q.showForCarriers,
          groupTitle: group?.title || "",
          groupPartOrder: group?.partOrder ?? null,

          ...rest,
        };
      });
  }, [questions, item.groups]);

  const getavailableInputTypes = (row: Row<QuestionData>) => {
    if (!row.original.id) return [];
    const availableInputTypes = Object.values(InputType).filter(v => {
      if (row.original.autocompleteIntegration === QuestionValueAutocompleteIntegration.OwnValues) {
        return ![InputType.Phone, InputType.RichText, InputType.Header, InputType.Textarea].includes(v);
      }

      switch (row.original.kind) {
        case Val.String:
          return [
            InputType.Phone,
            InputType.RichText,
            InputType.Header,
            InputType.Textarea,
            InputType.Statement,
          ].includes(v);
        case Val.Number:
          return false;
        case Val.Bool:
          return v === InputType.YesNo;
        case Val.Date:
          return false;
        default:
          return false;
      }
    });

    return [{ label: "Default", value: null }, ...availableInputTypes.map(item => ({ label: item, value: item }))];
  };

  const getAvailableParents = (row: Row<QuestionData>) => {
    const availableParents =
      item.groups
        .find(g => g.id === row.original.groupId)
        ?.questions.filter(v => !v.parentId && v.id !== row.original.id) || [];

    return [
      {
        label: "None",
        value: null,
      },
      ...(availableParents.map(item => ({
        label: item.question?.externalId || "",
        value: item.question?.id || "",
      })) || []),
    ];
  };

  const columnHelper = createColumnHelper<QuestionData>();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        header: ({ table }) => (
          <div className='flex justify-center items-center gap-2'>
            Select
            <input
              className='cursor-pointer'
              checked={table.getIsAllRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
              type='checkbox'
            ></input>
          </div>
        ),
        cell: ({ row }) => (
          <div className='flex justify-center items-center'>
            <input
              className='cursor-pointer'
              onChange={row.getToggleSelectedHandler()}
              checked={row.getIsSelected()}
              type='checkbox'
            ></input>
          </div>
        ),
      }),
      columnHelper.accessor("externalId", {
        header: "External ID",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
          />
        ),
      }),
      columnHelper.accessor("groupTitle", {
        header: "Page Title",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableSelectCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
            suggestions={item.groups.map(v => ({ label: v.title, value: v.id }))}
          />
        ),
      }),
      columnHelper.accessor("groupPartOrder", {
        header: "Page Part Order",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
          />
        ),
      }),
      columnHelper.accessor("label", {
        header: "Label",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
          />
        ),
      }),
      columnHelper.accessor("partOrder", {
        header: "Part Order",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
          />
        ),
      }),
      columnHelper.accessor("showForCarriers", {
        header: "Carriers",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableSelectCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
            suggestions={carriers.map(v => ({ label: v.name, value: v.name }))}
          />
        ),
      }),
      columnHelper.accessor("sectionTitle", {
        header: "Section Title",
        cell: ({ getValue, row, column, table }) => (
          <div>
            <QuestionTableSelectCell
              setTableInput={setTableInput}
              row={row}
              column={column}
              table={table}
              getValue={getValue}
              suggestions={[
                { label: "None", value: null },
                ...(item.groups
                  .find(g => g.id === row.original.groupId)
                  ?.sections?.map(s => ({ label: s.title || "", value: s.id })) ?? []),
              ]}
            />
          </div>
        ),
      }),
      columnHelper.accessor("sectionDisplay", {
        header: "Section Display",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableSelectCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
            suggestions={Object.values(DisplayDirection).map(item => ({ label: item, value: item }))}
          />
        ),
      }),
      columnHelper.accessor("containerQuestion", {
        header: "Container Question",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableSelectCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
            suggestions={getAvailableParents(row)}
          />
        ),
      }),
      columnHelper.accessor("subText", {
        header: "Sub Text",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
          />
        ),
      }),
      columnHelper.accessor("placeholder", {
        header: "Placeholder",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
          />
        ),
      }),
      columnHelper.accessor("tooltip", {
        header: "Tooltip",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
          />
        ),
      }),
      columnHelper.accessor("kind", {
        header: "Answer Kind",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableSelectCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
            suggestions={Object.values(Val).map(item => ({ label: item, value: item }))}
          />
        ),
      }),
      columnHelper.accessor("inputType", {
        header: "Input Type",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableSelectCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
            suggestions={getavailableInputTypes(row)}
          />
        ),
      }),
      columnHelper.accessor("format", {
        header: "Answer Format",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableSelectCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
            suggestions={[
              { label: "None", value: null },
              ...Object.values(QuestionValueFormatting).map(item => ({ label: item, value: item })),
            ]}
          />
        ),
      }),
      columnHelper.accessor("multipleAnswers", {
        header: "Multiple Answers",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableSelectCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
            suggestions={[
              { label: "true", value: "true" },
              { label: "false", value: "false" },
            ]}
          />
        ),
      }),
      columnHelper.accessor("autocompleteIntegration", {
        header: "Answer Options",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableSelectCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
            suggestions={[
              { label: "Open Ended", value: null },
              ...Object.values(QuestionValueAutocompleteIntegration).map(item => ({
                label: item,
                value: item,
              })),
            ]}
          />
        ),
      }),
      columnHelper.accessor("answerDisplay", {
        header: "Answer Display",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableSelectCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
            suggestions={Object.values(DisplayDirection).map(item => ({ label: item, value: item }))}
          />
        ),
      }),
      columnHelper.accessor("maxAnswers", {
        header: "Max Answers",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
          />
        ),
      }),
      columnHelper.accessor("minAnswers", {
        header: "Min Answers",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
          />
        ),
      }),
      columnHelper.accessor("addMoreIcon", {
        header: "Add More Icon",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableSelectCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
            suggestions={Object.values(AddMoreIconName).map(item => ({ label: item, value: item }))}
          />
        ),
      }),
      columnHelper.accessor("addMoreText", {
        header: "Add More Text",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
          />
        ),
      }),
      columnHelper.accessor("subQuestions", {
        header: "Sub Questions",
        cell: ({ getValue, row, column, table }) => (
          <QuestionTableSelectCell
            setTableInput={setTableInput}
            row={row}
            column={column}
            table={table}
            getValue={getValue}
            suggestions={Object.values(DisplayDirection).map(item => ({ label: item, value: item }))}
          />
        ),
      }),
    ],
    [columnHelper, carriers, item.groups],
  );

  return { columns, data, tableInput };
};
