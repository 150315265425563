export const DocumentationLoading = ({ message }: { message: string }) => (
  <div className='flex flex-col items-center justify-center py-20 px-4'>
    <div className='relative'>
      {/* Building blocks animation */}
      <div className='flex items-end justify-center space-x-2'>
        {[...Array(5)].map((_, i) => (
          <div key={i} className='flex flex-col items-center space-y-2' style={{ height: "120px" }}>
            <div
              className='w-8 h-8 bg-primary rounded-lg opacity-0'
              style={{
                animation: `buildingBlock 2s ease-in-out ${i * 0.2}s infinite`,
                transformOrigin: "bottom",
              }}
            />
            <div
              className='w-8 h-8 bg-secondary rounded-lg opacity-0'
              style={{
                animation: `buildingBlock 2s ease-in-out ${i * 0.2 + 0.1}s infinite`,
                transformOrigin: "bottom",
              }}
            />
            <div
              className='w-8 h-8 bg-secondary-light rounded-lg opacity-0'
              style={{
                animation: `buildingBlock 2s ease-in-out ${i * 0.2 + 0.2}s infinite`,
                transformOrigin: "bottom",
              }}
            />
          </div>
        ))}
      </div>

      {/* Progress text */}
      <div className='mt-12 text-center'>
        <p className='text-xl font-semibold text-secondary-text'>{message}</p>
        <p className='text-gray-500 mt-2'>Assembling the pieces for you...</p>
      </div>
    </div>
  </div>
);
