import { useBuilderNGN } from "./BuilderNGN";
import exportToCSV from "../Rules/utils/exportToCSV";
import Papa from "papaparse";

const DownloadCSV = () => {
  const { item } = useBuilderNGN();

  const downloadCSV = () => {
    const data = exportToCSV(item);
    const csv = Papa.unparse(data);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `questionnaire-csv-export-${new Date().getDate()}.csv`;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  return (
    <button
      className='py-1 px-3 rounded-xl border shadow border-sky-500 text-sky-500 hover:bg-sky-500 hover:text-white transition ease-in-out'
      onClick={downloadCSV}
    >
      Download CSV
    </button>
  );
};

export const QuestionnaireExport = () => {
  return (
    <div className={"flex-col gap-2 mr-2 relative"}>
      <DownloadCSV />
    </div>
  );
};
