import { useAppState } from "../../state/State";
import { UnderDevelopmentMessage } from "./UnderDevelopmentMessage";
import { useDocumentationContext } from "./DocumentationContext";
import { DocumentationFilters } from "./DocumentationFilters";
import { DocumentationQuestionDetails } from "./DocumentationQuestionDetails";

export const DocumentationDetails = () => {
  const { selectedGroup, selectedQuestion, setSelectedGroup, filteredQuestions, setSelectedQuestion } =
    useDocumentationContext();

  const {
    state: { currentQuestionnaire },
  } = useAppState();

  if (!currentQuestionnaire) return <UnderDevelopmentMessage />;

  return (
    <div className='  flex flex-col p-6 '>
      <div className='flex gap-6  '>
        {/* Left sidebar with groups */}
        <div className='w-96 bg-white rounded-lg shadow-md p-4 overflow-y-auto'>
          <h2 className='text-xl font-semibold text-dark-blue mb-4'>Pages ({currentQuestionnaire.groups.length})</h2>
          <div className='space-y-2'>
            {currentQuestionnaire.groups.map(group => (
              <div
                key={group.id}
                onClick={() => setSelectedGroup(group)}
                className={`p-3 rounded-lg cursor-pointer transition-all
                ${selectedGroup?.id === group.id ? "bg-primary text-white" : "bg-light hover:bg-secondary-lighter"}`}
              >
                <div className='flex items-center justify-between mb-2 text-sm'>
                  <span className='font-light'>Page {group.partOrder}</span>
                  <span className='text-smrounded-full bg-opacity-20 bg-white'>{group.questions.length} Questions</span>
                </div>
                <div className='font-bold'>{group.title}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Right content area */}
        <div className='flex-1 bg-light rounded-lg h-full overflow-y-auto '>
          {!selectedQuestion && <DocumentationFilters />}

          {selectedQuestion ? (
            <DocumentationQuestionDetails question={selectedQuestion} />
          ) : selectedGroup ? (
            <div className={"bg-white p-4 max-h-[calc(100vh-300px)] overflow-y-auto"}>
              <h2 className='text-2xl font-semibold text-dark-blue mb-4'>{selectedGroup.title}</h2>
              {filteredQuestions.map(q => (
                <div
                  key={q.id}
                  onClick={() => setSelectedQuestion(q)}
                  className='mb-2 p-3 bg-light rounded-lg border border-secondary-lighter hover:border-primary transition-all cursor-pointer'
                >
                  <div className='flex items-center gap-4'>
                    <span className='text-sm text-secondary font-medium min-w-[100px]'>{q.question?.externalId}</span>
                    <span className='text-sm text-dark-blue flex-1'>{q.question?.label}</span>
                    <div className='flex gap-1 flex-shrink-0'>
                      {q.showForCarriers.map(carrier => (
                        <span
                          key={carrier}
                          className='text-xs px-2 py-0.5 bg-secondary-lighter text-secondary-text rounded-full'
                        >
                          {carrier}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className='h-80 flex items-center justify-center text-secondary-text'>
              <div className='text-center'>
                <div className='text-4xl mb-4'>👈</div>
                <div>Please select a page from the sidebar to view its details</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};