import { BusinessType } from "../../util/Autocomplete";
import { QuestionContext } from "../../../types/QuestionContext";
import { BUSINESS_TYPES } from "../../../data/business-types";
import { STATES } from "../../../data/states";
import { Ref, Val } from "@Savus-Inc/dsl/dist/types";
import { interpolateValue } from "@Savus-Inc/questionnaire-ngn/dist/utils";

function filterUniqueByReduce(data: BusinessType[]) {
  const uniqueList = data.reduce(
    (acc, item) => {
      const key = `${item.title}-${item.naicsCode}-${item.naicsTitle}`;
      acc[key] = item;
      return acc;
    },
    {} as Record<string, BusinessType>,
  );
  return Object.values(uniqueList);
}

function getContextRefs() {
  const refs: Ref[] = [];

  refs.push({
    kind: Val.Number,
    label: "Customer Address - Zip Code",
    referenceId: "ZipCode",
    value: null,
  });

  const businessTypes = filterUniqueByReduce(BUSINESS_TYPES.data);
  refs.push({
    kind: Val.String,
    label: "Customer Address - State Code",
    referenceId: "State",
    value: STATES.map(v => `${v.shortName}`),
  });
  refs.push({
    kind: Val.String,
    label: "Customer Address - State Name",
    referenceId: "StateName",
    value: STATES.map(v => `${v.name}`),
  });
  refs.push({
    kind: Val.String,
    label: "Customer Address - State",
    referenceId: "StateNameWithCode",
    value: STATES.map(v => `(${v.name} (${v.shortName})`),
  });
  refs.push({
    kind: Val.String,
    label: "Customer Address - Address",
    referenceId: "Address",
    value: [],
  });
  refs.push({
    kind: Val.String,
    label: "Customer Address - City",
    referenceId: "City",
    value: [],
  });
  refs.push({
    kind: Val.String,
    label: "Customer Address - Suite",
    referenceId: "Suite",
    value: [],
  });
  refs.push({
    kind: Val.String,
    label: "Product",
    referenceId: "Product",
    value: [],
  });
  refs.push({
    kind: Val.String,
    label: "Product - Short Name",
    referenceId: "ProductShortName",
    value: [],
  });

  refs.push({
    kind: Val.String,
    label: "Customer - Commercial Name",
    referenceId: "BusinessName",
    value: [],
  });

  refs.push({
    kind: Val.String,
    label: "Customer - Phone",
    referenceId: "BusinessOwnerPhone",
    value: [],
  });

  refs.push({
    kind: Val.String,
    label: "Customer - Email",
    referenceId: "BusinessOwnerEmail",
    value: [],
  });

  refs.push({
    kind: Val.String,
    label: "Customer - First Name",
    referenceId: "BusinessOwnerFirstName",
    value: [],
  });

  refs.push({
    kind: Val.String,
    label: "Customer - Last Name",
    referenceId: "BusinessOwnerLastName",
    value: [],
  });

  refs.push({
    kind: Val.String,
    label: "X (Index)",
    referenceId: "__X",
    value: [],
  });
  refs.push({
    kind: Val.String,
    label: "Item Value",
    referenceId: "__Value",
    value: [],
  });
  refs.push({
    kind: Val.Number,
    label: "Item Value Index",
    referenceId: "__ValueIndex",
    value: [],
  });
  refs.push({
    kind: Val.String,
    label: "Customer Business Description",
    referenceId: "BusinessTypeTitle",
    value: businessTypes.map(v => `${v.title}`),
  });
  refs.push({
    kind: Val.String,
    label: "Customer Business NAICS Code",
    referenceId: "BusinessTypeNAICS",
    value: businessTypes.map(v => `${v.naicsCode}`),
  });
  refs.push({
    kind: Val.String,
    label: "Customer Business NAICS Title",
    referenceId: "BusinessTypeNAICSTitle",
    value: businessTypes.map(v => `${v.naicsTitle}`),
  });

  refs.push({
    kind: Val.String,
    label: "Class Code - Chubb",
    referenceId: "ClassCodeChubb",
    value: null,
  });

  refs.push({
    kind: Val.String,
    label: "Class Code - CNA",
    referenceId: "ClassCodeCna",
    value: null,
  });

  refs.push({
    kind: Val.String,
    label: "Class Code - Travelers",
    referenceId: "ClassCodeTravelers",
    value: null,
  });

  refs.push({
    kind: Val.String,
    label: "Class Code - Nationwide",
    referenceId: "ClassCodeNationWide",
    value: null,
  });

  refs.push({
    kind: Val.String,
    label: "Class Code - Liberty Mutual",
    referenceId: "ClassCodeLibertyMutual",
    value: null,
  });

  refs.push({
    kind: Val.String,
    label: "Class Code Description - Chubb",
    referenceId: "ClassCodeChubbDescription",
    value: null,
  });

  refs.push({
    kind: Val.String,
    label: "Class Code Description - CNA",
    referenceId: "ClassCodeCnaDescription",
    value: null,
  });

  refs.push({
    kind: Val.String,
    label: "Class Code Description - Travelers",
    referenceId: "ClassCodeTravelersDescription",
    value: null,
  });

  refs.push({
    kind: Val.String,
    label: "Class Code Description- Nationwide",
    referenceId: "ClassCodeNationWideDescription",
    value: null,
  });

  refs.push({
    kind: Val.String,
    label: "Class Code Description - Liberty Mutual",
    referenceId: "ClassCodeLibertyMutualDescription",
    value: null,
  });

  refs.push({
    kind: Val.String,
    label: "Policy Quote Id Chubb",
    referenceId: "PolicyQuoteIdChubb",
    value: null,
  });

  refs.push({
    kind: Val.String,
    label: "Agent First Name",
    referenceId: "AgentFirstName",
    value: null,
  });

  refs.push({
    kind: Val.String,
    label: "Agent Last Name",
    referenceId: "AgentLastName",
    value: null,
  });

  refs.push({
    kind: Val.String,
    label: "Agent Email Address",
    referenceId: "AgentEmail",
    value: null,
  });

  return refs.map(r => {
    r.label = interpolateValue(r.label, refs as Ref[], "referenceId");
    return r;
  });
}

export const getAllRefs = (context: QuestionContext) => {
  const { questionnaire } = context;

  const availableRefs = questionnaire.groups
    .map(g => {
      return g.questions.map(q => ({ ...q, groupOrder: q.partOrder }));
    })
    .flatMap(qs => {
      return qs.map(
        q =>
          ({
            label: `${q?.question?.externalId || ""}: ${q?.question?.label}`,
            referenceId: q.externalId as string,
            value: q?.question?.values?.length ? q?.question?.values.map(v => v.label) : null,
            kind: q?.question?.kind || Val.String,
          }) as Ref,
      );
    });

  availableRefs.push(...getContextRefs());

  return availableRefs.map(r => {
    r.label = interpolateValue(r.label, availableRefs, "referenceId");
    return r;
  });
};
