import { FC } from 'react';

const LogoWhite: FC = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="2.5 7.5 395 110"
    >
        <g>
            <g>
                <path
                    fill="#fff"
                    d="M138.9,89.8c-4-1.3-7.2-3.1-9.5-5.2l3-6.4c2.2,2,5,3.6,8.4,4.8c3.4,1.2,6.9,1.8,10.5,1.8    c4.7,0,8.3-0.8,10.6-2.4c2.3-1.6,3.5-3.8,3.5-6.4c0-2-0.6-3.6-1.9-4.8c-1.3-1.2-2.9-2.2-4.7-2.8c-1.9-0.7-4.5-1.4-8-2.2    c-4.3-1-7.8-2.1-10.4-3.1c-2.6-1-4.9-2.6-6.8-4.8c-1.9-2.2-2.8-5.1-2.8-8.7c0-3.1,0.8-5.8,2.4-8.3c1.6-2.5,4.1-4.4,7.3-5.9    c3.3-1.5,7.3-2.2,12.2-2.2c3.4,0,6.7,0.4,10,1.3c3.3,0.9,6.1,2.1,8.4,3.8l-2.7,6.5c-2.4-1.5-5-2.7-7.7-3.5c-2.7-0.8-5.4-1.2-8-1.2    c-4.6,0-8.1,0.8-10.4,2.5c-2.3,1.7-3.5,3.9-3.5,6.5c0,2,0.7,3.6,2,4.8c1.3,1.2,2.9,2.2,4.9,2.9c1.9,0.7,4.6,1.4,7.9,2.2    c4.3,1,7.8,2.1,10.4,3.1c2.6,1,4.9,2.6,6.8,4.7c1.9,2.1,2.8,5,2.8,8.6c0,3-0.8,5.7-2.5,8.2c-1.6,2.5-4.1,4.4-7.4,5.9    c-3.3,1.4-7.4,2.2-12.3,2.2C147.1,91.8,142.9,91.1,138.9,89.8z"
                />
                <path
                    fill="#fff"
                    d="M287.9,33.9l-25.1,57.3h-8.1l-25.2-57.3h8.8l20.6,47.1l20.8-47.1H287.9z"
                />
                <g>
                    <polygon
                        fill="#fff"
                        points="210.9,33.9 202.8,33.9 177.7,91.2 185.9,91.2    "
                    />
                    <polygon
                        fill="#fff"
                        points="236.1,91.2 215,43.1 210.6,53.1 227.3,91.2    "
                    />
                </g>
                <path
                    fill="#fff"
                    d="M301.9,85.3c-4.3-4.4-6.4-10.6-6.4-18.8V33.9h8.2v32.2c0,12.3,5.4,18.4,16.1,18.4c5.2,0,9.2-1.5,12-4.5    c2.8-3,4.2-7.7,4.2-13.9V33.9h7.9v32.6c0,8.2-2.1,14.5-6.4,18.9c-4.3,4.3-10.2,6.5-17.8,6.5C312.1,91.8,306.2,89.6,301.9,85.3z"
                />
                <path
                    fill="#fff"
                    d="M362.8,89.8c-4-1.3-7.2-3.1-9.5-5.2l3-6.4c2.2,2,5,3.6,8.4,4.8c3.4,1.2,6.9,1.8,10.5,1.8    c4.7,0,8.3-0.8,10.6-2.4c2.3-1.6,3.5-3.8,3.5-6.4c0-2-0.6-3.6-1.9-4.8c-1.3-1.2-2.9-2.2-4.7-2.8c-1.9-0.7-4.5-1.4-8-2.2    c-4.3-1-7.8-2.1-10.4-3.1c-2.6-1-4.9-2.6-6.8-4.8s-2.8-5.1-2.8-8.7c0-3.1,0.8-5.8,2.4-8.3c1.6-2.5,4.1-4.4,7.3-5.9    c3.3-1.5,7.3-2.2,12.2-2.2c3.4,0,6.7,0.4,10,1.3c3.3,0.9,6.1,2.1,8.4,3.8l-2.7,6.5c-2.4-1.5-5-2.7-7.7-3.5c-2.7-0.8-5.4-1.2-8-1.2    c-4.6,0-8.1,0.8-10.4,2.5c-2.3,1.7-3.5,3.9-3.5,6.5c0,2,0.7,3.6,2,4.8c1.3,1.2,2.9,2.2,4.9,2.9c1.9,0.7,4.6,1.4,7.9,2.2    c4.3,1,7.8,2.1,10.4,3.1c2.6,1,4.9,2.6,6.8,4.7c1.9,2.1,2.8,5,2.8,8.6c0,3-0.8,5.7-2.5,8.2c-1.6,2.5-4.1,4.4-7.4,5.9    c-3.3,1.4-7.4,2.2-12.3,2.2C371,91.8,366.8,91.1,362.8,89.8z"
                />
            </g>
            <g>
                <path
                    fill="#fff"
                    d="M57.5,12.3c27.7,0,50.2,22.5,50.2,50.2s-22.5,50.2-50.2,50.2c-27.7,0-50.2-22.5-50.2-50.2    S29.8,12.3,57.5,12.3 M57.5,7.5c-30.4,0-55,24.6-55,55s24.6,55,55,55s55-24.6,55-55S87.9,7.5,57.5,7.5L57.5,7.5z"
                />
                <g>
                    <g>
                        <path
                            fill="#fff"
                            d="M76.2,59.6v8.9L38.7,51.8v-8.6L76.2,59.6z"
                        />
                        <g>
                            <polygon
                                fill="#fff"
                                points="76.2,26.5 44.9,40.5 54.4,44.6 76.2,35.6      "
                            />
                        </g>
                    </g>
                    <path
                        fill="#fff"
                        d="M38.8,57.6l37.4,16.2v8.6L38.8,98.5v-9.4l26.7-11.4L38.8,66.2V57.6z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default LogoWhite;
