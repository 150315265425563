import { useMemo } from "react";
import { QuestionPreview } from "./QuestionPreview";
import classNames from "classnames";
import { DisplayDirection, QuestionnaireQuestionGroupEntity } from "@Savus-Inc/questionnaire-types";
import { useNGN } from "@Savus-Inc/runtime-ngn/dist/src/ngn";

export const GroupPreview = ({ group }: { group: QuestionnaireQuestionGroupEntity }) => {
  const { visibleQuestions } = useNGN();

  const questions = useMemo(() => {
    return Object.values(visibleQuestions.questions).filter(v => !v.sectionId && !v.parentId);
  }, [visibleQuestions]);

  return (
    <div className='w-full'>
      {!!group.title && (
        <h2 className='text-2xl font-semibold text-gray-800 pb-3 mb-6 border-b border-gray-200'>{group.title}</h2>
      )}

      {!!visibleQuestions.sections.length && (
        <div
          className={classNames("flex gap-8", {
            "flex-col": visibleQuestions.displaySections === DisplayDirection.Col,
            "flex-wrap": visibleQuestions.displaySections === DisplayDirection.RowWrap,
          })}
        >
          {visibleQuestions.sections.map(section => (
            <div key={section.id} className='bg-white rounded-lg  max-w-[47%] w-fit'>
              {!!section.title?.trim().length && (
                <h3 className='text-lg font-semibold text-gray-800 p-4'>{section.title}</h3>
              )}
              <div
                className={classNames("", {
                  "flex flex-col gap-4": section.display === DisplayDirection.Col,
                  "flex flex-wrap gap-4":
                    section.display === DisplayDirection.RowWrap || section.display === DisplayDirection.Row,
                })}
              >
                {section.questions.map(q => (
                  <QuestionPreview questionId={q} key={q} />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
