import React, { useEffect, useState } from "react";
import { useBuilderNGN } from "./BuilderNGN";
import { Question, QuestionnaireQuestionEntity } from "@Savus-Inc/questionnaire-types";

import { Column, Getter, Row, Table } from "@tanstack/react-table";
import { QuestionData } from "../util/useTableData";

const QuestionTableCell = ({
  setTableInput,
  getValue,
  row,
  column,
  table,
}: {
  setTableInput: (value: string) => void;
  getValue: Getter<string>;
  column: Column<QuestionData>;
  table: Table<QuestionData>;
  row: Row<QuestionData>;
}) => {
  const { updateQuestion, changeQuestionUIDetails, selectedQuestion, currentGroup } = useBuilderNGN();

  const initialValue = getValue();
  const [value, setValue] = useState<string | null | number>(initialValue);
  const dynamicColumns = ["maxAnswers", "minAnswers", "addMoreIcon", "addMoreText"];
  const numberColumns = ["partOrder", "minAnswers", "maxAnswers"];

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const updateCell = () => {
    if (!selectedQuestion?.question || !selectedQuestion || !currentGroup) return;
    if (value === null) return;
    if (column.id in selectedQuestion?.question) {
      changeQuestionUIDetails(column.id as keyof Question)(value);
    } else if (column.id in selectedQuestion) {
      if (column.id === "showForCarriers") {
        updateQuestion(column.id as keyof QuestionnaireQuestionEntity)([String(value)]);
      } else {
        updateQuestion(column.id as keyof QuestionnaireQuestionEntity)(value as string);
      }
    }
  };

  const handleChange = (e: any) => {
    if (numberColumns.includes(column.id)) {
      const onlyNumbers = e.target.value.replace(/[^0-9]/g, "");
      setValue(onlyNumbers);
      setTableInput(onlyNumbers);
    } else if (setTableInput) {
      setValue(e.target.value);
      setTableInput(e.target.value);
    }
  };

  return (
    <input
      className={`h-full w-full text-ellipsis whitespace-nowrap focus:outline-none pl-2 bg-transparent`}
      readOnly={dynamicColumns.includes(column.id) && !row.original.multipleAnswers ? true : false}
      value={(value === 0 ? "0" : value) || ""}
      onClick={() => {
        if (value) setTableInput(String(value));
      }}
      onChange={handleChange}
      onBlur={updateCell}
    />
  );
};

export default QuestionTableCell;
