import { QuestionnaireQuestionEntity } from "@Savus-Inc/questionnaire-types";

interface ImportantNotesProps {
  question: QuestionnaireQuestionEntity;
  subQuestions?: QuestionnaireQuestionEntity[];
}

export const ImportantNotes = ({ question, subQuestions }: ImportantNotesProps) => (
  <div className="mt-6 bg-blue-50 border border-blue-100 rounded-lg p-4 animate-fade-in">
    <h4 className="text-sm font-semibold text-blue-700 mb-2 flex items-center gap-2">
      <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      Important Notes
    </h4>
    <ul className="space-y-2">
      {question.question?.multipleAnswers && (
        <li className="flex items-center gap-2 text-sm text-blue-600 animate-fade-in-left">
          <svg className="w-4 h-4 text-blue-400 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          Multiple answers accepted (value should be an array)
        </li>
      )}
      {/* ... rest of the notes ... */}
    </ul>
  </div>
); 