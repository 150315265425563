import { createContext, PropsWithChildren, useContext, useState, useEffect, useMemo } from "react";
import {
  QuestionnaireQuestionGroupEntity,
  QuestionnaireQuestionEntity,
  RuleGroupKind,
  QuestionVisibility,
  RuleGroupEntity,
  QuestionRuleGroupEntity,
} from "@Savus-Inc/questionnaire-types";
import { useSearchParams } from "react-router-dom";
import { useAppState } from "../../state/State";
import { calculateQuestionExternalIdMap } from "@Savus-Inc/questionnaire-ngn/dist/utils";
import { group } from "console";
import { ruleGroupToString } from "@Savus-Inc/dsl/dist/utils/transformer";
import { RuleGroup } from "@Savus-Inc/dsl/dist/types";

interface CarrierFilter {
  carrier: string;
  classcode?: string;
}

export interface StateFilter {
  id: string;
  name: string;
  shortName: string;
  fullName: string;
}

interface DocumentationContextType {
  selectedGroup?: QuestionnaireQuestionGroupEntity;
  selectedQuestion?: QuestionnaireQuestionEntity;
  carriers: string[];
  setSelectedGroup: (group: QuestionnaireQuestionGroupEntity) => void;
  setSelectedQuestion: (question: QuestionnaireQuestionEntity) => void;
  filterCarriers: CarrierFilter[];
  setFilterCarriers: (carriers: CarrierFilter[]) => void;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  filteredQuestions: QuestionnaireQuestionEntity[];
  referenceMap: Record<string, string>;
  states: StateFilter[];
  filterStates: StateFilter[];
  setFilterStates: (states: StateFilter[]) => void;
}

const DocumentationContext = createContext<DocumentationContextType>({} as DocumentationContextType);

export const useDocumentationContext = () => useContext(DocumentationContext);

export const DocumentationCTX = ({ children }: PropsWithChildren) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedGroup, setSelectedGroup] = useState<QuestionnaireQuestionGroupEntity>();
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionnaireQuestionEntity>();
  const [filterCarriers, setFilterCarriers] = useState<CarrierFilter[]>([]);
  const [filterStates, setFilterStates] = useState<StateFilter[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredQuestions, setFilteredQuestions] = useState<QuestionnaireQuestionEntity[]>([]);
  const {
    state: { currentQuestionnaire, carriers, states },
  } = useAppState();

  const [isLoading, setIsLoading] = useState(true);

  // Load filters from URL on init
  useEffect(() => {
    // Load search term
    const urlSearchTerm = searchParams.get("search") || "";
    setSearchTerm(urlSearchTerm);

    // Load carriers
    const urlCarriers = searchParams.get("carriers");
    if (urlCarriers) {
      try {
        const parsedCarriers = decodeURIComponent(urlCarriers);
        setFilterCarriers(
          typeof parsedCarriers === "string"
            ? parsedCarriers
                .split(",")
                .map(carrier => ({ carrier: carrier.split("-")[0], classcode: carrier.split("-")[1] }))
            : [],
        );
      } catch (e) {
        console.error("Failed to parse carriers from URL");
      }
    }

    // Load states
    const urlStates = searchParams.get("states");
    if (urlStates) {
      try {
        const stateIds = decodeURIComponent(urlStates);
        const selectedStates = states.filter(state => stateIds.includes(state.shortName));
        setFilterStates(selectedStates);
      } catch (e) {
        console.error("Failed to parse states from URL");
      }
    }

    // If there's a question ID in the URL, find and select it
    const questionId = searchParams.get("questionId");
    if (questionId) {
      const question = currentQuestionnaire?.groups.flatMap(group => group.questions).find(q => q.question?.externalId === questionId);
      if (question) {
        setSelectedQuestion(question);
      } else {
        setSelectedQuestion(undefined);
      }
    } else {
      setSelectedQuestion(undefined);
    }
    setIsLoading(false);
  }, [searchParams, states, currentQuestionnaire]);

  // Update URL when filters change
  useEffect(() => {
    if (isLoading) return;
    const newParams = new URLSearchParams(searchParams);

    // Update search term
    if (searchTerm) {
      newParams.set("search", searchTerm);
    } else {
      newParams.delete("search");
    }

    // Update carriers
    if (filterCarriers.length > 0) {
      newParams.set("carriers", filterCarriers.map(c => [c.carrier, c.classcode].filter(Boolean).join("-")).join(","));
    } else {
      newParams.delete("carriers");
    }

    // Update states
    if (filterStates.length > 0) {
      const stateIds = filterStates.map(state => state.shortName);
      newParams.set("states", stateIds.join(","));
    } else {
      newParams.delete("states");
    }

    setSearchParams(newParams, { replace: true });
  }, [searchTerm, filterCarriers, filterStates]);

  const handleGroupSelection = (group: QuestionnaireQuestionGroupEntity) => {
    // When selecting a group, remove question param but keep other params
    const newParams = new URLSearchParams(searchParams);
    newParams.set("page", group.partOrder.toString());
    newParams.delete("question");
    setSearchParams(newParams);
    setSelectedGroup(group);
    setSelectedQuestion(undefined);
    
    //Only set filtered questions if the group is different from the current group
    if (selectedGroup !== group) {
    setFilteredQuestions(filteredQuestions);
    }
  };

  const referenceMap: Record<string, string> = useMemo(() => {
    if (!currentQuestionnaire) {
      return {};
    }
    const { map } = calculateQuestionExternalIdMap(currentQuestionnaire);
    return map as Record<string, string>;
  }, [currentQuestionnaire]);

  const isVisible = (rules: QuestionRuleGroupEntity[]) => {
    const displayRules = rules.map(group => ({
      rule: ruleGroupToString(group),
      action: group.actionKind as QuestionVisibility,
    }));

    const matchesStates =
      displayRules.length === 0 ||
      !displayRules.some(dr => dr.rule.includes("$State")) ||
      displayRules
        .filter(dr => dr.rule.includes("$State"))
        .some(
          dr =>
            (dr.action === QuestionVisibility.Show && filterStates.some(state => dr.rule.includes(state.shortName))) ||
            (dr.action === QuestionVisibility.Hidden && !filterStates.some(state => dr.rule.includes(state.shortName))),
        );

    const matchedClassCodes =
      displayRules.length === 0 ||
      !filterCarriers.some(v => !!v.classcode) ||
      !displayRules.some(dr => dr.rule.includes("ClassCode")) ||
      displayRules
        .filter(dr => dr.rule.includes("ClassCode"))
        .some(
          dr =>
            (dr.action === QuestionVisibility.Show &&
              filterCarriers
                .filter(v => v.classcode)
                .some(
                  c =>
                    dr.rule.toLowerCase().includes(c.carrier.toLowerCase()) &&
                    (!c.classcode ||
                      dr.rule
                        .split(",")
                        .map(v => v.trim().replace("(", "").replace(")", ""))
                        .includes(c.classcode)),
                )) ||
            (dr.action === QuestionVisibility.Hidden &&
              !filterCarriers.some(
                c =>
                  dr.rule.toLowerCase().includes(c.carrier.toLowerCase()) &&
                  (!c.classcode ||
                    dr.rule
                      .split(",")
                      .map(v => v.trim().replace("(", "").replace(")", ""))
                      .includes(c.classcode)),
              )),
        );

    return matchesStates && matchedClassCodes;
  };

  const handleQuestionSelection = (question: QuestionnaireQuestionEntity) => {
    // When selecting a question, keep page param and update question param
    const newParams = new URLSearchParams(searchParams);
    newParams.set("question", question.question?.externalId || "");
    setSearchParams(newParams);

    if (question.question?.values) {
      setSelectedQuestion({
        ...question,
        question: {
          ...question.question,
          values: question.question.values.filter(v => {
            if (!v?.visibility?.length) {
              return true;
            }

            return isVisible(v.visibility as never);
          }),
        },
      });
    } else {
      setSelectedQuestion(question);
    }
  };

  // Handle URL params for initial load and navigation
  useEffect(() => {
    if (!currentQuestionnaire) return;

    const page = searchParams.get("page");
    const questionId = searchParams.get("question");

    if (page) {
      const group = currentQuestionnaire.groups.find(g => g.partOrder === parseInt(page));
      if (group) {
        setSelectedGroup(group);

        // If there's a question ID in the URL, find and select it
        if (questionId) {
          const question = group.questions.find(q => q.question?.externalId === questionId);
          if (question) {
            setSelectedQuestion(question);
          }
        }
      }
    }
  }, [searchParams, currentQuestionnaire]);

  // Combined filter effect
  useEffect(() => {
    if (!currentQuestionnaire || !selectedGroup) return;

    const filtered = selectedGroup.questions.filter(q => {
      const matchesCarriers =
        filterCarriers.length === 0 || filterCarriers.some(filter => q.showForCarriers.includes(filter.carrier));

      const matchesSearch =
        !searchTerm ||
        q.question?.label?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        q.question?.externalId?.toLowerCase().includes(searchTerm.toLowerCase());

      const displayRules = q.ruleGroups.filter(group => group.kind === RuleGroupKind.Visibility);

      return matchesCarriers && matchesSearch && isVisible(displayRules);
    });

    setFilteredQuestions(filtered);
  }, [filterCarriers, filterStates, searchTerm, selectedGroup, currentQuestionnaire, selectedQuestion]);

  const handleSetFilterCarriers = (carriers: CarrierFilter[]) => {
    setFilterCarriers(carriers);
  };

  const handleSetFilterStates = (states: StateFilter[]) => {
    setFilterStates(states);
  };

  const handleSetSearchTerm = (term: string) => {
    setSearchTerm(term);
  };

  const value: DocumentationContextType = {
    selectedGroup,
    selectedQuestion,
    carriers: carriers.map(c => c.name),
    setSelectedGroup: handleGroupSelection,
    setSelectedQuestion: handleQuestionSelection,
    filterCarriers,
    setFilterCarriers: handleSetFilterCarriers,
    searchTerm,
    setSearchTerm: handleSetSearchTerm,
    filteredQuestions,
    referenceMap,
    states: states,
    filterStates,
    setFilterStates: handleSetFilterStates,
  };

  return <DocumentationContext.Provider value={value}>{children}</DocumentationContext.Provider>;
};
