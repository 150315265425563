import { useCallback, useMemo } from "react";
import classNames from "classnames";
import { cb } from "../../utils/cd";
import {
  AnswerValue,
  InputType,
  QuestionValidationResult,
  QuestionValueAutocompleteIntegration,
  QuestionValueFormatting,
  ValidationAlertLevel,
} from "@Savus-Inc/questionnaire-types";

import { unpack } from "../../utils/unpack";
import { AddIcon } from "../util/AddNew";
import { RemoveIcon } from "../util/ItemActions";
import MultiSelect from "../util/MultiSelect";
import { useQuestionAnswer } from "@Savus-Inc/runtime-ngn/dist/src/ngn";
import { LocationBusinessTypeQuestion } from "./AsyncQuestions/LocationBusinessTypeQuestion";
import { StateQuestion } from "./AsyncQuestions/StateQuestion";
import { WCBusinessDescription } from "./AsyncQuestions/WCBusinessDescription";

// Update input styling for consistency
const baseInputStyles =
  "w-full border border-secondary-light rounded-in px-3 py-2 text-base focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary transition-colors";
const baseSelectStyles =
  "w-full border border-secondary-light rounded-in px-3 py-2 text-base focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary bg-white transition-colors";
const baseButtonStyles = "px-4 py-2 rounded-in transition-colors text-base font-medium";

export function ValidationMessages({
                                     validationMessages,
                                   }: {
  validationMessages: QuestionValidationResult | QuestionValidationResult[];
}) {
  return (
    <div className="flex flex-col gap-1 mt-1">
      {validationMessages.flat().map(v => (
        <div
          key={v.message}
          className={classNames("rounded-in px-3 py-2 text-sm", {
            "bg-danger-light text-danger": v.alertLevel === ValidationAlertLevel.Error,
            "bg-yellow-light text-yellow-dark border-l-4 border-warning": v.alertLevel === ValidationAlertLevel.Warning,
          })}
        >
          {v.message}
        </div>
      ))}
    </div>
  );
}

export const AnswerValueInput = ({
                                   idx = 0,
                                   me,
                                   parentPath,
                                 }: {
  idx?: number;
  me: string;
  parentPath: (string | number)[];
}) => {
  const { answer, value: val, question } = useQuestionAnswer(me, parentPath);

  const value = useMemo(() => {
    if ([null, undefined].includes(idx as never)) {
      return val?.value;
    } else if (Array.isArray(val?.value)) {
      return val?.value[idx];
    } else {
      return val?.value;
    }
  }, [val, idx]);

  const isSelected = useCallback(
    (v: AnswerValue) => {
      if (Array.isArray(val?.value) && val?.multiple) {
        return (val.value as AnswerValue[]).includes(v);
      } else if (val?.value) {
        return v === val?.value;
      }
    },
    [val],
  );

  switch (question?.autocompleteIntegration) {
    case QuestionValueAutocompleteIntegration.OwnValues:
      switch (question?.inputType) {
        case InputType.LocationBusinessType:
          return <LocationBusinessTypeQuestion me={me} parentPath={parentPath} />;
        case InputType.WCBusinessDescription:
          return <WCBusinessDescription me={me} parentPath={parentPath} />;
        case InputType.State:
          return <StateQuestion me={me} parentPath={parentPath} />;
        case InputType.Select:
          if (question?.multipleAnswers) {
            return (
              <MultiSelect
                options={question?.values.map(v => ({ label: v.label, value: v.value.value })) as never}
                value={(value as string[]) || []}
                onChange={answer(idx) as never}
              />
            );
          }
          return (
            <select value={value?.toString() || ""} onChange={unpack(answer(idx))} className={baseSelectStyles}>
              <option value={question?.placeholder || ""}></option>
              {question?.values.map(v => (
                <option value={v.value.value as never} key={v.id} selected={isSelected(v.value.value as never)}>
                  {v.label}
                </option>
              ))}
            </select>
          );
        case InputType.RadioGroup:
          return (
            <div className="flex gap-4 flex-wrap">
              {question?.values.map(v => (
                <label key={v.id} className="flex items-center gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name={question?.id}
                    value={v.value.value as never}
                    checked={isSelected(v.value.value as never)}
                    onChange={unpack(answer(idx))}
                    className="w-4 h-4 text-primary border-secondary-light focus:ring-primary"
                  />
                  <span className="text-dark-blue">{v.label}</span>
                </label>
              ))}
            </div>
          );
        case InputType.YesNo:
          return (
            <div className="flex">
              {question?.values.map(val => (
                <div
                  key={val.id}
                  className={classNames(
                    "cursor-pointer px-4 py-2 flex-1 text-center transition-colors border border-secondary",
                    {
                      "bg-primary text-white": isSelected(val.value.value as never),
                      "hover:bg-secondary-lighter": !isSelected(val.value.value as never),
                    },
                  )}
                  onClick={cb(answer(idx), val.value.value as AnswerValue)}
                >
                  {val.label}
                </div>
              ))}
            </div>
          );
        case InputType.Checkbox:
          return (
            <div className={"flex flex-col gap-1"}>
              {question?.values.map((v, i) => (
                <label key={v.id} htmlFor={question?.id} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name={question?.id} // Same name for all checkboxes if you want grouping behavior
                    value={v.value?.value as string}
                    checked={isSelected(v?.value?.value as string)}
                    onChange={unpack(answer(i))} // Handle checkbox changes
                    className="form-checkbox h-4 w-4 text-sky-600"
                  />
                  <span>{v.label}</span>
                </label>
              ))}
            </div>
          );
        case InputType.RichText:
          return (
            <textarea
              value={value?.toString() || ""}
              onChange={unpack(answer(idx))}
              className={`${baseInputStyles} min-h-[200px] resize-y`}
              placeholder={question?.placeholder || ""}
              rows={20}
            />
          );
        default: {
          if (question?.values.length)
            return (
              <div className={"flex justify-start gap-10 flex-wrap"}>
                {question?.values.map((val, idx) => (
                  <div
                    key={val.id}
                    className={classNames(
                      "cursor-pointer px-1 py-0.5 w-full min-w-40 rounded-lg border border-sky-800 text-base text-center hover:bg-sky-500 hover:text-white",
                      { "bg-sky-500 text-white": isSelected(val.value.value as never) },
                    )}
                    onClick={cb(answer(idx), val.value.value as AnswerValue)}
                  >
                    {val.label}
                  </div>
                ))}
              </div>
            );
          return (
            <input
              type="text"
              value={value?.toString() || ""}
              onChange={unpack(answer(idx))}
              className={baseInputStyles}
              placeholder={question?.placeholder || ""}
            />
          );
        }
      }
    case QuestionValueAutocompleteIntegration.GoogleLocation:
      return (
        <input type="text" id="name" className="w-max min-w-96 border border-gray-300 rounded-md px-1 py-1 text-base" />
      );
  }

  switch (question?.inputType) {
    case InputType.Header: {
      return (
        <input
          value={value?.toString() || ""}
          onChange={unpack(answer(idx))}
          className="max-w-90% px-1 bg-transparent py-1 text-base rounded min-w-[378px] focus:outline-none absolute top-4"
          placeholder={question?.placeholder || ""}
        />
      );
    }
    case InputType.RichText:
      return (
        <textarea
          value={value?.toString() || ""}
          onChange={unpack(answer(idx))}
          className="w-full border border-gray-300 px-1 py-1 text-base rounded min-w-[400px]"
          placeholder={question?.placeholder || ""}
          rows={20}
        />
      );
    case InputType.Statement as never: {
      return (
        <div className={"flex"}>
          <div
            className={classNames(
              "cursor-pointer px-1 py-0.5 w-full min-w-32  border border-gray-300 text-base text-center hover:bg-sky-500 hover:text-white flex items-center justify-center",
              { "bg-sky-500 text-white": isSelected("No") },
            )}
            onClick={cb(answer(idx), "No" as AnswerValue)}
          >
            No
          </div>
          <div
            className={classNames(
              "cursor-pointer px-1 py-0.5 w-full min-w-32  border border-gray-300 text-base text-center hover:bg-sky-500 hover:text-white flex items-center justify-center",
              { "bg-sky-500 text-white": isSelected("Yes") },
            )}
            onClick={cb(answer(idx), "Yes" as AnswerValue)}
          >
            Yes
          </div>
        </div>
      );
    }
  }

  switch (question?.format) {
    case QuestionValueFormatting.PasswordMask:
    case QuestionValueFormatting.FEIN:
    case QuestionValueFormatting.SSN:
      return (
        <input
          type={"password"}
          value={value?.toString() || ""}
          onChange={unpack(answer(idx))}
          className="w-max border border-gray-300 px-1 py-1 text-base rounded min-w-[400px]"
          placeholder={question?.placeholder || ""}
        />
      );
    case QuestionValueFormatting.DateFormat_Year:
      return (
        <input
          type={"number"}
          value={value?.toString() || ""}
          onChange={unpack(answer(idx))}
          className="w-max border border-gray-300 px-1 py-1 text-base rounded min-w-[400px]"
          placeholder={question?.placeholder || ""}
        />
      );
    case QuestionValueFormatting.DateFormat_MonthYear:
      return (
        <input
          type={"month"}
          value={value?.toString() || ""}
          onChange={unpack(answer(idx))}
          className="w-max border border-gray-300 px-1 py-1 text-base rounded min-w-[400px]"
          placeholder={question?.placeholder || ""}
        />
      );
    case QuestionValueFormatting.DateFormat_FullDate:
      return (
        <input
          type={"date"}
          value={value?.toString() || ""}
          onChange={unpack(answer(idx))}
          className="w-max border border-gray-300 px-1 py-1 text-base rounded min-w-[400px]"
          placeholder={question?.placeholder || ""}
        />
      );
    case QuestionValueFormatting.TimeFormat_12H:
      return (
        <div className={"flex gap-4"}>
          <label>Hours:</label>
          <select>
            {Array.from(Array(13).keys()).map((_, i) => (
              <option value={i}>{i}</option>
            ))}
          </select>

          <label>Minutes:</label>
          <select>
            {Array.from(Array(61).keys()).map((_, i) => (
              <option value={i}>{i}</option>
            ))}
          </select>

          <label htmlFor="ampm">AM/PM:</label>
          <select id="ampm">
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </select>
        </div>
      );
    case QuestionValueFormatting.TimeFormat_24H:
      return (
        <div className={"flex gap-4"}>
          <label>Hours:</label>
          <select>
            {Array.from(Array(24).keys()).map((_, i) => (
              <option value={i}>{i}</option>
            ))}
          </select>

          <label>Minutes:</label>
          <select>
            {Array.from(Array(61).keys()).map((_, i) => (
              <option value={i}>{i}</option>
            ))}
          </select>

          <label htmlFor="ampm">AM/PM:</label>
          <select id="ampm">
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </select>
        </div>
      );
    // question	questionnaire_name	stateIncluded	stateExcluded	stateProductExclude	client	product	carrier	application	userFlow	pageId	pageOrder	pageDisplay	pageTitle	pageSubText	sectionId	sectionOrder	sectionDisplay	sectionTitle	sectionSubText	subSection	questionId	questionOrder	questionContainerId	subQuestionDisplay	fieldLabel	fieldInputType	questionKind	fieldMultipleAnswers	actionButton	minAnswers	maxAnswers	questionFormat	questionCode	answerDisplay	autocompleteIntegration	valueId	answerOrder	answerValue	answerLabel	defaultValue	fieldPlaceholder	fieldSubText	fieldTooltip	visibility	Required	warning	classCodeInclude	classCodeExclude	decline	jsonNode	prefill

    case QuestionValueFormatting.CurrencyFormat:
      return (
        <div className={"flex items-center gap-1"}>
          <span className={"font-bold"}>$</span>
          <input
            type={"number"}
            value={value?.toString() || ""}
            onChange={unpack(answer(idx))}
            className="w-max border border-gray-300 px-1 py-1 text-base rounded min-w-[400px]"
            placeholder={question?.placeholder || ""}
          />
        </div>
      );
    case QuestionValueFormatting.Percentage:
      return (
        <div className={"flex items-center gap-1"}>
          <input
            type={"number"}
            value={value?.toString() || ""}
            onChange={unpack(answer(idx))}
            className="w-max border border-gray-300 px-1 py-1 text-base rounded min-w-[400px]"
            placeholder={question?.placeholder || ""}
          />
          <span className={"font-bold"}>%</span>
        </div>
      );
    default:
      return (
        <input
          type={question?.kind === "timestamp" ? "date" : question?.kind === "bool" ? "checkbox" : question?.kind}
          value={value?.toString() || ""}
          onChange={unpack(answer(idx))}
          className="w-max border border-gray-300 px-1 py-1 text-base rounded min-w-[400px]"
          placeholder={question?.placeholder || ""}
        />
      );
  }
};

export const ValuePreview = ({ me, parentPath }: { me: string; parentPath: (string | number)[] }) => {
  const {
    answer,
    value,
    validationMessages,
    defaultValue,
    displayMultipleInputs,
    canRemoveAnswers,
    canAddMoreAnswers,
    question,
  } = useQuestionAnswer(me, parentPath);

  if (question?.inputType === (InputType.Statement as never)) {
    return (
      <div className={"flex flex-col mt-1 gap-2"}>
        {question.values.map((v, i) => (
          <div className={"pl-4"} key={i}>
            - {v.label}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-2">
      {displayMultipleInputs && value?.value && value?.multiple ? (
        <div className="flex flex-wrap items-center gap-3">
          {value?.value?.map((v, i) => (
            <div key={`${i}`} className="flex items-center gap-2">
              <AnswerValueInput idx={i} me={me} parentPath={parentPath} />
              {canRemoveAnswers && (
                <button
                  onClick={cb(answer(i), null)}
                  className="p-1 text-danger hover:bg-danger-light rounded-in transition-colors"
                >
                  <RemoveIcon className="w-5 h-5" />
                </button>
              )}
            </div>
          ))}
          {canAddMoreAnswers && (
            <button
              onClick={cb(answer(value.value.length), defaultValue)}
              className={`${baseButtonStyles} border border-primary text-primary hover:bg-primary hover:text-white`}
            >
              <AddIcon className="w-5 h-5" />
            </button>
          )}
        </div>
      ) : (
        <AnswerValueInput me={me} parentPath={parentPath} />
      )}
      <ValidationMessages validationMessages={validationMessages} />
    </div>
  );
};
