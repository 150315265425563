import { toKebabCase } from "@Savus-Inc/questionnaire-ngn/dist/utils";
import { Questionnaire } from "@Savus-Inc/questionnaire-types";

export const prepareQuestionnaire = (currentQuestionnaire: Questionnaire): Questionnaire => {
  const questionMap: Record<string, string> = currentQuestionnaire.groups.flatMap(g => g.questions).reduce(
    (p, c) => ({
      ...p,
      [c.id]: c.externalId,
    }),
    {},
  );

  return {
    ...currentQuestionnaire,
    groups: currentQuestionnaire.groups.map(gr => {

      const sectionsMap: Record<string, string> =
        gr.sections?.reduce(
          (p, c) =>
            c.title
              ? {
                ...p,
                [c.id]: c.partOrder.toString(),
              }
              : p,
          {},
        ) || {};
      const groupId = toKebabCase(gr.title);
      return {
        ...gr,
        id: groupId,
        sections: gr.sections?.map(s => ({
          ...s,
          id: sectionsMap[s.id],
          questions: s.questions.map(q => {
            if (q.startsWith("Q")) {
              return q;
            }
            return questionMap[q];
          }),
        })),
        questions: gr.questions.map(q => ({
          ...q,
          externalId: q.question?.externalId,
          groupId,
          sectionId: q.sectionId && Number.isNaN(+q.sectionId) ? sectionsMap[q.sectionId] : q.sectionId,
          parentId: q.parentId && !q.parentId.startsWith("Q-") ? questionMap[q.parentId] : q.parentId,
        })),
      } as never;
    }),
  } as never;
};