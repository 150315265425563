import { rankItem } from "@tanstack/match-sorter-utils";
import { useBuilderNGN } from "../Questionaire/BuilderNGN";
import { DisplayDirection, Question, QuestionnaireQuestionEntity } from "@Savus-Inc/questionnaire-types";
import { Column, Row } from "@tanstack/react-table";
import { QuestionData } from "./useTableData";

export const fuzzyFilter = (row: any, columnId: any, value: any, addMeta: any) => {
  const itemRank = rankItem(row.getValue(columnId), value);

  addMeta({ itemRank });

  return itemRank.passed;
};

export const TableSelect = ({
  suggestions,
  column,
  table,
  row,
}: {
  suggestions: { label: string; value: string | null }[];

  column: Column<QuestionData>;
  table: any;
  row: Row<QuestionData>;
}) => {
  const { changeQuestionUIDetails, updateQuestion, questions, updateSection } = useBuilderNGN();
  const { updateData } = table?.options?.meta;
  const dynamicColumns = ["answerDisplay", "maxAnswers", "minAnswers", "addMoreIcon", "addMoreText"];

  const handleSelectionClick = (
    column: Column<QuestionData>,
    item: {
      label: string;
      value: string | null;
    },
  ) => {
    if (column.id === "sectionTitle") {
      updateQuestion("sectionId")(item.value);
    } else if (column.id === "containerQuestion") {
      updateQuestion("parentId")(item.value);
    } else if (Object.keys(questions).includes(column.id)) {
      updateQuestion(column.id as keyof QuestionnaireQuestionEntity)(item.value);
    } else if (column.id === "multipleAnswers") {
      const value = item.value === "true" ? true : false;
      changeQuestionUIDetails("multipleAnswers")(value);
      updateData(row.index, column.id, value);
      return;
    } else if (column.id === "sectionDisplay") {
      updateSection(row.original.sectionId)("display")(item.value as DisplayDirection);
    } else if (column.id === "groupTitle") {
      updateQuestion("groupId")(item.value);
    } else {
      changeQuestionUIDetails(column.id as keyof Question)(item.value);
    }

    updateData(row.index, column.id, item.label);
  };

  return (
    <div className='absolute w-fit min-w-full h-fit flex flex-col top-8 left-0 bg-white border border-gray-300 shadow-lg text-left overflow-y-hidden z-10 '>
      {suggestions.map(
        (item: { label: string; value: string | null }) =>
          item.label && (
            <div
              key={item.label}
              onClick={() => {
                handleSelectionClick(column, item);
              }}
              className='px-2 py-2 hover:bg-gray-200 cursor-pointer'
            >
              {item.label}
            </div>
          ),
      )}
    </div>
  );
};

export const TableMultiSelect = ({
  suggestions,
  column,
  table,
  row,
  value,
}: {
  suggestions: { label: string; value: string | null }[];
  column: any;
  table: any;
  row: any;
  value: string[] | string;
}) => {
  const { updateQuestion } = useBuilderNGN();
  const { updateData } = table?.options?.meta;
  const handleOptionClick = (optionValue: string | null) => {
    if (optionValue) {
      const newValue = (Array.isArray(value) ? value : [value]).includes(optionValue)
        ? (Array.isArray(value) ? value : [value]).filter(val => val !== optionValue)
        : [...(Array.isArray(value) ? value : [value]), optionValue];
      updateData(row.index, column.id, newValue);

      updateQuestion("showForCarriers")(newValue);
    }
  };

  return (
    <div className='absolute w-fit min-w-full flex flex-col top-8 left-0 bg-white border border-gray-300 shadow-lg text-left overflow-y-hidden z-10 '>
      {suggestions.map((item: { label: string; value: string | null }) => (
        <div onClick={() => handleOptionClick(item.value)} className='px-2 py-2 hover:bg-gray-200 cursor-pointer'>
          {item.label}
        </div>
      ))}
    </div>
  );
};
