import { useQuestionAnswer, useAsyncValueIntegration, LocationBusinessType } from "@Savus-Inc/runtime-ngn/dist/src/ngn";
import { useMemo, useState } from "react";
import classNames from "classnames";
import { RemoveIcon } from "../../util/ItemActions";
import { unpack } from "../../../utils/unpack";
import { sleep } from "@Savus-Inc/questionnaire-ngn/dist/utils";
import { cb } from "../../../utils/cd";

export const LocationBusinessTypeQuestion = ({ me, parentPath }: { me: string; parentPath: (string | number)[] }) => {
  const { answer, value, question } = useQuestionAnswer(me, parentPath);
  const { loading, choices, setTerm, term } = useAsyncValueIntegration<LocationBusinessType>("location-business-type");

  const [focused, setFocused] = useState<boolean>(false);
  const selectedChoices: string[] = useMemo(
    () => (value?.value as unknown as LocationBusinessType[])?.map(v => v.title) || [],
    [value],
  );

  const handleRemove = (idx: number) => {
    answer(idx)(null);
  };

  return (
    <div className='flex flex-col gap-3 max-w-[370px] relative'>
      <div className='relative'>
        <input
          type='text'
          value={term || ""}
          onBlur={() => sleep(100).then(cb(setFocused, false))}
          onFocus={cb(setFocused, true)}
          onChange={unpack(setTerm)}
          placeholder='Type to search (min 3 chars)...'
          className='w-full border border-secondary-light rounded-lg px-4 py-2.5 text-base 
                   focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary 
                   transition-colors placeholder:text-gray-400'
        />
        {loading && (
          <div className='absolute right-3 top-1/2 -translate-y-1/2'>
            <div className='animate-spin rounded-full h-5 w-5 border-2 border-primary border-t-transparent'></div>
          </div>
        )}
      </div>

      {selectedChoices.length > 0 && (
        <div className='flex flex-wrap gap-2'>
          {selectedChoices.map((choice, idx) => (
            <div
              key={choice}
              className='flex items-center gap-2 bg-secondary-lighter px-3 py-1.5 rounded-lg
                       text-secondary-text text-sm font-medium'
            >
              <span>{choice}</span>
              <button
                onClick={() => handleRemove(idx)}
                className='text-secondary-text hover:text-danger transition-colors'
              >
                <RemoveIcon className='w-4 h-4' />
              </button>
            </div>
          ))}
        </div>
      )}

      {focused && (
        <div className='max-h-[300px] w-[370px] overflow-y-auto rounded-lg border border-secondary-light absolute top-12 bg-white'>
          {choices?.length ? (
            choices.map(choice => (
              <button
                key={choice.id}
                onClick={() =>
                  answer(question?.multipleAnswers ? (value?.multiple ? value.value?.length || 0 : 0) : 0)(
                    choice as never,
                  )
                }
                className={classNames(
                  "w-full text-left px-4 py-3 hover:bg-secondary-lighter transition-colors",
                  "border-b border-secondary-light last:border-b-0",
                  "focus:outline-none focus:bg-secondary-lighter",
                )}
              >
                <div className='font-medium text-gray-800'>{choice.title}</div>
              </button>
            ))
          ) : (
            <div className='text-center py-8 text-gray-500'>
              {loading ? "Loading types..." : term ? "No matching types found" : "Start typing to search types"}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
