import { AnswerValueInput, ValuePreview } from "./ValuePreview";
import classNames from "classnames";
import Tooltip from "../util/Tooltip";
import { AddIcon } from "../util/AddNew";
import { cb } from "../../utils/cd";
import { DisplayDirection, InputType, Question, QuestionnaireQuestionEntity } from "@Savus-Inc/questionnaire-types";
import { RemoveIcon } from "../util/ItemActions";
import { useQuestionAnswer } from "@Savus-Inc/runtime-ngn/dist/src/ngn";
import { useMemo } from "react";

export const SubQuestionPreview = ({
  parentPath,
  subquestions,
  idx,
  question,
  removeQuestion,
}: {
  parentPath: (string | number)[];
  subquestions: QuestionnaireQuestionEntity[];
  idx?: number;
  question: Question;
  removeQuestion?: () => void;
}) => {
  const hasHeader = useMemo(
    () => subquestions.some(q => q.question?.inputType === InputType.Header),
    [subquestions, question],
  );

  return (
    <>
      <div className={"border border-gray-200 rounded w-fit relative h-fit"}>
        <div
          className={classNames("flex gap-2 relative px-2", {
            "justify-evenly": question.subQuestions === DisplayDirection.Row,
            "flex-col": !question.subQuestions || question.subQuestions === DisplayDirection.Col,
            "flex-wrap gap-y-1 gap-x-8": question.subQuestions === DisplayDirection.RowWrap,
            "pt-16": hasHeader,
          })}
        >
          {hasHeader && (
            <div
              className={
                "p-2 w-full absolute top-0 left-0 rounded-t flex justify-end items-center gap-2 border-b h-16 shadow bg-gray-50"
              }
            >
              {removeQuestion && (
                <span className={"cursor-pointer text-red-500"} onClick={removeQuestion}>
                  <RemoveIcon />
                </span>
              )}
            </div>
          )}
          {subquestions.map(q => (
            <div className={"flex-1"} key={q.id}>
              <QuestionPreview
                questionId={q.externalId as string}
                parentPath={
                  idx !== undefined
                    ? [...parentPath, "value", idx]
                    : [...parentPath, ...(question.multipleAnswers ? ["value", 0] : [])]
                }
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export function QuestionPreview({
  questionId,
  parentPath = [],
}: {
  questionId: string;
  parentPath?: (string | number)[];
}) {
  const { isGhost, canAddMoreAnswers, isContainerQuestion, value, answer, subquestions, canRemoveAnswers, question } =
    useQuestionAnswer(questionId, parentPath);

  if (isGhost || !question) {
    return <></>;
  }

  if (isContainerQuestion) console.log("canAddMoreAnswers", canAddMoreAnswers);
  return (
    <div className={classNames("bg-white rounded-lg p-2 px-4")}>
      <div className='flex items-start gap-4 mb-1'>
        <div className='flex-1'>
          {question.inputType !== InputType.Header && (
            <h3 className='text-lg font-medium text-gray-800'>{question.label}</h3>
          )}
          {question.subText && <p className='text-sm text-gray-600 mt-1'>{question.subText}</p>}
        </div>
        {!!question.tooltip && (
          <Tooltip content={question.tooltip}>
            <div className='text-gray-400 hover:text-primary cursor-help transition-colors'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='w-5 h-5'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'
                />
              </svg>
            </div>
          </Tooltip>
        )}
      </div>

      {!isContainerQuestion && (
        <div className='mt-1'>
          <ValuePreview me={questionId} parentPath={parentPath} />
        </div>
      )}

      {isContainerQuestion && (
        <div
          className={classNames("flex gap-4", {
            "justify-evenly overflow-x-auto max-w-[100%]": question.answerDisplay === DisplayDirection.Row,
            "flex-col": question.answerDisplay === DisplayDirection.Col,
            "flex-wrap gap-y-1 gap-x-8": question.answerDisplay === DisplayDirection.RowWrap,
          })}
        >
          <>
            {subquestions.map((v, i) => (
              <div className={"border border-gray-200 rounded w-fit relative h-fit"}>
                <SubQuestionPreview
                  key={i}
                  parentPath={[...parentPath, questionId]}
                  subquestions={v}
                  idx={i}
                  question={question}
                  removeQuestion={canRemoveAnswers ? cb(answer(i), null) : undefined}
                />
                {isContainerQuestion && <AnswerValueInput me={question.externalId as string} parentPath={parentPath} />}
              </div>
            ))}
          </>

          {canAddMoreAnswers && (
            <div
              className={"bg-gray-100 w-24 flex justify-center items-center cursor-pointer"}
              onClick={cb(answer(Array.isArray(value?.value) ? value?.value.length || 0 : 0), {} as never)}
            >
              <AddIcon className={"h-8 w-8"} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
