import LogoWhite from "../util/LogoWhite";
import { useNavigate } from "react-router-dom";

export const Documentation = () => {
  const navigate = useNavigate();

  const insuranceProducts = [
    {
      title: "Business Owner Policy",
      description: "Combined coverage to protect your business property and liability",
      icon: "📋",
      path: "bop",
    },
    {
      title: "General Liability",
      description: "Protection against common business risks and legal claims",
      icon: "⚖️",
      path: "gl",
    },
    {
      title: "Workers Compensation",
      description: "Coverage for employee injuries and related medical costs",
      icon: "👷",
      path: "wc",
    },
    {
      title: "Cyber Insurance",
      description: "Protection against digital threats and data breaches",
      icon: "🔒",
      path: "cyber",
    },
    {
      title: "Commercial Auto",
      description: "Coverage for business vehicles and fleet management",
      icon: "🚛",
      path: "auto",
    },
  ];

  const handleProductClick = (path: string) => {
    navigate(`/documentation/${path}`);
  };

  return (
    <div className='min-h-screen bg-light px-4'>
      {/* Header Section */}
      <div className='bg-primary text-white p-8 rounded-lg mb-12'>
        <div className='max-w-[200px] mx-auto mb-6'>
          <LogoWhite />
        </div>
        <div className='text-center'>
          <h1 className='text-4xl font-bold mb-4'>Questionnaire Documentation</h1>
          <p className='text-regular max-w-2xl mx-auto opacity-90'>
            Choose from our range of commercial insurance products to learn more about questions, rules, data mapping
            and more.
          </p>
        </div>
      </div>

      {/* Products Grid */}
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-7xl mx-auto'>
        {insuranceProducts.map(product => (
          <div
            key={product.title}
            onClick={() => handleProductClick(product.path)}
            className='bg-white rounded-lg p-6 shadow-md hover:shadow-lg transition-all 
            cursor-pointer border border-secondary-lighter hover:border-primary'
          >
            <div className='text-4xl mb-4'>{product.icon}</div>
            <h3 className='text-xl font-semibold text-dark-blue mb-2'>{product.title}</h3>
            <p className='text-secondary-text text-md'>{product.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
