import { Val } from "@Savus-Inc/dsl/dist/types";
import { QuestionnaireQuestionEntity, QuestionValueFormatting, InputType } from "@Savus-Inc/questionnaire-types";

const getSchemaType = (q: QuestionnaireQuestionEntity) => {
  const inputType = q.question?.inputType?.toLowerCase();
  const format = q.question?.format;
  const hasValues = q.question?.values && q.question.values.length > 0;

  if (hasValues) {
    const enumValues = q.question?.values.map(v => `'${v.label}'`).join(" | ");
    return q.question?.multipleAnswers 
      ? `Array<${enumValues}>`
      : enumValues;
  }

  switch (inputType) {
    case InputType.YesNo:
      return "boolean";
    case InputType.Header:
    case InputType.Statement:
      return "null";
    case InputType.Select:
    case InputType.RadioGroup:
      return "string";
    case InputType.Checkbox:
      return q.question?.multipleAnswers ? "string[]" : "boolean";
    default:
      switch (format) {
        case QuestionValueFormatting.FEIN:
          return "string // Format: XX-XXXXXXX";
        case QuestionValueFormatting.SSN:
          return "string // Format: XXX-XX-XXXX";
        case QuestionValueFormatting.DateFormat_Year:
          return "string // Format: YYYY";
        case QuestionValueFormatting.DateFormat_MonthYear:
          return "string // Format: MM/YYYY";
        case QuestionValueFormatting.DateFormat_FullDate:
          return "string // Format: MM/DD/YYYY";
        case QuestionValueFormatting.TimeFormat_12H:
          return "string // Format: HH:MM AM/PM";
        case QuestionValueFormatting.TimeFormat_24H:
          return "string // Format: HH:MM";
        case QuestionValueFormatting.CurrencyFormat:
        case QuestionValueFormatting.NumberWithTwoDecimalPlaces:
          return "number";
        case QuestionValueFormatting.Percentage:
          return "number // 0-100";
        case QuestionValueFormatting.ZipCode:
          return "string // Format: XXXXX";
        case QuestionValueFormatting.Phone:
          return "string // Format: (XXX) XXX-XXXX";
        case QuestionValueFormatting.PhoneExt:
          return "string // Format: XXX";
        case QuestionValueFormatting.Email:
          return "string // Format: email@domain.com";
        default:
          switch (q.question?.kind) {
            case Val.Number:
              return "number";
            case Val.Date:
              return "string // Format: MM/DD/YYYY";
            case Val.Bool:
              return "boolean";
            default:
              return "string";
          }
      }
  }
};

export const generateSchema = (
  question: QuestionnaireQuestionEntity, 
  subQuestions?: QuestionnaireQuestionEntity[],
  jsonNodePath: string[] = ['answers']
) => {
  const buildSchema = (q: QuestionnaireQuestionEntity) => {
    const schema: any = {
      question: `string // ${q.question?.externalId}`,
      value: getSchemaType(q)
    };

    if (subQuestions && subQuestions.length > 0) {
      schema.answers = `Array<{
  question: string,
  value: ${subQuestions.map(sq => getSchemaType(sq)).join(' | ')}
}>`;
    }

    return schema;
  };

  let schema: any = {};
  let current = schema;
  jsonNodePath.forEach((node, index) => {
    if (index === jsonNodePath.length - 1) {
      current[node] = buildSchema(question);
    } else {
      current[node] = {};
      current = current[node];
    }
  });

  return JSON.stringify(schema, null, 2)
    .replace(/"([^"]+)":/g, '$1:') // Remove quotes from property names
    .replace(/"/g, ''); // Remove remaining quotes for type definitions
}; 