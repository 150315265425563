import { Arg } from "./Arg";
import { cb } from "../../utils/cd";
import React from "react";
import { OpUI, useOp } from "./OpUI";
import { QuestionContext } from "../../types/QuestionContext";
import { ArgSignature, Op, OpArgument as Argument, ValueFunctions } from "@Savus-Inc/dsl/dist/types";
import { RemoveIcon } from "../util/ItemActions";

export function OpArgument({
  arg,
  label,
  argSignature,
  allowSynthetic,
  update,
  context,
  argIdx,
}: {
  arg: Argument | Op | null;
  label?: string;
  argSignature: ArgSignature;
  allowSynthetic?: boolean;
  update: (val: Argument | Op) => void;
  context: QuestionContext;
  argIdx: number;
}) {
  const { stack, op: parentOp, signature } = useOp();
  if ([undefined, null].includes(arg as never)) {
    return (
      <div className={"flex relative items-center"}>
        <Arg
          arg={null}
          argSignature={argSignature}
          allowSynthetic={argSignature.allowSynthetic ?? allowSynthetic}
          label={label}
          update={update}
        />
        {
          <div
            className={"flex bg-gray-600 text-white items-center rounded mx-1 cursor-pointer"}
            onClick={cb(update, { fn: ValueFunctions.Value, args: [] })}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1'
              stroke='currentColor'
              className='size-7'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M4.745 3A23.933 23.933 0 0 0 3 12c0 3.183.62 6.22 1.745 9M19.5 3c.967 2.78 1.5 5.817 1.5 9s-.533 6.22-1.5 9M8.25 8.885l1.444-.89a.75.75 0 0 1 1.105.402l2.402 7.206a.75.75 0 0 0 1.104.401l1.445-.889m-8.25.75.213.09a1.687 1.687 0 0 0 2.062-.617l4.45-6.676a1.688 1.688 0 0 1 2.062-.618l.213.09'
              />
            </svg>
          </div>
        }
        {(signature.minArgs ?? 1) < parentOp?.args?.length && (
          <div onClick={() => update(null as never)} className={"text-red-500 cursor-pointer"}>
            <RemoveIcon />
          </div>
        )}
      </div>
    );
  }

  if ((arg as Op).fn) {
    const op = arg as Op;
    return (
      <div className={"flex relative"}>
        <div
          className={"flex text text-red-400 items-center rounded-full absolute  top-0 right-0 z-50  cursor-pointer"}
          onClick={cb(update, null as never)}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={4}
            stroke='currentColor'
            className='size-4'
          >
            <path strokeLinecap='round' strokeLinejoin='round' d='M6 18 18 6M6 6l12 12' />
          </svg>
        </div>
        <OpUI
          key={JSON.stringify(op)}
          op={op}
          update={v =>
            update({
              ...op,
              args: v,
            })
          }
          updateFunction={v =>
            update({
              fn: v,
              args: [],
            })
          }
          context={context}
          stack={[...stack, parentOp]}
          argIdx={argIdx}
        />
      </div>
    );
  }

  return (
    <Arg
      arg={arg as Argument}
      argSignature={argSignature}
      allowSynthetic={argSignature.allowSynthetic ?? allowSynthetic}
      label={label}
      update={update}
    />
  );
}
