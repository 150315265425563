import { QuestionnaireQuestionEntity } from "@Savus-Inc/questionnaire-types";
import { useState } from "react";
import { JsonNodePath } from "./components/JsonNodePath";
import { CodeWindow } from "./components/CodeWindow";
import { ImportantNotes } from "./components/ImportantNotes";
import { generateSchema } from "./utils/schemaGenerator";
import { generateExample } from "./utils/exampleGenerator";

interface ApiExampleProps {
  question: QuestionnaireQuestionEntity;
  subQuestions?: QuestionnaireQuestionEntity[];
  jsonNodePath?: string[];
}

export const DocumentationApiExample = ({ 
  question, 
  subQuestions,
  jsonNodePath = ['answers']
}: ApiExampleProps) => {
  const [copied, setCopied] = useState(false);
  const [pathCopied, setPathCopied] = useState(false);
  const [showExample, setShowExample] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(showExample ? generateExample(question, subQuestions, jsonNodePath) : generateSchema(question, subQuestions, jsonNodePath));
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleCopyPath = async () => {
    await navigator.clipboard.writeText(jsonNodePath.join('.'));
    setPathCopied(true);
    setTimeout(() => setPathCopied(false), 2000);
  };

  return (
    <div className="mt-8 max-w-full">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-semibold text-dark-blue flex items-center gap-2">
          <svg className="w-5 h-5 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
          API Request Format
        </h3>
        <div className="flex items-center gap-2">
          <button
            onClick={() => setShowExample(!showExample)}
            className="px-3 py-1 text-sm text-primary border border-primary rounded-full hover:bg-primary hover:text-white transition-all duration-200 active:scale-95"
          >
            {showExample ? "Show Schema" : "Show Example"}
          </button>
        </div>
      </div>

      <JsonNodePath 
        path={jsonNodePath}
        onCopy={handleCopyPath}
        copied={pathCopied}
      />

      <CodeWindow
        title={showExample ? "Example Payload" : "Schema Definition"}
        content={showExample 
          ? generateExample(question, subQuestions, jsonNodePath)
          : generateSchema(question, subQuestions, jsonNodePath)
        }
        onCopy={handleCopy}
        copied={copied}
      />

      <ImportantNotes 
        question={question}
        subQuestions={subQuestions}
      />
    </div>
  );
};
