import { Questionnaire } from "@Savus-Inc/questionnaire-types";
import { QuestionnaireExport } from "./QuestionnaireExport";
import { useState } from "react";
import { calculateQuestionsMap } from "@Savus-Inc/questionnaire-ngn/dist/state-reducers";
import { calculateQuestionExternalIdMap } from "@Savus-Inc/questionnaire-ngn/dist/utils";

export function QuestionnaireHeader({
  item,
  preview,
  back,
  setDisplayMode,
}: {
  back: () => void;
  item: Questionnaire;
  preview: () => void;
  setDisplayMode: (value: "Table" | "Default") => void;
}) {
  const [isTable, setIsTable] = useState(false);
  const handleToggle = (e: any) => {
    setIsTable(!isTable);
    setDisplayMode(e.target.innerText);
  };

  return (
    <div className='sticky top-[58px] -mt-2 flex flex-col gap-[2px] z-[9]'>
      <div className={"flex items-center justify-between border-b border-gray-300 sticky -top-2 bg-white p-2"}>
        <div className='flex items-center gap-10'>
          <button className={"w-2 py-[2px] text-gray-500"} onClick={back}>
            <div className='cursor-pointer'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={2.5}
                stroke='#0BA5E9'
                className='size-7'
              >
                <path strokeLinecap='round' strokeLinejoin='round' d='M15 19l-7-7 7-7' />
              </svg>
            </div>
          </button>
          <h1 className={"text-lg text-gray-700 flex flex-col text-center"}>
            <span>
              Questionnaire: <span className={"font-semibold"}>{item?.title || "New"}</span>
            </span>
            {!item.lineOfBusinessId && (
              <span className={"text-red-500 text-sm font-semibold"}>
                Questionnaire is not associated to the product
              </span>
            )}
          </h1>

          <div className={"flex items-center gap-3"}>
            <button
              className='w-32 py-1 px-3 rounded-xl border border-sky-500 shadow text-sky-500 hover:bg-sky-500 hover:text-white transition ease-in-out'
              onClick={preview}
            >
              Preview
            </button>
            <div className={"flex gap-1"}>
              <div className={"flex gap-2 items-center"}>
                <QuestionnaireExport />
              </div>
              <a
                className=' py-1 px-3 rounded-xl border border-sky-500 shadow text-sky-500 hover:bg-sky-500 hover:text-white transition ease-in-out'
                href={`data:text/json;charset=utf-8,${encodeURIComponent(
                  JSON.stringify({ ...item, carrierQuestionnaires: undefined }),
                )}`}
                download={`${Date.now()}-questionnaire.json`}
              >
                Export JSON
              </a>
            </div>

            <div className={"flex gap-1"}>
              <div className={"flex gap-2 items-center"}>
                <QuestionnaireExport />
              </div>
              <a
                className=' py-1 px-3 rounded-xl border border-sky-500 shadow text-sky-500 hover:bg-sky-500 hover:text-white transition ease-in-out'
                href={`data:text/json;charset=utf-8,${encodeURIComponent(
                  JSON.stringify(calculateQuestionExternalIdMap(item)),
                )}`}
                download={`${Date.now()}-question-map.json`}
              >
                Export Question Map
              </a>
            </div>
          </div>
        </div>

        <div
          className='relative w-36 h-10 bg-gray-200 rounded-md flex items-center cursor-pointer p-1'
          onClick={handleToggle}
        >
          <span className={`absolute left-4 font-medium text-gray-500`}>Table</span>
          <span className={`absolute right-3 font-medium text-gray-500`}>Default</span>

          <div
            className={`w-1/2 h-8 bg-white rounded-md transform transition-transform ${
              isTable ? "translate-x-0" : "translate-x-full"
            } flex items-center justify-center text-gray-700 font-medium px-2 cursor-pointer`}
          >
            {isTable ? "Table" : "Default"}
          </div>
        </div>
      </div>
    </div>
  );
}
