import { QuoteTestConfig } from "./QuoteTestSetup";
import customers from "../../data/combined-customer-records.json";
import { BUSINESS_TYPES } from "../../data/business-types";
import { useCallback, useEffect, useState } from "react";
import { getBusinessTypes, getQuestionnaire, getQuestionnaireCarrierMapping } from "../../http/questionnaire";
import { useAppState } from "../../state/State";
import { createCustomerRecord, createQuote, saveQuoteState, startQuote, submitQuote } from "../../http/app";
import { CustomerRecord } from "../../types/customer-record";
import { autocompleteQuestionnaire } from "../../utils/autocomplete-questionnaire";
import { CarrierQuestionnaire, Questionnaire, QuestionnaireQuestionEntity } from "@Savus-Inc/questionnaire-types";
import { prepareQuestionnaire } from "../../utils/prepare-questionnaire";
import { unpackMapping, unpackQuestionnaire } from "@Savus-Inc/questionnaire-ngn/dist/utils";
import { CarrierProduct, CarrierProductStatus } from "@Savus-Inc/questionnaire-ngn/dist/types";
import { getCarrierSubmissions } from "@Savus-Inc/questionnaire-ngn/dist/state-reducers";
import { useLocation, useNavigate } from "react-router-dom";


const FIELD_MAP = {
  Address: ["customerRecord", "address", "address"],
  City: ["customerRecord", "address", "city"],
  Suite: ["customerRecord", "address", "suite"],
  BusinessTypeNAICS: [
    "customerRecord",
    "businessType",
    "naicsCode",
  ],
  BusinessTypeNAICSTitle: [
    "customerRecord",
    "businessType",
    "naicsTitle",
  ],
  BusinessTypeTitle: ["customerRecord", "businessType", "title"],
  BusinessName: ["customerRecord", "businessName"],
  Product: ["customerRecord", "product", "name"],
  ProductShortName: ["customerRecord", "product", "shortName"],
  State: ["customerRecord", "address", "state", "shortName"],
  StateName: ["customerRecord", "address", "state", "name"],
  StateNameWithCode: [
    "customerRecord",
    "address",
    "state",
    "fullName",
  ],
  ZipCode: ["customerRecord", "address", "zip"],
  ClassCodeChubb: ["customerRecord", "businessType", "ClassCodeChubb"],
  ClassCodeCna: ["customerRecord", "businessType", "ClassCodeCna"],
  ClassCodeTravelers: ["customerRecord", "businessType", "ClassCodeTravelers"],
  ClassCodeNationWide: ["customerRecord", "businessType", "ClassCodeNationWide"],
  ClassCodeLibertyMutual: ["customerRecord", "businessType", "ClassCodeLibertyMutual"],
  ClassCodeChubbDescription: ["customerRecord", "businessType", "ClassCodeChubbDescription"],
  ClassCodeCnaDescription: ["customerRecord", "businessType", "ClassCodeCnaDescription"],
  ClassCodeTravelersDescription: ["customerRecord", "businessType", "ClassCodeTravelersDescription"],
  ClassCodeNationWideDescription: ["customerRecord", "businessType", "ClassCodeNationWideDescription"],
  ClassCodeLibertyMutualDescription: ["customerRecord", "businessType", "ClassCodeLibertyMutualDescription"],
  ChubbIntegration: ["integrations", "chubb"],
  BusinessOwnerPhone: ["customerRecord", "phoneNumber"],
  BusinessOwnerEmail: ["customerRecord", "emailAddress"],
  BusinessOwnerFirstName: ["customerRecord", "firstName"],
  BusinessOwnerLastName: ["customerRecord", "lastName"],
  PolicyQuoteIdChubb: ["policyQuoteIdChubb"],
  __X: ["__X"],
  AgentFirstName: ["agent", "firstName"],
  AgentLastName: ["agent", "lastName"],
  AgentEmail: ["agent", "email"],
  AgentBrokerCodeLibertyMutual: ["agent", "brokerCode", "libertyMutual"],
  AgentBrokerCodeNationwide: ["agent", "brokerCode", "nationWide"],
  AgentBrokerCodeTravelers: ["agent", "brokerCode", "travelers"],
};

const activeRun: Record<string, string> = {};

enum QuoteTestRunnerState {
  Starting = "starting",
  SelectingBusinessType = "selecting-business-type",
  SelectingAddress = "selecting-address",
  CreatingCustomerRecord = "creating-customer-record",
  GetAvaliableProducts = "get-avaliable-products",
  GetQuestionnaire = "get-questionnaire",
  GetQuestionnaireCarrierMapping = "get-questionnaire-carrier-mapping",
  StartingQuote = "starting-quote",
  AutoPopulatingQuestionnaire = "auto-populating-questionnaire",
  SubmittingQuote = "submitting-quote",
  Finished = "finished",
  Error = "error",
}

interface QuoteRunStatus {
  state: QuoteTestRunnerState;
  message: string;
  timestamp: number;
  customerInfo?: {
    businessName: string;
    businessType: string;
    address: string;
  };
  applicationId?: string;
  quoteStateId?: string;
  customerRecordId?: string;
}

export const QuoteTestRunner = ({ config }: { config: QuoteTestConfig }) => {
  const [runStatuses, setRunStatuses] = useState<QuoteRunStatus[]>([]);
  const { state: { states, applications, carriers, products } } = useAppState();
  const location = useLocation();
  const navigate = useNavigate();

  const getStatusMessage = (state: QuoteTestRunnerState): string => {
    switch (state) {
      case QuoteTestRunnerState.Starting:
        return "Initializing quote test...";
      case QuoteTestRunnerState.SelectingBusinessType:
        return "Selecting business type...";
      case QuoteTestRunnerState.SelectingAddress:
        return "Choosing address...";
      case QuoteTestRunnerState.CreatingCustomerRecord:
        return "Creating customer record...";
      case QuoteTestRunnerState.GetAvaliableProducts:
        return "Fetching available products...";
      case QuoteTestRunnerState.GetQuestionnaire:
        return "Loading questionnaire...";
      case QuoteTestRunnerState.GetQuestionnaireCarrierMapping:
        return "Mapping carrier questionnaire...";
      case QuoteTestRunnerState.StartingQuote:
        return "Initiating quote process...";
      case QuoteTestRunnerState.AutoPopulatingQuestionnaire:
        return "Auto-filling questionnaire...";
      case QuoteTestRunnerState.SubmittingQuote:
        return "Submitting quote...";
      case QuoteTestRunnerState.Finished:
        return "Quote test completed successfully!";
      case QuoteTestRunnerState.Error:
        return "An error occurred during the quote test";
      default:
        return "Processing...";
    }
  };

  const updateRunStatus = (
    index: number,
    state: QuoteTestRunnerState,
    data?: {
      message?: string;
      customerInfo?: { businessName: string; businessType: string; address: string };
      applicationId?: string;
      quoteStateId?: string;
      customerRecordId?: string;
    },
  ) => {
    setRunStatuses(prev => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        state,
        message: `${getStatusMessage(state)} ${data?.message || ""}}`,
        timestamp: Date.now(),
        ...data,
      };
      return updated;
    });
  };

  useEffect(() => {
    if (activeRun[config.name] === "running") {
      return;
    }

    const runs = Array(config.numberOfQuotes).fill({
      state: QuoteTestRunnerState.Starting,
      message: getStatusMessage(QuoteTestRunnerState.Starting),
      timestamp: Date.now(),
    });

    // Initialize status array for all runs
    setRunStatuses(runs);

    activeRun[config.name] = "running";
    const product = products.find(p => p.id === config.product);
    console.log("product", product, config.product);
    if (product) {
      getQuestionnaire(product.activeQuestionnaireId).then(async questionnaire => {
        if (questionnaire.ok) {
          const prepared = prepareQuestionnaire(unpackQuestionnaire(questionnaire.data));
          const mapping = await getQuestionnaireCarrierMapping(prepared.id);

          if (!mapping.ok) {
            throw "Failed to get mapping";
          }

          const mappingData = unpackMapping(mapping.data, prepared);

          const run = async (i: number) => {
            try {
              await startSuite(prepared, mappingData, i);
            } catch (e: any) {
              if (e.message === "Failed to start a quote") {
                run(i);
              } else {
                console.error(e);
              }
            }
          };

          await Promise.all(runs.map((_, i) => run(i))).finally(() => {
            activeRun[config.name] = "finished";
            const searchParams = new URLSearchParams(location.search);
            searchParams.set("marker", config.name as string);
            navigate({
              pathname: location.pathname,
              search: searchParams.toString(),
            });
          });
        }
      });

    }

  }, [config.name]);

  const handleQuoteClick = (status: QuoteRunStatus) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("customerRecordId", status.customerRecordId as string);
    searchParams.set("quoteStateId", status.quoteStateId as string);
    searchParams.set("applicationId", status.applicationId as string);
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const startSuite = useCallback(async (questionnaire: Questionnaire, mappingData: CarrierQuestionnaire[], index: number) => {
    try {
      updateRunStatus(index, QuoteTestRunnerState.SelectingBusinessType);
      const questionMap: Record<string, QuestionnaireQuestionEntity> = questionnaire.groups.flatMap(g => g.questions).reduce(
        (p, c) => ({
          ...p,
          [c.externalId || c.question?.externalId as string]: c,
        }),
        {},
      );

      const fakeCustomers = customers as unknown as CustomerRecord[];
      const customer = fakeCustomers[Math.floor(Math.random() * fakeCustomers.length)];
      customer.emailAddress = customer.emailAddress.replace("@", `-${Math.floor(Math.random() * 10000000000000)}@`);
      const application = applications.find(a => a.name.includes("AgentX"));
      if (!application) {
        throw new Error("Application not found");
      }

      if (config.businessTypeMode === "random") {
        updateRunStatus(index, QuoteTestRunnerState.SelectingBusinessType);
        const rand = BUSINESS_TYPES.data[Math.floor(Math.random() * BUSINESS_TYPES.data.length)].title;
        const bt = await getBusinessTypes(rand);
        if (bt.ok) {
          customer.businessType = bt.data[Math.floor(Math.random() * bt.data.length)] as never;
          customer.businessTypeId = customer.businessType.id as string;
        }
      } else {
        updateRunStatus(index, QuoteTestRunnerState.SelectingBusinessType);
        customer.businessType = config.manualBusinessType as never;
        customer.businessTypeId = customer.businessType.id;
      }

      if (config.addressMode === "random") {
        updateRunStatus(index, QuoteTestRunnerState.SelectingAddress);
        if (!config.states.includes("all")) {
          const customersFromStates = fakeCustomers.filter(c => {
            return config.states.includes(c.address.state?.shortName || "");
          });
          let found = customersFromStates[Math.floor(Math.random() * customersFromStates.length)];

          const state = states.find(s => s.shortName === found.address.state.shortName);
          console.log("found", found, "state", state);
          customer.address = found.address;
          customer.address.stateId = state?.id as string;
          customer.address.state.id = state?.id as string;
        } else {
          const state = states.find(s => s.shortName === customer.address.state.shortName);
          console.log("found", customer, "state", state);
          customer.address.stateId = state?.id as string;
          customer.address.state.id = state?.id as string;
        }
      } else {
        updateRunStatus(index, QuoteTestRunnerState.SelectingAddress);
        const state = states.find(s => s.shortName === config.manualAddress?.state);
        customer.address = config.manualAddress as never;
        customer.address.stateId = state?.id as string;
        customer.address.state.id = state?.id as string;
      }

      const { id, businessType, progress, ...payload } = customer;
      updateRunStatus(index, QuoteTestRunnerState.CreatingCustomerRecord, {
        customerInfo: {
          businessName: customer.businessName,
          businessType: customer.businessType.title,
          address: `${customer.address.address}, ${customer.address.city}, ${customer.address.state.shortName} ${customer.address.zip}`,
        },
      });
      const data = await createCustomerRecord({
        ...payload,
        applicationId: application.id,
        quoteStateId: undefined,
        marker: config.name,
      } as never);

      if (!data.ok) {
        throw new Error("Failed to create customer record");
      }

      customer.id = data.data.id;

      const customerRecordId = data.data.id;
      updateRunStatus(index, QuoteTestRunnerState.StartingQuote, {
        customerRecordId: customerRecordId,
        customerInfo: {
          businessName: customer.businessName,
          businessType: customer.businessType.title,
          address: `${customer.address.address}, ${customer.address.city}, ${customer.address.state.shortName} ${customer.address.zip}`,
        },
      });
      const quoteR = await createQuote(application.id, customerRecordId);

      if (!quoteR.ok) {
        throw new Error("Failed to create quote");
      }

      const carrierToSend = config.carriers.includes("all") ? carriers.map(c => ({
        carrierId: c.id,
        lineOfBusinessId: config.product as string,
      })) : config.carriers.map(c => ({
        carrierId: carriers.find(carrier => carrier.name === c)?.id as string,
        lineOfBusinessId: config.product as string,
      }));

      const quote = await startQuote(application.id, quoteR.data.id, customerRecordId, carrierToSend);

      if (!quote.ok) {
        throw new Error("Failed to start a quote");
      }


      const quoteStateId = quote.data.id;
      customer.quoteStateId = quoteStateId;

      updateRunStatus(index, QuoteTestRunnerState.AutoPopulatingQuestionnaire, {
          quoteStateId: quoteStateId,
          customerRecordId: customerRecordId,
          applicationId: application.id,
          customerInfo: {
            businessName: customer.businessName,
            businessType: customer.businessType.title,
            address: `${customer.address.address}, ${customer.address.city}, ${customer.address.state.shortName} ${customer.address.zip}`,
          },
        },
      );
      const answers = await autocompleteQuestionnaire(questionnaire, (quote.data as any).carrierProducts.map((cp: CarrierProduct) => {
        const carrier = carriers.find(c => cp.carrierId === c.id);
        const product = products.find(p => p.id === cp.lineOfBusinessId);
        const v: CarrierProductStatus = {
          carrier,
          product,
          declinations: [],
        } as never;

        return v;
      }), customer as CustomerRecord, FIELD_MAP, message => updateRunStatus(index, QuoteTestRunnerState.AutoPopulatingQuestionnaire, {
          quoteStateId: quoteStateId,
          customerRecordId: customerRecordId,
          applicationId: application.id,
          message,
          customerInfo: {
            businessName: customer.businessName,
            businessType: customer.businessType.title,
            address: `${customer.address.address}, ${customer.address.city}, ${customer.address.state.shortName} ${customer.address.zip}`,
          },
        },
      ));

      updateRunStatus(index, QuoteTestRunnerState.SubmittingQuote, {
        quoteStateId: quoteStateId,
        customerRecordId: customerRecordId,
        applicationId: application.id,
        customerInfo: {
          businessName: customer.businessName,
          businessType: customer.businessType.title,
          address: `${customer.address.address}, ${customer.address.city}, ${customer.address.state.shortName} ${customer.address.zip}`,
        },
      });

      await saveQuoteState(application.id, quoteStateId, customerRecordId, answers);

      const carrierProducts = (quote.data as any).carrierProducts.map((p: CarrierProduct) => {
        const carrierQuestionnaire = mappingData.find(
          (c) => c.carrierId === p.carrierId,
        ) as CarrierQuestionnaire;
        return {
          ...p,
          lineOfBusiness: products.find(p => p.id === config.product),
          carrier: carriers.find(c => c.id === p.carrierId),
          knockout: carrierQuestionnaire?.rules || [],
          defaults: carrierQuestionnaire?.defaults || [],
          dataMap: carrierQuestionnaire?.questions || [],
        } as CarrierProduct;
      });

      console.log("carrierProducts", carrierProducts);
      console.log("questionMap", questionMap);
      console.log(
        "answers",
        answers);
      console.log("ctx", { customerRecord: customer });


      const carrierSubmissions = getCarrierSubmissions(carrierProducts, questionMap, answers, { customerRecord: customer }, FIELD_MAP);

      console.log("carrierSubmissions", carrierSubmissions);

      await submitQuote(application.id, quoteStateId, customerRecordId, carrierSubmissions);

      updateRunStatus(index, QuoteTestRunnerState.Finished, {
        quoteStateId: quoteStateId,
        customerRecordId: customerRecordId,
        applicationId: application.id,
        customerInfo: {
          businessName: customer.businessName,
          businessType: customer.businessType.title,
          address: `${customer.address.address}, ${customer.address.city}, ${customer.address.state.shortName} ${customer.address.zip}`,
        },
      });

    } catch (error) {
      updateRunStatus(index, QuoteTestRunnerState.Error);
      throw error;
    }
  }, [config.name]);


  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
        {runStatuses.map((status, index) => (
          <div
            key={index}
            onClick={() => handleQuoteClick(status)}
            className={`
              p-4 rounded-lg shadow-md animate-fade-in cursor-pointer
              hover:shadow-lg transition-shadow duration-200
              ${status.state === QuoteTestRunnerState.Error ? "bg-danger-light border-l-4 border-danger" :
              status.state === QuoteTestRunnerState.Finished ? "bg-light-green border-l-4 border-success" :
                "bg-white border-l-4 border-primary"}
            `}
          >
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-lg font-semibold">Quote Test #{index + 1}</h3>
              <span className="text-sm text-secondary">
                {new Date(status.timestamp).toLocaleTimeString()}
              </span>
            </div>

            {status.customerInfo && (
              <div className="mb-3 p-2 bg-light rounded-md text-sm">
                <div className="font-medium text-secondary-text mb-1">
                  {status.customerInfo.businessName}
                </div>
                <div className="text-secondary text-xs">
                  <div>{status.customerInfo.businessType}</div>
                  <div className="mt-1">{status.customerInfo.address}</div>
                </div>
              </div>
            )}

            <p className={`
              text-md animate-fade-in-left
              ${status.state === QuoteTestRunnerState.Error ? "text-danger" :
              status.state === QuoteTestRunnerState.Finished ? "text-dark-green" :
                "text-secondary-text"}
            `}>
              {status.message}
            </p>

            {status.state !== QuoteTestRunnerState.Finished &&
              status.state !== QuoteTestRunnerState.Error && (
                <div className="mt-3 h-1 bg-light rounded-full overflow-hidden">
                  <div className="h-full bg-primary animate-pulse" style={{ width: "60%" }}></div>
                </div>
              )}
          </div>
        ))}
      </div>

    </>
  );
};