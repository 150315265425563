import React, { useState } from "react";
import Papa from "papaparse";
import { CodeWindow } from "../Documentation/components/CodeWindow";
import { CopyAction } from "../util/ItemActions";

type FlattenedObject = Record<string, string>;

function flattenObject(obj: any, prefix = ''): FlattenedObject[] {
  let results: FlattenedObject[] = [{}];

  for (const key in obj) {
    const value = obj[key];
    const newKey = prefix ? `${prefix}.${key}` : key;

    if (Array.isArray(value)) {
      if (value.length === 0) {
        results.forEach(result => result[newKey] = '');
      } else if (typeof value[0] === 'object' && value[0] !== null) {
        // For arrays of objects, create new rows
        const newResults: FlattenedObject[] = [];
        results.forEach(existingResult => {
          value.forEach(item => {
            const flattenedItems = flattenObject(item, newKey);
            flattenedItems.forEach(flattenedItem => {
              newResults.push({ ...existingResult, ...flattenedItem });
            });
          });
        });
        results = newResults;
      } else {
        // For arrays of primitives, join with comma
        results.forEach(result => result[newKey] = value.join(','));
      }
    }
    else if (value !== null && typeof value === 'object') {
      // Recursively flatten nested objects
      const flattenedItems = flattenObject(value, newKey);
      results.forEach(result => Object.assign(result, flattenedItems[0]));
    }
    else {
      // Handle primitive values
      results.forEach(result => result[newKey] = value !== null ? String(value) : '');
    }
  }

  return results;
}

type DiffEntry = {
  path: string;
  oldValue: any;
  newValue: any;
  header?: string; // Summarization key value
  arrayDiffs?: ArrayDiff[]; // Add this field
};

type ArrayDiffType = 'added' | 'removed' | 'modified';

interface ArrayDiff {
  type: ArrayDiffType;
  value: any;
  index?: number;
}

function getArrayDifferences(oldArr: any[], newArr: any[]): ArrayDiff[] {
  const diffs: ArrayDiff[] = [];
  
  // Find removed and modified items
  oldArr.forEach((item, index) => {
    if (!newArr.includes(item)) {
      diffs.push({ type: 'removed', value: item, index });
    }
  });

  // Find added items
  newArr.forEach((item, index) => {
    if (!oldArr.includes(item)) {
      diffs.push({ type: 'added', value: item, index });
    }
  });

  return diffs.sort((a, b) => (a.index || 0) - (b.index || 0));
}

function sortArrayByType(arr: any[]): any[] {
  if (arr.length === 0) return arr;
  
  // If array contains objects, sort by stringified version
  if (typeof arr[0] === 'object' && arr[0] !== null) {
    return [...arr].sort((a, b) => 
      JSON.stringify(a) < JSON.stringify(b) ? -1 : 1
    );
  }
  
  // For primitive types, use regular sort
  return [...arr].sort();
}

function getValueByPath(obj: any, path: string): any {
  return path.split('.').reduce((curr, key) => curr?.[key], obj);
}

function findSummarizationValue(obj: any, summarizationKey: string): string {
  return obj[summarizationKey];
}

interface GroupedDiffResults {
  [key: string]: DiffEntry[];
}

function groupDiffResults(results: DiffEntry[]): GroupedDiffResults {
  return results.reduce((acc: GroupedDiffResults, curr) => {
    const key = curr.header || 'Other Changes';
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(curr);
    return acc;
  }, {});
}

function parseFileContent(content: string): any {
  // Try parsing as regular JSON first
  try {
    return JSON.parse(content);
  } catch (e) {
    // If regular JSON parsing fails, try parsing as newline-separated JSON
    try {
      // Split by newlines and filter out empty lines
      const lines = content.split('\n').filter(line => line.trim());
      
      // Try to parse each line as JSON
      const jsonArray = lines.map(line => {
        try {
          return JSON.parse(line);
        } catch (err) {
          throw new Error(`Invalid JSON in line: ${line}`);
        }
      });

      return jsonArray;
    } catch (err: any) {
      throw new Error(`Failed to parse file: ${err.message}`);
    }
  }
}

export const UtilsPage = () => {
  const [activeTab, setActiveTab] = useState<'jsonToCsv' | 'jsonDiff'>('jsonToCsv');
  const [baseFile, setBaseFile] = useState<File | null>(null);
  const [newFile, setNewFile] = useState<File | null>(null);
  const [diffResults, setDiffResults] = useState<DiffEntry[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [entryPoint, setEntryPoint] = useState("");
  const [summarizationKey, setSummarizationKey] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [expandedGroups, setExpandedGroups] = useState<Set<string>>(new Set());
  const [copiedOld, setCopiedOld] = useState(false);
  const [copiedNew, setCopiedNew] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
      setError(null);
    }
  };

  const handleJsonToCsv = () => {
    if (!selectedFile) {
      setError("Please select a file first");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const fileContent = e.target?.result as string;
        let data = parseFileContent(fileContent);

        // Handle entry point if specified
        if (entryPoint) {
          const keys = entryPoint.split(".");
          for (const key of keys) {
            data = data[key];
            if (!data) {
              throw new Error(`Entry point "${entryPoint}" not found in JSON`);
            }
          }
        }

        // Ensure data is an array
        const dataArray = Array.isArray(data) ? data : [data];

        const flattenedData = dataArray.flatMap(item => {
          const results = flattenObject(item);
          return results;
        });

        // Get all unique column headers
        const headers = Array.from(
          new Set(
            flattenedData.flatMap(obj => Object.keys(obj))
          )
        );

        const csv = Papa.unparse({
          fields: headers,
          data: flattenedData
        });

        // Download file
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${selectedFile.name.replace('.json', '')}-${new Date().getTime()}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setError(null);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An error occurred");
      }
    };

    reader.onerror = () => {
      setError("Error reading file");
    };

    reader.readAsText(selectedFile);
  };

  const handleDiffFiles = async () => {
    if (!baseFile || !newFile) {
      setError("Please select both files");
      return;
    }

    try {
      const baseContent = await baseFile.text();
      const newContent = await newFile.text();
      
      const baseJson = parseFileContent(baseContent);
      const newJson = parseFileContent(newContent);
      
      // Get the specific data based on entryPoint if provided
      const baseData = entryPoint ? getValueByPath(baseJson, entryPoint) : baseJson;
      const newData = entryPoint ? getValueByPath(newJson, entryPoint) : newJson;
      
      if (!baseData || !newData) {
        throw new Error("Invalid entry point path");
      }

      console.log('baseData', baseData);
      console.log('newData', newData);
      const differences = compareJson(
        baseData, 
        newData, 
        '', 
        [], 
        baseData, 
        newData, 
        summarizationKey, 
        undefined, 
        true
      ).filter(diff => diff.header);

      setDiffResults(differences);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    }
  };

  const toggleGroup = (groupKey: string) => {
    const newExpanded = new Set(expandedGroups);
    if (newExpanded.has(groupKey)) {
      newExpanded.delete(groupKey);
    } else {
      newExpanded.add(groupKey);
    }
    setExpandedGroups(newExpanded);
  };

  console.log('diffResults', diffResults);

  return (
    <div className="w-full flex flex-col p-4 gap-2">
      <div className="flex items-center justify-between border-b border-gray-700 pb-2">
        <h1 className="text-lg text-gray-700">Utils</h1>
      </div>

      {/* Tools Sidebar */}
      <div className="flex gap-4">
        <div className="w-48 flex flex-col gap-2">
          <button
            className={`p-2 rounded-lg ${
              activeTab === 'jsonToCsv' ? 'bg-primary text-white' : 'bg-light hover:bg-secondary-lighter'
            }`}
            onClick={() => setActiveTab('jsonToCsv')}
          >
            JSON to CSV
          </button>
          <button
            className={`p-2 rounded-lg ${
              activeTab === 'jsonDiff' ? 'bg-primary text-white' : 'bg-light hover:bg-secondary-lighter'
            }`}
            onClick={() => setActiveTab('jsonDiff')}
          >
            JSON Diff
          </button>
        </div>

        <div className="flex-1">
          {activeTab === 'jsonToCsv' ? (
            <div className="border rounded-lg p-4">
              <h2 className="text-lg mb-4">JSON to CSV Converter</h2>
              
              <div className="flex flex-col gap-2">
                <label className="text-sm text-gray-600">Select JSON File</label>
                <input
                  type="file"
                  accept=".json"
                  onChange={handleFileChange}
                  className="w-full p-2 border rounded"
                />
                {selectedFile && (
                  <div className="text-sm text-gray-600">
                    Selected file: {selectedFile.name}
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-2 mt-4">
                <label className="text-sm text-gray-600">Entry Point (optional)</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={entryPoint}
                  onChange={e => setEntryPoint(e.target.value)}
                  placeholder="e.g., data.items"
                />
              </div>

              {error && <div className="text-red-500 mt-2">{error}</div>}

              <button
                className="mt-4 w-48 py-1 bg-gray-700 text-white"
                onClick={handleJsonToCsv}
              >
                Convert to CSV
              </button>
            </div>
          ) : (
            <div className="border rounded-lg p-4">
              <h2 className="text-lg mb-4">JSON Diff Comparison</h2>
              
              <div className="grid grid-cols-2 gap-4">
                <div className="flex flex-col gap-2">
                  <label className="text-sm text-gray-600">Base JSON File</label>
                  <input
                    type="file"
                    accept=".json"
                    onChange={e => e.target.files && setBaseFile(e.target.files[0])}
                    className="w-full p-2 border rounded"
                  />
                </div>
                
                <div className="flex flex-col gap-2">
                  <label className="text-sm text-gray-600">New JSON File</label>
                  <input
                    type="file"
                    accept=".json"
                    onChange={e => e.target.files && setNewFile(e.target.files[0])}
                    className="w-full p-2 border rounded"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2 mt-4">
                <label className="text-sm text-gray-600">Entry Point (optional)</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={entryPoint}
                  onChange={e => setEntryPoint(e.target.value)}
                  placeholder="e.g., underwritingQuestionsResponse.underwritingQuestionsData"
                />
              </div>

              <div className="flex flex-col gap-2 mt-4">
                <label className="text-sm text-gray-600">Summarization Key (optional)</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={summarizationKey}
                  onChange={e => setSummarizationKey(e.target.value)}
                  placeholder="e.g., ruleCode"
                />
              </div>

              {error && <div className="text-red-500 mt-2">{error}</div>}

              <button
                className="mt-4 w-48 py-1 bg-gray-700 text-white rounded"
                onClick={handleDiffFiles}
              >
                Compare Files
              </button>

              {diffResults.length > 0 && (
                <div className="mt-4 space-y-4">
                  <h3 className="text-lg mb-2">Differences Found:</h3>
                  <div className="space-y-2">
                    {Object.entries(groupDiffResults(diffResults)).map(([groupKey, groupDiffs]) => (
                      <div key={groupKey} className="border rounded-lg overflow-hidden">
                        <button
                          onClick={() => toggleGroup(groupKey)}
                          className="w-full p-3 bg-light hover:bg-secondary-lighter flex items-center justify-between text-left"
                        >
                          <div className="flex items-center gap-4">
                            <div className="flex items-center gap-2">
                              <span className="text-primary font-medium">{groupKey}</span>
                              <span className="text-secondary-text text-sm">
                                ({groupDiffs.length} change{groupDiffs.length !== 1 ? 's' : ''})
                              </span>
                            </div>
                            <CopyAction value={groupKey} small />
                          </div>
                          <svg
                            className={`w-5 h-5 transform transition-transform ${
                              expandedGroups.has(groupKey) ? 'rotate-180' : ''
                            }`}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                          </svg>
                        </button>
                        
                        {expandedGroups.has(groupKey) && (
                          <div className="border-t">
                            {groupDiffs.map((diff, index) => (
                              <div key={index} className="border-b last:border-b-0">
                                <div className="p-3 bg-light/50">
                                  <span className="font-mono text-sm text-secondary-text">{diff.path}</span>
                                </div>
                                <div className="grid grid-cols-2 gap-4 p-4">
                                  <div>
                                    <CodeWindow
                                      title="Old Value"
                                      content={
                                        diff.oldValue === undefined 
                                          ? "// No Previous Value"
                                          : diff.arrayDiffs 
                                            ? JSON.stringify(diff.oldValue, null, 2)
                                            : JSON.stringify(diff.oldValue, null, 2)
                                      }
                                      onCopy={() => {
                                        navigator.clipboard.writeText(JSON.stringify(diff.oldValue, null, 2));
                                        setCopiedOld(true);
                                        setTimeout(() => setCopiedOld(false), 2000);
                                      }}
                                      copied={copiedOld}
                                    />
                                  </div>
                                  <div>
                                    <CodeWindow
                                      title="New Value"
                                      content={
                                        diff.newValue === undefined 
                                          ? "// Value Was Removed"
                                          : diff.arrayDiffs 
                                            ? JSON.stringify(diff.newValue, null, 2)
                                            : JSON.stringify(diff.newValue, null, 2)
                                      }
                                      onCopy={() => {
                                        navigator.clipboard.writeText(JSON.stringify(diff.newValue, null, 2));
                                        setCopiedNew(true);
                                        setTimeout(() => setCopiedNew(false), 2000);
                                      }}
                                      copied={copiedNew}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function compareJson(
  base: any, 
  updated: any, 
  path = '', 
  results: DiffEntry[] = [], 
  fullBase: any = null, 
  fullUpdated: any = null,
  summarizationKey = '',
  header?: string,
  isTopLevel: boolean = true
): DiffEntry[] {
  fullBase = fullBase || base;
  fullUpdated = fullUpdated || updated;

  if (Array.isArray(base) && Array.isArray(updated)) {
    const sortedBase = sortArrayByType(base);
    const sortedUpdated = sortArrayByType(updated);
    
    if (sortedBase.length > 0 && typeof sortedBase[0] === 'object' && sortedBase[0] !== null) {
      // Special handling for answerOptions array - match by both display text and value
      if (path.includes('answerOptions')) {
        const baseMap = new Map(sortedBase.map(item => [
          `${item.answerOptionDisplayText}|${item.answerOptionValue}`,
          item
        ]));
        
        const updatedMap = new Map(sortedUpdated.map(item => [
          `${item.answerOptionDisplayText}|${item.answerOptionValue}`,
          item
        ]));

        // Find removed options
        baseMap.forEach((baseItem, key) => {
          if (!updatedMap.has(key)) {
            results.push({
              path: `${path}[${sortedBase.indexOf(baseItem)}]`,
              oldValue: baseItem,
              newValue: undefined,
              header: isTopLevel ? baseItem[summarizationKey] : header
            });
          }
        });

        // Find added options
        updatedMap.forEach((updatedItem, key) => {
          if (!baseMap.has(key)) {
            results.push({
              path: `${path}[${sortedUpdated.indexOf(updatedItem)}]`,
              oldValue: undefined,
              newValue: updatedItem,
              header: isTopLevel ? updatedItem[summarizationKey] : header
            });
          }
        });

        return results;
      }

      // Regular object array comparison for other cases
      sortedBase.forEach((baseItem, index) => {
        const updatedItem = sortedUpdated.find(item => 
          item[summarizationKey] === baseItem[summarizationKey]
        );
        
        const currentHeader = isTopLevel 
          ? baseItem[summarizationKey]
          : header;
        
        if (updatedItem) {
          const beforeResultsLength = results.length;
          compareJson(
            baseItem, 
            updatedItem, 
            `${path}[${index}]`, 
            results, 
            baseItem, 
            updatedItem, 
            summarizationKey,
            currentHeader,
            false
          );
          if (results.length === beforeResultsLength) {
            return;
          }
        } else {
          results.push({
            path: `${path}[${index}]`,
            oldValue: baseItem,
            newValue: undefined,
            header: currentHeader
          });
        }
      });

      sortedUpdated.forEach((updatedItem, index) => {
        const exists = sortedBase.some(item => 
          item[summarizationKey] === updatedItem[summarizationKey]
        );
        
        if (!exists) {
          results.push({
            path: `${path}[${index}]`,
            oldValue: undefined,
            newValue: updatedItem,
            header: isTopLevel 
              ? updatedItem[summarizationKey]
              : header
          });
        }
      });
      
      return results;
    }
    
    return results;
  }

  if (typeof base === 'object' && base !== null && 
      typeof updated === 'object' && updated !== null) {
    const allKeys = new Set([...Object.keys(base), ...Object.keys(updated)]);
    
    allKeys.forEach(key => {
      const newPath = path ? `${path}.${key}` : key;
      
      if (!(key in base)) {
        results.push({
          path: newPath,
          oldValue: undefined,
          newValue: updated[key],
          header
        });
      } else if (!(key in updated)) {
        results.push({
          path: newPath,
          oldValue: base[key],
          newValue: undefined,
          header
        });
      } else if (JSON.stringify(base[key]) !== JSON.stringify(updated[key])) {
        compareJson(
          base[key], 
          updated[key], 
          newPath, 
          results, 
          base, 
          updated, 
          summarizationKey,
          header,
          false
        );
      }
    });
    
    return results;
  }

  if (base !== updated) {
    results.push({
      path,
      oldValue: base,
      newValue: updated,
      header
    });
  }
  
  return results;
}

// Helper function to format array diffs as a string
function formatArrayDiffToString(diffs: ArrayDiff[]): string {
  // Group all values
  const values = diffs.map(diff => diff.value);
  
  // For primitive arrays, just return the new array state
  return JSON.stringify(values, null, 2);
}
