import { Coverage } from "../../types/customer-record";

interface CoverageDetailsProps {
  coverage: Coverage;
}

export const CoverageDetails = ({ coverage }: CoverageDetailsProps) => {
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  return (
    <div className="mt-4 space-y-4">
      <div className="flex items-center justify-between">
        <h4 className="text-md font-medium text-dark-blue">Coverage Details</h4>
        {coverage.details.policyUrl && (
          <a
            href={coverage.details.policyUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-primary hover:text-primary-dark flex items-center gap-1"
          >
            View Policy
            <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>
          </a>
        )}
      </div>

      {/* Main Coverage Limits */}
      <div className="bg-light rounded-lg p-4">
        <h5 className="text-sm font-medium text-secondary-text mb-3">Main Coverage Limits</h5>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {coverage.details.main.map((limit, index) => (
            <div key={index} className="bg-white rounded-lg p-3 shadow-sm">
              <div className="text-xs text-secondary-text mb-1">{limit.limit}</div>
              <div className="text-lg font-semibold text-dark-blue">
                {formatCurrency(limit.value)}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Locations */}
      {coverage.details.locations.map((location, locationIndex) => (
        <div key={locationIndex} className="bg-light rounded-lg p-4">
          <div className="flex items-start justify-between mb-3">
            <div>
              <h5 className="text-sm font-medium text-secondary-text">Location Details</h5>
              <p className="text-sm text-secondary mt-1">{location.name}</p>
            </div>
            <span className="px-2 py-1 bg-primary/10 text-primary text-xs rounded-full">
              {location.locationType}
            </span>
          </div>

          <div className="space-y-3">
            <div className="flex items-center text-sm">
              <span className="text-secondary-text">Property Deductible:</span>
              <span className="ml-2 text-dark-blue font-medium">
                {formatCurrency(location.propertyDeductible)}
              </span>
            </div>

            {/* Buildings */}
            {location.buildings.map((building, buildingIndex) => (
              <div key={buildingIndex} className="mt-4">
                {Object.entries(building).map(([buildingId, coverages]) => (
                  <div key={buildingId} className="bg-white rounded-lg p-4 shadow-sm">
                    <div className="flex items-center justify-between mb-3">
                      <h6 className="font-medium text-dark-blue">{buildingId}</h6>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {coverages.map((coverage, coverageIndex) => (
                        <div key={coverageIndex} className="bg-light/50 rounded-lg p-3">
                          <div className="text-xs text-secondary-text mb-1">
                            {coverage.limit}
                          </div>
                          <div className="text-sm font-medium text-dark-blue">
                            {formatCurrency(coverage.value)}
                          </div>
                          {coverage.name && (
                            <div className="text-xs text-secondary-text mt-1">
                              {coverage.name}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}; 