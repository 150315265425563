import { stringToRuleGroup } from "@Savus-Inc/dsl/dist/utils/transformer";
import { QuestionVisibility, RuleGroupKind } from "@Savus-Inc/questionnaire-types";
import { v4 } from "uuid";

export function processVisibilityGroup(gr: string, fieldMap: { [p: string]: string }, i: number) {
  const [group, action] = gr.split(" -- ").map((v: string) => v.trim());

  const ruleGroup = stringToRuleGroup(group, fieldMap)[0];
  return {
    ...ruleGroup,
    name: gr,
    kind: RuleGroupKind.Visibility,
    partOrder: i,
    actionKind:
      action.toLowerCase() === "show"
        ? QuestionVisibility.Show
        : action.toLowerCase() === "readonly"
        ? QuestionVisibility.ReadOnly
        : QuestionVisibility.Hidden,
    id: v4(),
  };
}
