import { flexRender } from "@tanstack/react-table";
import React, { useEffect, useRef, useState } from "react";
import QuestionTableMenu from "./QuestionTableMenu";

const QuestionTableHeader = ({
  header,
  showMenuPopup,
  onClick,
  setShowMenuPopup,
}: {
  header: any;
  showMenuPopup: string;
  onClick: (value: string) => void;
  setShowMenuPopup: (value: string) => void;
}) => {
  const [columnHeader, setColumnHeader] = useState<string>("");

  return (
    <th
      onClick={() => {
        if (header.column?.id !== "select") onClick(header.column.columnDef.header);
      }}
      className={`relative border border-gray-200 hover:bg-gray-100 cursor-pointer ${
        header.column.id === "delete" ? "hidden" : ""
      }`}
      style={{ width: header.getSize() }}
      colSpan={header.colSpan}
    >
      <div className='px-2 text-ellipsis whitespace-nowrap overflow-hidden'>
        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
      </div>
      {showMenuPopup === header.column.columnDef.header && (
        <QuestionTableMenu
          setShowMenuPopup={setShowMenuPopup}
          setColumnHeader={setColumnHeader}
          columnHeader={columnHeader}
          header={header}
        />
      )}

      <div
        onClick={e => e.stopPropagation()}
        onMouseDown={e => {
          e.stopPropagation();
          header.getResizeHandler()(e);
        }}
        className='absolute top-0 right-0 w-2 h-full transform translate-x-1/2 z-10 cursor-col-resize bg-transparent touch-none select-none hover:bg-sky-400'
      ></div>
    </th>
  );
};

export default QuestionTableHeader;
