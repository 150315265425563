import {
  Answers,
  Question,
  Questionnaire,
  QuestionnaireQuestionEntity,
  QuestionValueAutocompleteIntegration,
} from "@Savus-Inc/questionnaire-types";
import { CarrierProductStatus } from "@Savus-Inc/questionnaire-ngn/dist/types";
import { CustomerRecord } from "../types/customer-record";
import { getAvailableGroups } from "@Savus-Inc/questionnaire-ngn/dist/state-reducers";
import { getVisibleQuestions, sleep } from "@Savus-Inc/questionnaire-ngn/dist/utils";
import { interpolate } from "@Savus-Inc/questionnaire-ngn/dist/utils/interpolate";
import { sendToGPT } from "./send-to-gpt";
import { set } from "@Savus-Inc/dsl/dist/utils/set";

export const autocompleteQuestionnaire = async (
  questionnaire: Questionnaire,
  carrierProductStatus: CarrierProductStatus[],
  customerRecord: CustomerRecord,
  fieldMap: Record<string, string[]>,
  updateMessage: (message: string) => void,
) => {
  let answers: Answers = {};

  const questions = questionnaire.groups.reduce(
    (acc, group) => {
      return {
        ...acc,
        ...group.questions.reduce((qAcc, question) => {
          return {
            ...qAcc,
            [question.externalId || (question.question?.externalId as string)]: question,
          };
        }, {}),
      };
    },
    {} as Record<string, QuestionnaireQuestionEntity>,
  );

  const questionsList = Object.values(questions);

  let availableGroups = getAvailableGroups(
    questionnaire,
    questions,
    { customerRecord, ...answers },
    fieldMap,
    carrierProductStatus,
  ).filter(g => g.partOrder !== 6);

  for (const group of availableGroups) {
    updateMessage(`Autocompleting group ${group.title}`);
    const context = {
      ...answers,
      customerRecord,
    };

    // Keep recalculating visible questions until no new ones appear
    let previousVisibleQuestions = new Set();
    let hasNewQuestions = true;

    while (hasNewQuestions) {
      const visibleQuestions = getVisibleQuestions(
        questionnaire,
        questions,
        { current: group.partOrder },
        context,
        fieldMap,
        carrierProductStatus,
        false,
      );

      const allQuestions = [
        ...Object.keys(visibleQuestions.questions),
        ...visibleQuestions.sections.flatMap(s => s.questions),
      ].filter(q => !questionsList.some(c => c.parentId === q));

      // Format visible questions for GPT
      const formattedQuestions = allQuestions
        .filter(q => !["Q-37", "Q-52", "937", "Q-467", "Q-475", "Q-331", "Q-332", "Q-333", "Q-334", "Q-335"].includes(q) || questions[q].parentId !== "Q-37")
        .map(q => {
          const question = questions[q]?.question as Question;
          return {
            id: question.externalId,
            text: question.label,
            ...(question.autocompleteIntegration === QuestionValueAutocompleteIntegration.OwnValues
              ? {
                availableAnswers: question.values.map(v =>
                  interpolate(questions, (v.value?.value as string) || v.label, context, fieldMap, true),
                ),
              }
              : { answerFormat: question.format || question.kind }),
            ...(questions[q].parentId
              ? {
                parentId: questions[q].parentId,
                parentQuestion: questions[questions[q].parentId as string]?.question?.label,
              }
              : { parentId: null }),
          };
        });

      let gptAnswers = {};

      const send = async (ques: (typeof formattedQuestions[0])[], attempts = 5) => {
        if (attempts === 0) {
          throw "Failed to get answers from AI";
        }
        try {
          gptAnswers = await sendToGPT(formattedQuestions as never, questions, answers, customerRecord);
          attempts = 5;
        } catch (e) {
          await sleep(5000);
          await send(ques, attempts - 1);
        }
      };


      const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
        const chunks: T[][] = [];
        for (let i = 0; i < array.length; i += chunkSize) {
          chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
      };

      const questionChunks = chunkArray(formattedQuestions, 5);

      await Promise.all(questionChunks.map(chunk => send(chunk)));

      Object.entries(gptAnswers).forEach(([id, value]) => {
        const q = questions[id];
        const answer = {
          value: (questions[id].question?.multipleAnswers ? (Array.isArray(value) ? value : [value]) : value) as never,
          multiple: !!questions[id].question?.multipleAnswers,
        };

        if (questionsList.some(v => v.parentId === q.externalId)) {
          return;
        }

        if (q.parentId) {
          answers = set(answers, [q.parentId, "value", 0, id], answer);
        } else {
          answers = set(answers, [id], answer);
        }
      });

      const newVisibleQuestions = new Set(visibleQuestions.sections.flatMap(section => section.questions));
      hasNewQuestions = !areSetsEqual(newVisibleQuestions, previousVisibleQuestions);
      previousVisibleQuestions = newVisibleQuestions;
    }

    availableGroups = getAvailableGroups(
      questionnaire,
      questions,
      { customerRecord, ...answers },
      fieldMap,
      carrierProductStatus,
    ).filter(g => g.partOrder !== 6);
  }


  answers = set(answers, ["Q-480"], { value: "No", multiple: false });
  answers = set(answers, ["Q-31", "value", 0, "Q-465"], { value: "LOC1", multiple: false });
  answers = set(answers, ["Q-31", "value", 0, "Q-475"], { value: "Primary", multiple: false });
  answers = set(answers, ["Q-31", "value", 0, "Q-34"], {
    value: (Math.floor(Math.random() * 50) + 2).toString(),
    multiple: false,
  });
  answers = set(answers, ["Q-31", "value", 0, "Q-35"], {
    value: "2",
    multiple: false,
  });

  answers = set(answers, ["Q-31", "value", 0, "Q-331"], { value: customerRecord.address.address, multiple: false });
  answers = set(answers, ["Q-31", "value", 0, "Q-333"], { value: customerRecord.address.city, multiple: false });
  answers = set(answers, ["Q-31", "value", 0, "Q-334"], {
    value: `${customerRecord.address.state.name} (${customerRecord.address.state.shortName})`,
    multiple: false,
  });
  answers = set(answers, ["Q-31", "value", 0, "Q-335"], { value: customerRecord.address.zip, multiple: false });
  answers = set(answers, ["Q-51", "value", 0, "Q-52"], { value: "LOC1", multiple: false });
  answers = set(answers, ["Q-51", "value", 0, "Q-467"], { value: "BLDG1", multiple: false });

  answers["Q-37"] = {
    multiple: true,
    value: [],
  };

  return answers;
};

const areSetsEqual = (a: Set<any>, b: Set<any>) => {
  if (a.size !== b.size) return false;
  for (const item of Array.from(a)) if (!b.has(item)) return false;
  return true;
};
