import axios from "axios";
import { getValue } from "@Savus-Inc/runtime-ngn/dist/src/utils/get-value";
import { Answers, QuestionnaireQuestionEntity } from "@Savus-Inc/questionnaire-types";
import { CustomerRecord } from "../types/customer-record"; // Assuming you'll be making a request to the GPT API

export const sendToGPT = async (
  questions: Array<{
    id: string; // Question ID
    text: string; // Question text
    availableAnswers?: string[]; // Optional available answers if the question has predefined values
    answerFormat?: string; // Optional, e.g., free text, number, etc.
    parentId?: string | null,
    parentQuestionText?: string,
  }>,
  questionMap: Record<string, QuestionnaireQuestionEntity>,
  previousAnswers: Answers,
  customerRecord: CustomerRecord,
): Promise<Record<string, string>> => {
  // Construct a detailed prompt for GPT
  const instructions = `
You are completing a Commercial Insurance Questionnaire for a Business Owner Policy. Answer questions as if you are the business owner, aiming to maximize chances of receiving a favorable insurance quote. Avoid using additional insureds, locations or claims.

Business Details:
- Name: ${customerRecord.businessName}
- Location: ${customerRecord.address.address}, ${customerRecord.address.city}, ${customerRecord.address.state.shortName}, ${customerRecord.address.zip}

Previous Answers for Context:
${Object.entries(previousAnswers)
    .map(([k, v]) => `${questionMap[k].question?.label}: ${getValue(v)}`)
    .join("\n")}

Questions to Answer:
${questions.map((q, i) => `
${i + 1}) ID: ${q.id}
   Q: ${q.text}
   ${q.availableAnswers ? `Options: ${q.availableAnswers.join(", ")}` : ""}
   ${q.answerFormat ? `Format: ${q.answerFormat}` : ""}
   ${q.parentQuestionText ? `Context: ${q.parentQuestionText}` : ""}`).join("\n")}


Answer instructions:
- Try to not have a single question unanswered.
- Make sure that you are reading the context correctly, same for the question like when is the business established, this can also be a year. 
- Timestamp questions should be in the format of "2023-01-01" without time or timezone.
- Answer the questions as you are a mid-size business owner.
- All of the data is just for testing purposes, so try to provide answer to evert questions even financial ones with the answer that makes the most sense.
- We are using the generated data just to build tests so feel free to make up amounts, and answers.
- For numbers please use the format of 500000 not 500,000 or 500k, use valid json numbers, they can be strings, like "500000" but cannot be "500,000".
- For numbers that are percentages, please use the format of 50 not 50%, use valid json numbers, they can be strings, like "50" but cannot be "50%".
- never use currency symbols, just use the number.
- policy startQuote should always be in the future where today is ${new Date().toISOString().split("T")[0]}, and should always be in the date format (like: "2025-06-06")
- If question is asking about a year of something, then answer should be a string, in a format of "YYYY".
- Avoid choosing non profit or church or common ownership business types.

Respond with a minified JSON object only: {"questionId": "answer", ...} where questionId is the ID of the question and answer is the answer to the question.

JSON object must be valid JSON. Please do not include any extra characters.
`;

  // Debugging or logging the constructed instructions

  const key = process.env.REACT_APP_OPENAI_API_KEY || (window as any).OPENAI_API_KEY;

  if (!key) {
    alert(`OPENAI_API_KEY is not set, please set it in environment or window.OPENAI_API_KEY`);
    throw `OPENAI_API_KEY is not set, please set it in environment or window.OPENAI_API_KEY`;
  }


  // Implementation of the call to GPT API
  try {
    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions", // Replace with your GPT API endpoint
      {
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: "You are a helpful business assistant completing forms.",
          },
          {
            role: "user",
            content: instructions,
          },
        ],
        temperature: 0.2,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      },
    );

    // Parse GPT's response into a usable format
    const gptResponse = response.data.choices[0]?.message?.content;

    // Expecting GPT to return the JSON object: { "questionId": "answer" }
    return JSON.parse(gptResponse);
  } catch (error) {
    console.error("Error sending request to GPT:", error);
    throw new Error("Failed to receive a response from GPT");
  }
};