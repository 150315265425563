import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { RemoveAction } from "../util/ItemActions";
import { cb } from "../../utils/cd";
import { CarrierQuestion, CarrierQuestionnaire, QuestionnaireQuestionEntity } from "@Savus-Inc/questionnaire-types";
import { useBuilderNGN } from "../Questionaire/BuilderNGN";
import { UploadMapping } from "../Questionaire/UploadMapping";
import { unpack } from "../../utils/unpack";
import { useAppState } from "../../state/State";
import { toast } from "react-toastify";

interface ProcessedCarrierQuestion extends CarrierQuestion {
  referenceString: string;
  partOrder: number;
  parent?: QuestionnaireQuestionEntity;
  groupPart: number;
}

export const DataMapping = ({ carrierId, carrierMap }: { carrierId: string; carrierMap: CarrierQuestionnaire }) => {
  const { groupPartActive, activateGroupPart, carrierMappingQuestionId, removeCarrierMappingItem, questions, item } =
    useBuilderNGN();

  const {
    editQuestion,
    state: { carriers },
  } = useAppState();

  const [searchTerm, setTerm] = useState("");

  const sortedQuestions = useMemo(() => {
    const processedQuestions: ProcessedCarrierQuestion[] = carrierMap?.questions
      .map(q => {
        const referencedQuestion = questions.find(c => c.externalId === q.referencedQuestionId || c.question?.externalId === q.referencedQuestionId);
        const parentQuestion = referencedQuestion?.parentId
          ? questions.find(c => c.id === referencedQuestion?.parentId)
          : undefined;
        const groupPart =
          item.groups.find(g => g.questions.some(q => q.id === referencedQuestion?.id))?.partOrder ?? -1;

        const referenceString = referencedQuestion
          ? `${referencedQuestion.question?.externalId} - ${referencedQuestion.question?.label}`
          : q.jsonNode.join(".") || "New Mapping";

        return {
          ...q,
          referenceString,
          partOrder: referencedQuestion?.partOrder ?? -1,
          parent: parentQuestion,
          groupPart,
        };
      })
      .filter(
        v =>
          !searchTerm.trim() ||
          `${v.parent?.question?.externalId} ${v.referenceString} ${v.jsonNode.flatMap(v => v.join(".")).join(" ")}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase()),
      );

    return Object.entries(
      processedQuestions
        .sort((a, b) => a.groupPart - b.groupPart)
        .reduce(
          (p, c) => {
            if (p[c.groupPart]) {
              p[c.groupPart].push(c);
            } else {
              p[c.groupPart] = [c];
            }
            return p;
          },
          {} as Record<number, ProcessedCarrierQuestion[]>,
        ),
    )
      .sort((a, b) => Number(a[0]) - Number(b[0]))
      .map(([_, v]) =>
        v.sort(
          (a, b) => a.partOrder + (a.parent?.partOrder || 0) * 1000 - (b.partOrder + (b.parent?.partOrder || 0) * 1000),
        ),
      );
  }, [carrierMap?.questions, item.groups, questions, searchTerm]);

  const assignMappedQuestionsToCarrier = useCallback(() => {
    const carrierName = carriers.find(c => c.id === carrierId)?.name;
    if (!carrierName) {
      alert("Carrier Not Found");
      return;
    }
    let totalQuestions = 0;
    carrierMap.questions.forEach(q => {
      const question = questions.find(c => c.id === q.referencedQuestionId);
      if (!question) {
        return;
      }
      if (!question?.showForCarriers?.includes(carrierName)) {
        question.showForCarriers = [...question.showForCarriers, carrierName];
        editQuestion(item.id, question.groupId, question.id, {
          showForCarriers: question.showForCarriers.join(",") as never,
        });
        totalQuestions++;
      }
    });

    toast.info(`Updates ${totalQuestions} questions for carrier ${carrierName}`);
  }, [carrierMap?.questions, questions, carriers]);

  return (
    <div className={"flex flex-col gap-3"}>
      <div
        className={
          "flex gap-2 items-center cursor-pointer justify-between  py-[2px] bg-sky-500 px-2 rounded text-white shadow text-center"
        }
        onClick={assignMappedQuestionsToCarrier}
      >
        Assign Carrier To Questions
      </div>
      <UploadMapping carrierId={carrierId} />
      <div className={"flex flex-col"}>
        <input
          className='border border-gray-300 rounded px-1 py-[2px] focus:outline-none focus:border-sky-500'
          value={searchTerm}
          onChange={unpack(setTerm)}
          placeholder={"Filter Mapping..."}
        />
        {sortedQuestions.map((group, i) => (
          <div key={i} className={"my-2"}>
            {group.map(q => (
              <div
                key={q.id}
                className={classNames(
                  "flex gap-2 items-center justify-between border-sky-200 border-b py-[2px] cursor-pointer px-2 rounded",
                  {
                    "bg-gray-100": groupPartActive === "c-questions" && q.id === carrierMappingQuestionId,
                  },
                )}
              >
                <div
                  className={
                    "w-full max-w-[300px] overflow-ellipsis overflow-hidden whitespace-nowrap flex gap-1 items-center"
                  }
                  onClick={cb(activateGroupPart, { carrier: carrierId, groupPart: "c-questions", dataMapId: q.id })}
                >
                  {q.parent && (
                    <span className={"bg-gray-600 text-white text-xs p-1 rounded"}>
                      {q.parent.question?.externalId}
                    </span>
                  )}
                  {q.referenceString}
                </div>
                <RemoveAction remove={cb(removeCarrierMappingItem, carrierId, q.id, "questions")} small invert />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
