import { Val } from "@Savus-Inc/dsl/dist/types";
import { QuestionnaireQuestionEntity, QuestionValueFormatting, InputType } from "@Savus-Inc/questionnaire-types";

const getExampleValue = (q: QuestionnaireQuestionEntity) => {
  const inputType = q.question?.inputType?.toLowerCase();
  const format = q.question?.format;
  const hasValues = q.question?.values && q.question.values.length > 0;
  
  if (hasValues) {
    const exampleValue = q.question?.values[0].label;
    return q.question?.multipleAnswers ? [exampleValue] : exampleValue;
  }

  switch (inputType) {
    case InputType.YesNo:
      return true;
    case InputType.Header:
    case InputType.Statement:
      return null;
    case InputType.Select:
    case InputType.RadioGroup:
      return "option_1";
    case InputType.Checkbox:
      return q.question?.multipleAnswers ? ["option_1", "option_2"] : true;
    default:
      switch (format) {
        case QuestionValueFormatting.FEIN:
          return "12-3456789";
        case QuestionValueFormatting.SSN:
          return "123-45-6789";
        case QuestionValueFormatting.DateFormat_Year:
          return "2024";
        case QuestionValueFormatting.DateFormat_MonthYear:
          return "01/2024";
        case QuestionValueFormatting.DateFormat_FullDate:
          return "01/01/2024";
        case QuestionValueFormatting.TimeFormat_12H:
          return "02:30 PM";
        case QuestionValueFormatting.TimeFormat_24H:
          return "14:30";
        case QuestionValueFormatting.CurrencyFormat:
          return 1234.56;
        case QuestionValueFormatting.Percentage:
          return 75;
        case QuestionValueFormatting.ZipCode:
          return "12345";
        case QuestionValueFormatting.Phone:
          return "(123) 456-7890";
        case QuestionValueFormatting.PhoneExt:
          return "123";
        case QuestionValueFormatting.Email:
          return "example@domain.com";
        case QuestionValueFormatting.SqFt:
          return 1500;
        case QuestionValueFormatting.Initial:
          return "JD";
        case QuestionValueFormatting.NumberWithCommas:
          return 1234567;
        case QuestionValueFormatting.NumberWithTwoDecimalPlaces:
          return 1234.56;
        default:
          switch (q.question?.kind) {
            case Val.String:
              return "example text";
            case Val.Number:
              return 123;
            case Val.Bool:
              return true;
            case Val.Date:
              return "01/01/2024";
            default:
              return "example text";
          }
      }
  }
};

export const generateExample = (
  question: QuestionnaireQuestionEntity, 
  subQuestions?: QuestionnaireQuestionEntity[],
  jsonNodePath: string[] = ['answers']
) => {
  const buildExample = (q: QuestionnaireQuestionEntity) => {
    const example: any = {
      question: q.question?.externalId,
      value: getExampleValue(q)
    };

    if (subQuestions && subQuestions.length > 0) {
      example.answers = subQuestions.map(sq => ({
        question: sq.question?.externalId,
        value: getExampleValue(sq)
      }));
    }

    return example;
  };

  let example: any = {};
  let current = example;
  jsonNodePath.forEach((node, index) => {
    if (index === jsonNodePath.length - 1) {
      current[node] = buildExample(question);
    } else {
      current[node] = {};
      current = current[node];
    }
  });

  return JSON.stringify(example, null, 2);
}; 