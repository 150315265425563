import React, { useEffect } from "react";

export default function QuestionTableMenu({
  header,
  setColumnHeader,
  columnHeader,
  setShowMenuPopup,
}: {
  header: any;
  columnHeader: string;
  setColumnHeader: (value: string) => void;
  setShowMenuPopup: (value: string) => void;
}) {
  const isSorted = header.column.getIsSorted();
  const handleInputChange = (value: string) => {
    setColumnHeader(value);
  };

  useEffect(() => {
    if (header.id !== "select") {
      setColumnHeader(header.column.columnDef.header);
    }
  }, [header.column.columnDef.header, setColumnHeader, header.id]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const value = (e.target as HTMLInputElement).value;
      setColumnHeader(value);
      header.column.columnDef.header = value;

      setShowMenuPopup("");
    }
  };

  return (
    <div className='absolute w-fit flex flex-col bg-white shadow-lg border-radius-md z-[999]'>
      <div className='p-2'>
        <input
          onChange={e => handleInputChange(e.target.value)}
          onKeyDown={handleKeyDown}
          onClick={e => e.stopPropagation()}
          value={columnHeader}
          className='focus:outline-none border-2 rounded-md pl-2'
        />
      </div>
      <div
        onClick={header.column.getToggleSortingHandler()}
        className='hover:bg-gray-200 p-2 text-gray-500 font-medium'
      >
        {isSorted === "desc" ? (
          <div className='flex gap-2 whitespace-nowrap'>
            Sort Ascending
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='green'
              className='size-6'
            >
              <path strokeLinecap='round' strokeLinejoin='round' d='m4.5 15.75 7.5-7.5 7.5 7.5' />
            </svg>
          </div>
        ) : (
          <div className='flex gap-2 whitespace-nowrap'>
            Sort Descending
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='orange'
              className='size-6'
            >
              <path strokeLinecap='round' strokeLinejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5' />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}
